import {
    ParticipationView,
    viewIncludesCarbon,
    viewIncludesCommunityCanopy,
    viewIncludesCommunityEvents,
    viewIncludesCommunityTreeDistributor,
    viewIncludesReforestation
} from './ParticipationViewSelector';
import CarbonParticipationHeader from './ProjectTypeTables/CarbonTable/CarbonParticipationTableHeader';
import {CommunityCanopyParticipationHeader} from './ProjectTypeTables/CommunityCanopyTable/CommunityCanopyParticipationHeader';
import {CommunityEventHeader} from './ProjectTypeTables/CommunityEventTable/CommunityEventHeader';
import {CommunityTreeDistributorParticipationHeader} from './ProjectTypeTables/CommunityTreeDistributorTable/CommunityTreeDistributorParticipationHeader';
import {ReforestationHeader} from './ProjectTypeTables/ReforestationTable/ReforestationHeader';

function ParticipationColumnHeaderRow({view}: {view: ParticipationView}) {
    if (viewIncludesReforestation(view)) return <ReforestationHeader />;

    if (viewIncludesCommunityEvents(view)) return <CommunityEventHeader />;

    if (viewIncludesCommunityCanopy(view)) return <CommunityCanopyParticipationHeader />;

    if (viewIncludesCommunityTreeDistributor(view))
        return <CommunityTreeDistributorParticipationHeader />;

    if (viewIncludesCarbon(view)) {
        return <CarbonParticipationHeader />;
    }
}

export default ParticipationColumnHeaderRow;
