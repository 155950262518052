import {useEffect, useState, useTransition} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {
    formatProjectStatus,
    formatProjectType,
    PlantingProject,
    PlantingProjectType,
    YesNoConditional
} from '../../entities';
import styled from 'styled-components';
import {NumericFilter, TextFilter} from '../../components/Form/FormStyles';
import React from 'react';
import {
    ValidatedIcon,
    ValidateFilter,
    ValidateFilterValue
} from '../../components/Controls/ValidateControl';
import {
    CellWithValidator,
    StickyDateHeader,
    StickyNumericHeader
} from '../../components/Table/TableStyles';
import Tippy from '@tippyjs/react';
import {RemoveFilterButton} from '../../components/Buttons/FilterButton';
import PlantingProjectViewSelector, {
    PlantingProjectView,
    getProjectTypeForView,
    showCarbonColumn,
    showCommunityColumn,
    showReforestationColumn,
    getViewForProjectType,
    showCommunityCanopyColumn,
    showCommunityTreeDistributorColumn
} from './PlantingProjectViewSelector';
import {useConfig} from '../../providers/ConfigProvider';
import {Filter, filterProjects} from './PlantingProjectFilter';
import {formatAdditionalCertifications} from '../PlantingProjectEditor/Carbon/CarbonCreditAdditionalCertifications';
import {formatCarbonCreditTypes} from '../PlantingProjectEditor/Carbon/CarbonCreditType';
import {formatCarbonProjectStandards} from '../PlantingProjectEditor/Carbon/ProjectStandard';
import {formatSustainableDevelopmentGoal} from '../PlantingProjectEditor/Carbon/SustainableDevelopmentGoals';

const PlantingProjectsLayout = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
        'header'
        'viewFilter'
        'projects';
    place-content: stretch;
    padding: 0 0 0 1rem;
`;

const ViewFilter = styled.div`
    grid-area: viewFilter;
    display: flex;
    align-item: start;
    grid-row-start: 0;
    padding-bottom: 10px;
`;

const HeaderArea = styled.div`
    display: flex;
    gap: 1rem;
    align-items: baseline;
`;

const HeaderWrapper = styled.div`
    grid-area: header;
    padding-bottom: 10px;
    border-bottom: 1px solid grey;
    max-width: 99%;
`;

const Title = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
`;

const ProjectsArea = styled.div`
    overflow: auto;
    padding: 0;
`;

const StickyHeader = styled.th`
    background: white;
    position: sticky;
    top: 0;
`;

const SelectionLink = styled.span`
    color: #0d6efd;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const DisplayCountDropdown = styled.div`
    display: inline-block;
    margin: 1rem 0 0 0;
    padding: 0.5rem;
    color: #055160;
`;

const DisplayCountSelect = styled.select`
    padding: 3px;
    border: 1px solid #ced4da;
    margin-top: 0px;
`;

const DisplayCountWrapper = styled.span`
    font-weight: 500;
`;

const StyledTd = styled.td`
    text-align: right;
`;

const offsetOneRow = {
    top: '33.5px'
};

const offsetTwoRows = {
    top: '91px'
};

const fixedValColumn = {
    left: '0',
    backgroundColor: 'white'
};

const fixedProjectIdColumn = {
    left: '50px',
    backgroundColor: 'white'
};

const fixedProjectTypeColumn = {
    left: '140px',
    backgroundColor: 'white'
};

const fixedNameColumn = {
    left: '330px',
    backgroundColor: 'white'
};

class ProjectRow extends React.PureComponent<{
    project: PlantingProject;
    forSelection: boolean;
    projectSelected: (projectId: number) => void;
    showForReforestation: boolean;
    showForCommunity: boolean;
    showForCarbon: boolean;
    showForCommunityCanopy: boolean;
    showForCommunityTreeDistributor: boolean;
    treeHarmonyBaseUrl: string;
    lythoData;
}> {
    render() {
        const {
            project,
            forSelection,
            projectSelected,
            showForCarbon,
            showForCommunity,
            showForReforestation,
            showForCommunityCanopy,
            showForCommunityTreeDistributor,
            treeHarmonyBaseUrl,
            lythoData
        } = this.props;

        return (
            <tr>
                <td style={{position: 'sticky', ...fixedValColumn}}>
                    {project.EverythingValidated && <ValidatedIcon />}
                </td>

                <td style={{position: 'sticky', ...fixedProjectIdColumn}}>
                    {forSelection ? (
                        <SelectionLink onClick={() => projectSelected(project.Id)}>
                            {project.Id}
                        </SelectionLink>
                    ) : (
                        <Link to={`/planting-projects/${project.Id}`}>{project.Id}</Link>
                    )}
                </td>
                <td style={{position: 'sticky', ...fixedProjectTypeColumn}}>
                    <CellWithValidator>
                        <span>{project.ProjectTypeValidated && <ValidatedIcon />}</span>
                        <span>{formatProjectType(project.ProjectType)}</span>
                    </CellWithValidator>
                </td>
                <td style={{position: 'sticky', ...fixedNameColumn}}>
                    <CellWithValidator>
                        <span>{project.NameValidated && <ValidatedIcon />}</span>
                        <span>{project.Name}</span>
                    </CellWithValidator>
                </td>
                {(showForReforestation || showForCommunity || showForCarbon) && (
                    <td>
                        <CellWithValidator>
                            <span>{project.CompletedValidated && <ValidatedIcon />}</span>
                            <span>{formatProjectStatus(project.Completed)}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForCommunityCanopy && (
                    <td>
                        {project.BHCampaigns?.map((campaign, i) => [
                            i > 0 && <br key={i} />,
                            campaign.CampaignId
                        ])}
                    </td>
                )}

                {(showForReforestation || showForCommunity || showForCarbon) && (
                    <td>
                        <CellWithValidator>
                            <span>{project.LocationValidated && <ValidatedIcon />}</span>
                            <span>{project.Location}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForReforestation && (
                    <td>
                        <CellWithValidator>
                            <span>
                                {project.ProjectType === PlantingProjectType.Reforestation &&
                                    project.TTFForestCodeValidated && <ValidatedIcon />}
                            </span>
                            <span>{project.TTFForestName}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForCommunity && (
                    <>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                        project.CommunitySiteNameValidated && <ValidatedIcon />}
                                </span>
                                <span>{project.CommunitySiteName}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                        project.CommunitySiteAddressValidated && <ValidatedIcon />}
                                </span>
                                <span>{project.CommunitySiteAddress}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                        project.CommunitySiteCityValidated && <ValidatedIcon />}
                                </span>
                                <span>{project.CommunitySiteCity}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                        project.CommunitySiteStateCodeValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>{project.CommunitySiteStateCode}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                        project.CommunitySitePostalCodeValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>{project.CommunitySitePostalCode}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                        project.CommunitySiteCountryCodeValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>{project.CommunitySiteCountryCode}</span>
                            </CellWithValidator>
                        </td>
                    </>
                )}
                {(showForReforestation || showForCommunity || showForCarbon) && (
                    <td>
                        <CellWithValidator>
                            <span>{project.MapValidated && <ValidatedIcon />}</span>
                        </CellWithValidator>
                    </td>
                )}
                {(showForCarbon || showForReforestation) && (
                    <td style={{textAlign: 'right'}}>
                        <CellWithValidator>
                            <span>
                                {(project.ProjectType === PlantingProjectType.Reforestation ||
                                    project.ProjectType === PlantingProjectType.Carbon) &&
                                    project.ProjectYearsValidated && <ValidatedIcon />}
                            </span>
                            <span>
                                {project.ProjectStartYear === project.ProjectEndYear
                                    ? project.ProjectEndYear
                                    : `${project.ProjectStartYear ?? ''} - ${
                                          project.ProjectEndYear ?? ''
                                      }`}
                            </span>
                        </CellWithValidator>
                    </td>
                )}
                {(showForCommunity ||
                    showForCommunityCanopy ||
                    showForCommunityTreeDistributor) && (
                    <td>
                        <CellWithValidator>
                            <span>
                                {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                    project.CommunityEventDatesValidated && <ValidatedIcon />}
                            </span>
                            <span>
                                {project.CommunityFirstDayOfEvent ===
                                project.CommunityFinalDayOfEvent
                                    ? project.CommunityFinalDayOfEvent
                                    : `${project.CommunityFirstDayOfEvent ?? ''} - ${
                                          project.CommunityFinalDayOfEvent ?? ''
                                      }`}
                            </span>
                        </CellWithValidator>
                    </td>
                )}
                {showForReforestation && (
                    <td>
                        <CellWithValidator>
                            <span>
                                {project.ProjectType === PlantingProjectType.Reforestation &&
                                    project.TTFPlantingTimeframeValidated && <ValidatedIcon />}
                            </span>
                            <span>{project.TTFPlantingTimeframe}</span>
                        </CellWithValidator>
                    </td>
                )}
                {(showForReforestation ||
                    showForCommunity ||
                    showForCommunityCanopy ||
                    showForCommunityTreeDistributor) && (
                    <td>
                        <CellWithValidator>
                            <span>{project.ReasonForPlantingValidated && <ValidatedIcon />}</span>
                            <span>{project.ReasonForPlanting}</span>
                        </CellWithValidator>
                    </td>
                )}
                {(showForCommunity || showForReforestation || showForCommunityTreeDistributor) && (
                    <td>
                        <CellWithValidator>
                            <span>
                                {project.ImpactStoryInProgressValidated && <ValidatedIcon />}
                            </span>
                            <span>{project.HasImpactStoryInProgress ? 'Y' : ''}</span>
                        </CellWithValidator>
                    </td>
                )}
                <td>
                    <CellWithValidator>
                        <span>{project.ImpactStoryCompletedValidated && <ValidatedIcon />}</span>
                        <span>{project.HasImpactStoryCompleted ? 'Y' : ''}</span>
                    </CellWithValidator>
                </td>
                {showForCarbon && (
                    <td>
                        <CellWithValidator>
                            <span>
                                {project.ProjectType === PlantingProjectType.Carbon &&
                                    project.SustainableDevelopmentGoalsValidated && (
                                        <ValidatedIcon />
                                    )}
                            </span>
                            {
                                <span>
                                    {project.SustainableDevelopmentGoals?.map((x) =>
                                        formatSustainableDevelopmentGoal(
                                            x.SustainableDevelopmentGoal
                                        )
                                    ).join(', ')}
                                </span>
                            }
                        </CellWithValidator>
                    </td>
                )}
                {(showForCommunityCanopy || showForCommunityTreeDistributor) && (
                    <td>
                        {project.ProjectType === PlantingProjectType.CommunityCanopy &&
                            project.FundingPartners?.map((partner, i) => [
                                i > 0 && ', ',
                                <Link
                                    key={partner.Id}
                                    to={`/partners/${partner.Id}?view=${
                                        project.ProjectType === PlantingProjectType.CommunityCanopy
                                            ? 'CommunityCanopy'
                                            : 'CommunityTreeDistributor'
                                    }`}>
                                    {partner.Name}
                                </Link>
                            ])}
                    </td>
                )}
                {(showForReforestation || showForCommunity || showForCarbon) && (
                    <td>
                        <CellWithValidator>
                            <span>{project.PlantingPartnerNameValidated && <ValidatedIcon />}</span>
                            <span>{project.PlantingPartnerName}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForReforestation && (
                    <>
                        <td>
                            {project.Commitments?.map((commitment, i) => [
                                i > 0 && ', ',
                                <a
                                    key={i}
                                    href={`${treeHarmonyBaseUrl}/Commitments/Detail/${commitment.CommitmentId}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {commitment.CommitmentId}
                                </a>
                            ])}
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Reforestation &&
                                        project.TTFCommitmentFiscalYearValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>{project.TTFCommitmentFiscalYear}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Reforestation &&
                                        project.TTFAgreementNumberValidated && <ValidatedIcon />}
                                </span>
                                <span>{project.TTFAgreementNumber}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator style={{textAlign: 'right'}}>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Reforestation &&
                                        project.TTFTotalTreeCostValidated && <ValidatedIcon />}
                                </span>
                                <span>{project.TTFTotalTreeCost}</span>
                            </CellWithValidator>
                        </td>
                    </>
                )}
                {showForCommunity && (
                    <>
                        <td>
                            <CellWithValidator style={{textAlign: 'right'}}>
                                <span>
                                    {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                        project.CommunityNumberOfCorporateVolunteersValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>
                                    {project.CommunityNumberOfCorporateVolunteers?.toLocaleString()}
                                </span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator style={{textAlign: 'right'}}>
                                <span>
                                    {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                        project.CommunityNumberOfTotalParticipantsValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>
                                    {project.CommunityNumberOfTotalParticipants?.toLocaleString()}
                                </span>
                            </CellWithValidator>
                        </td>
                    </>
                )}
                {showForReforestation && (
                    <td style={{textAlign: 'right'}}>
                        <CellWithValidator>
                            <span>
                                {project.ProjectType === PlantingProjectType.Reforestation &&
                                    project.TTFTreesValidated && <ValidatedIcon />}
                            </span>
                            <span>{project.TTFTrees?.toLocaleString()}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForCommunity && (
                    <td>
                        <CellWithValidator style={{textAlign: 'right'}}>
                            <span>
                                {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                    project.CommunityTreesPlantedValidated && <ValidatedIcon />}
                            </span>
                            <span>{project.CommunityTreesPlanted?.toLocaleString()}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForCommunity && (
                    <td>
                        <CellWithValidator style={{textAlign: 'right'}}>
                            <span>
                                {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                    project.CommunityTreesDistributedValidated && <ValidatedIcon />}
                            </span>
                            <span>{project.CommunityTreesDistributed?.toLocaleString()}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForReforestation && (
                    <td>
                        <CellWithValidator style={{textAlign: 'right'}}>
                            <span>
                                {project.ProjectType === PlantingProjectType.Reforestation &&
                                    project.TTFDBHValidated && <ValidatedIcon />}
                            </span>
                            <span>{project.TTFDBH}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForCommunity && (
                    <td>
                        <CellWithValidator style={{textAlign: 'right'}}>
                            <span>
                                {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                    project.CommunityDBHTreesPlantedValidated && <ValidatedIcon />}
                            </span>
                            <span>{project.CommunityDBHTreesPlanted}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForCommunity && (
                    <td>
                        <CellWithValidator style={{textAlign: 'right'}}>
                            <span>
                                {project.ProjectType === PlantingProjectType.CommunityEvent &&
                                    project.CommunityDBHTreesDistributedValidated && (
                                        <ValidatedIcon />
                                    )}
                            </span>
                            <span>{project.CommunityDBHTreesDistributed}</span>
                        </CellWithValidator>
                    </td>
                )}
                {(showForReforestation || showForCarbon) && (
                    <>
                        <td>
                            <CellWithValidator style={{textAlign: 'right'}}>
                                <span>
                                    {(project.ProjectType === PlantingProjectType.Reforestation ||
                                        project.ProjectType === PlantingProjectType.Carbon) &&
                                        project.AcresRestoredValidated && <ValidatedIcon />}
                                </span>
                                <span>
                                    {project.AcresRestored?.toLocaleString(undefined, {
                                        maximumFractionDigits: 2
                                    })}
                                </span>
                            </CellWithValidator>
                        </td>
                    </>
                )}
                {(showForReforestation || showForCommunity) && (
                    <td>
                        <CellWithValidator>
                            <span>{project.TreeSpeciesValidated && <ValidatedIcon />}</span>
                            <span>{project.HasTreeSpecies ? 'Y' : ''}</span>
                        </CellWithValidator>
                    </td>
                )}
                {showForCarbon && (
                    <>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Carbon &&
                                        project.CarbonTotalCreditsIssuedValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>
                                    {project.CarbonTotalCreditsIssued?.toLocaleString(undefined, {
                                        maximumFractionDigits: 10
                                    })}
                                </span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Carbon &&
                                        project.CarbonProjectStandardValidated && <ValidatedIcon />}
                                </span>
                                <span>
                                    {formatCarbonProjectStandards(project.CarbonProjectStandard)}
                                </span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Carbon &&
                                        project.CarbonCreditCreditTypeValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>
                                    {formatCarbonCreditTypes(project.CarbonCreditCreditType)}
                                </span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Carbon &&
                                        project.CarbonICroaEndorsedStandardValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>{YesNoConditional[project.CarbonICroaEndorsedStandard]}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Carbon &&
                                        project.CarbonCorsiaEligibleValidated && <ValidatedIcon />}
                                </span>
                                <span>{YesNoConditional[project.CarbonCorsiaEligible]}</span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Carbon &&
                                        project.CarbonAdditionalDetailsLinkValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <span>
                                    {project.CarbonCreditsAdditionalCertifications?.map((x) =>
                                        formatAdditionalCertifications(
                                            x.CarbonCreditsAdditionalCertification
                                        )
                                    ).join(', ')}
                                </span>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Carbon &&
                                        project.CarbonAdditionalDetailsLinkValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <a
                                    href={project.CarbonAdditionalDetailsLink}
                                    target={'_blank'}
                                    rel="noopener noreferrer">
                                    {project.CarbonAdditionalDetailsLink}
                                </a>
                            </CellWithValidator>
                        </td>
                        <td>
                            <CellWithValidator>
                                <span>
                                    {project.ProjectType === PlantingProjectType.Carbon &&
                                        project.CarbonProjectRegistryLinkValidated && (
                                            <ValidatedIcon />
                                        )}
                                </span>
                                <a
                                    href={project.CarbonProjectRegistryLink}
                                    target={'_blank'}
                                    rel="noopener noreferrer">
                                    {project.CarbonProjectRegistryLink}
                                </a>
                            </CellWithValidator>
                        </td>
                    </>
                )}

                {(showForReforestation ||
                    showForCommunity ||
                    showForCarbon ||
                    showCommunityCanopyColumn) && (
                    <>
                        <td>{project.DAMTag}</td>
                        <td>
                            <CellWithValidator>
                                <span>{project.ImagesValidated && <ValidatedIcon />}</span>
                                <span></span>
                            </CellWithValidator>
                        </td>
                        <td>
                            {lythoData !== null && lythoData['image-count']
                                ? lythoData['image-count']
                                : 0}
                        </td>
                        <td>{lythoData !== null && lythoData['hero'] ? 'Y' : ''}</td>
                    </>
                )}

                {showForReforestation && (
                    <>
                        <StyledTd>
                            <span>{project.GallonsTotalRunoffAvoidedPerTreeReforestation}</span>
                        </StyledTd>
                        <StyledTd>
                            <span>{project.TonsCarbonPerTreeReforestation}</span>
                        </StyledTd>
                        <StyledTd>
                            <span>{project.TonsAirPollutionPerTreeReforestation}</span>
                        </StyledTd>
                    </>
                )}
            </tr>
        );
    }
}

const centeredContainerStyle = {
    height: '100%',
    verticalAlign: 'middle'
};

const getFixedWhatSpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 3;
        case PlantingProjectView.Reforestation:
            return 3;
        case PlantingProjectView.Community:
            return 3;
        case PlantingProjectView.Carbon:
            return 3;
        case PlantingProjectView.CommunityCanopy:
            return 3;
        case PlantingProjectView.CommunityTreeDistributor:
            return 3;
    }
};

const getNonFixedWhatSpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 2;
        case PlantingProjectView.Reforestation:
            return 1;
        case PlantingProjectView.Community:
            return 1;
        case PlantingProjectView.Carbon:
            return 1;
        case PlantingProjectView.CommunityCanopy:
            return 1;
        case PlantingProjectView.CommunityTreeDistributor:
            return 1;
    }
};

const getWhereSpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 9;
        case PlantingProjectView.Reforestation:
            return 3;
        case PlantingProjectView.Community:
            return 8;
        case PlantingProjectView.Carbon:
            return 2;
        case PlantingProjectView.CommunityCanopy:
            return 0;
        case PlantingProjectView.CommunityTreeDistributor:
            return 0;
    }
};

const getWhenSpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 3;
        case PlantingProjectView.Reforestation:
            return 2;
        case PlantingProjectView.Community:
            return 1;
        case PlantingProjectView.Carbon:
            return 1;
        case PlantingProjectView.CommunityCanopy:
            return 1;
        case PlantingProjectView.CommunityTreeDistributor:
            return 1;
    }
};

const getWhySpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 4;
        case PlantingProjectView.Reforestation:
            return 3;
        case PlantingProjectView.Community:
            return 3;
        case PlantingProjectView.Carbon:
            return 2;
        case PlantingProjectView.CommunityCanopy:
            return 2;
        case PlantingProjectView.CommunityTreeDistributor:
            return 2;
    }
};

const getWhoSpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 8;
        case PlantingProjectView.Reforestation:
            return 5;
        case PlantingProjectView.Community:
            return 3;
        case PlantingProjectView.Carbon:
            return 1;
        case PlantingProjectView.CommunityCanopy:
            return 1;
        case PlantingProjectView.CommunityTreeDistributor:
            return 1;
    }
};

const getWhatPlantedSpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 8;
        case PlantingProjectView.Reforestation:
            return 4;
        case PlantingProjectView.Community:
            return 5;
        case PlantingProjectView.Carbon:
            return 1;
        case PlantingProjectView.CommunityCanopy:
            return 0;
        case PlantingProjectView.CommunityTreeDistributor:
            return 0;
    }
};

const getImagesSpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 4;
        case PlantingProjectView.Reforestation:
            return 4;
        case PlantingProjectView.Community:
            return 4;
        case PlantingProjectView.Carbon:
            return 4;
        case PlantingProjectView.CommunityCanopy:
            return 0;
        case PlantingProjectView.CommunityTreeDistributor:
            return 4;
    }
};

const getBenefitsSpan = (view: PlantingProjectView) => {
    switch (view) {
        case PlantingProjectView.Default:
            return 3;
        case PlantingProjectView.Reforestation:
            return 3;
        case PlantingProjectView.Community:
            return 0;
        case PlantingProjectView.Carbon:
            return 0;
        case PlantingProjectView.CommunityCanopy:
            return 0;
        case PlantingProjectView.CommunityTreeDistributor:
            return 0;
    }
};

function PlantingProjectSearch({
    forSelection = false,
    projectSelected = () => {},
    projectType = undefined
}: {
    forSelection?: boolean;
    projectSelected?: (projectId: number) => void;
    projectType?: PlantingProjectType;
}) {
    const config = useConfig();
    const [projects, setProjects] = useState([] as PlantingProject[]);
    const [, startTransition] = useTransition();

    const fixedView = getViewForProjectType(projectType);

    const [searchParams, setSearchParams] = useSearchParams();
    let urlFilter = JSON.parse(searchParams.get('filter')) as Filter;
    if (fixedView) {
        if (!urlFilter) urlFilter = {} as Filter;
        urlFilter.ProjectType = getProjectTypeForView(fixedView);
    }
    const [filter, setFilter] = useState(urlFilter);

    const [view, setView] = useState<PlantingProjectView>(
        fixedView ?? getViewForProjectType(filter?.ProjectType) ?? PlantingProjectView.Default
    );
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [projectCount, setProjectCount] = useState(0);
    const [displayAmount, setDisplayAmount] = useState<number>(100);
    const [lythoImageData, setLythoImageData] = useState(null);

    useEffect(() => {
        const getProjects = async () => {
            const result = await (await fetch('/api/planting-projects')).json();
            setProjects(result);
        };

        getProjects();
    }, []);

    const getLythoImageData = async () => {
        const result = await (await fetch(`/api/lytho-hero-data`)).json();
        setLythoImageData(result);
    };

    useEffect(() => {
        const result = getLythoImageData();
        setLythoImageData(result);
    }, []);

    useEffect(() => {
        startTransition(() => {
            const fps = filterProjects(projects ?? [], lythoImageData, filter).slice(0, 100);
            setFilteredProjects(fps);
        });
    }, [projects, filter]);

    useEffect(() => {
        startTransition(() => {
            const preFps = filterProjects(projects ?? [], lythoImageData, filter);
            setProjectCount(preFps.length);
            const fps = preFps.slice(0, displayAmount);
            setFilteredProjects(fps);
        });
    }, [projects, filter, displayAmount]);

    const updateFilter = (filter: Filter) => {
        setFilter(filter);
        startTransition(() => {
            setSearchParams(filter ? {filter: JSON.stringify(filter)} : {}, {replace: true});
        });
    };

    const removeFilters = (newView = view) => {
        const filters = {} as Filter;
        if (newView !== null && newView !== PlantingProjectView.Default)
            filters.ProjectType = getProjectTypeForView(newView);
        updateFilter(filters);
    };

    const updateDisplayCountDropdown = (event) => {
        const parsedInt = parseInt(event.target.value, 10);
        setDisplayAmount(parsedInt);
    };

    const hasAnyFiltersOtherThanProjectType = (filter: Filter) => {
        if (!filter) return;

        const hasFilters = Object.keys(filter).some(function (item) {
            return item !== 'ProjectType';
        });
        return hasFilters;
    };

    const showForReforestation = showReforestationColumn(view);
    const showForCommunity = showCommunityColumn(view);
    const showForCarbon = showCarbonColumn(view);
    const showForCommunityCanopy = showCommunityCanopyColumn(view);
    const showForCommunityTreeDistributor = showCommunityTreeDistributorColumn(view);

    const fixedWhatSpan = getFixedWhatSpan(view);
    const nonFixedWhatSpan = getNonFixedWhatSpan(view);
    const whereSpan = getWhereSpan(view);
    const whenSpan = getWhenSpan(view);
    const whySpan = getWhySpan(view);
    const whoSpan = getWhoSpan(view);
    const whatPlantedSpan = getWhatPlantedSpan(view);
    const benefitsSpan = getBenefitsSpan(view);
    const imagesSpan = getImagesSpan(view);

    const changeView = (view: PlantingProjectView) => {
        setView(view);
        removeFilters(view);
    };

    return (
        <PlantingProjectsLayout>
            <HeaderWrapper>
                <HeaderArea>
                    <Title>Planting Projects</Title>
                    {hasAnyFiltersOtherThanProjectType(filter) && (
                        <RemoveFilterButton onClick={() => removeFilters(view)} />
                    )}
                    <DisplayCountWrapper>
                        Showing
                        <DisplayCountDropdown>
                            <Tippy content="Larger selections will be slower performing">
                                <DisplayCountSelect
                                    value={displayAmount.toString()}
                                    onChange={updateDisplayCountDropdown}>
                                    {projectCount >= 100 && <option value="100">100</option>}
                                    {projectCount >= 200 && <option value="200">200</option>}
                                    {projectCount >= 300 && <option value="300">300</option>}
                                    {projectCount >= 500 && <option value="500">500</option>}
                                    {projectCount >= 1000 && <option value="1000">1000</option>}
                                    <option value={projectCount}>{projectCount}</option>
                                </DisplayCountSelect>
                            </Tippy>
                        </DisplayCountDropdown>{' '}
                        of {projectCount}
                    </DisplayCountWrapper>
                </HeaderArea>
            </HeaderWrapper>
            <ViewFilter>
                <PlantingProjectViewSelector
                    changeView={changeView}
                    view={view}
                    allowViewChange={projectType == undefined}
                />
            </ViewFilter>
            <ProjectsArea>
                <table className="table">
                    <thead>
                        <tr>
                            <StickyHeader
                                colSpan={1}
                                style={{
                                    border: 'none',
                                    zIndex: 1,
                                    ...fixedValColumn,
                                    backgroundColor: 'rgb(231 222 237)'
                                }}></StickyHeader>
                            <StickyHeader
                                colSpan={fixedWhatSpan}
                                style={{
                                    border: 'none',
                                    zIndex: 1,
                                    ...fixedProjectIdColumn,
                                    textAlign: 'center',
                                    backgroundColor: 'rgb(245, 230, 255)'
                                }}>
                                What is the project?
                            </StickyHeader>
                            <StickyHeader
                                colSpan={nonFixedWhatSpan}
                                style={{
                                    border: 'none',
                                    backgroundColor: 'rgb(245, 230, 255)'
                                }}></StickyHeader>
                            {whereSpan > 0 && (
                                <StickyHeader
                                    colSpan={whereSpan}
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        backgroundColor: 'rgb(215, 230, 255)'
                                    }}>
                                    {whereSpan > 1 ? 'Where is the project?' : 'Where?'}
                                </StickyHeader>
                            )}
                            <StickyHeader
                                colSpan={whenSpan}
                                style={{
                                    border: 'none',
                                    textAlign: 'center',
                                    backgroundColor: 'rgb(230, 245, 255)',
                                    minWidth: '200px'
                                }}>
                                When is the project?
                            </StickyHeader>
                            <StickyHeader
                                colSpan={whySpan}
                                style={{
                                    border: 'none',
                                    textAlign: 'center',
                                    backgroundColor: 'rgb(215, 230, 255)'
                                }}>
                                Why is the project being done?
                            </StickyHeader>
                            {whoSpan > 0 && (
                                <StickyHeader
                                    colSpan={whoSpan}
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        backgroundColor: 'rgb(230, 245, 255)'
                                    }}>
                                    Who is participating in the project?
                                </StickyHeader>
                            )}
                            {whatPlantedSpan > 0 && (
                                <StickyHeader
                                    colSpan={whatPlantedSpan}
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        backgroundColor: 'rgb(210, 235, 250)',
                                        minWidth: '200px'
                                    }}>
                                    What is being planted?
                                </StickyHeader>
                            )}
                            {showForCarbon && (
                                <StickyHeader
                                    colSpan={8}
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        backgroundColor: 'rgb(199, 219, 251)'
                                    }}>
                                    Additional Project Details
                                </StickyHeader>
                            )}
                            {imagesSpan > 0 && (
                                <StickyHeader
                                    colSpan={imagesSpan}
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        backgroundColor: 'rgb(230, 245, 255)'
                                    }}>
                                    Images
                                </StickyHeader>
                            )}
                            {benefitsSpan > 0 && (
                                <StickyHeader
                                    colSpan={benefitsSpan}
                                    style={{
                                        border: 'none',
                                        textAlign: 'center',
                                        backgroundColor: 'rgb(215, 230, 255)'
                                    }}>
                                    Benefits
                                </StickyHeader>
                            )}
                        </tr>
                        <tr>
                            <StickyHeader
                                style={{
                                    ...offsetOneRow,
                                    zIndex: 1,
                                    ...fixedValColumn,
                                    width: '50px',
                                    minWidth: '50px',
                                    maxWidth: '50px'
                                }}>
                                <Tippy content="Shows which projects have been fully validated">
                                    <span>Val</span>
                                </Tippy>
                            </StickyHeader>

                            <StickyHeader
                                style={{
                                    ...offsetOneRow,
                                    zIndex: 1,
                                    ...fixedProjectIdColumn,
                                    width: '90px',
                                    minWidth: '90px',
                                    maxWidth: '90px'
                                }}>
                                Project id
                            </StickyHeader>
                            <StickyHeader
                                style={{
                                    ...offsetOneRow,
                                    zIndex: 1,
                                    ...fixedProjectTypeColumn,
                                    width: '190px',
                                    minWidth: '190px',
                                    maxWidth: '190px'
                                }}>
                                Project type
                            </StickyHeader>
                            <StickyHeader
                                style={{
                                    ...offsetOneRow,
                                    zIndex: 1,
                                    ...fixedNameColumn,
                                    width: '355px',
                                    minWidth: '355px',
                                    maxWidth: '355px'
                                }}>
                                Name
                            </StickyHeader>
                            {(showForReforestation || showForCommunity || showForCarbon) && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '125px',
                                        minWidth: '125px',
                                        maxWidth: '125px'
                                    }}>
                                    Project status
                                </StickyHeader>
                            )}
                            {showForCommunityCanopy && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '370px',
                                        minWidth: '370px',
                                        maxWidth: '370px'
                                    }}>
                                    Beehive campaigns
                                </StickyHeader>
                            )}

                            {(showForReforestation || showForCommunity || showForCarbon) && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '355px',
                                        minWidth: '355px',
                                        maxWidth: '355px'
                                    }}>
                                    Location
                                </StickyHeader>
                            )}
                            {showForReforestation && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '320px',
                                        minWidth: '320px',
                                        maxWidth: '320px'
                                    }}>
                                    TTF forest
                                </StickyHeader>
                            )}
                            {showForCommunity && (
                                <>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '320px',
                                            minWidth: '320px',
                                            maxWidth: '320px'
                                        }}>
                                        Community site name
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '360px',
                                            minWidth: '360px',
                                            maxWidth: '360px'
                                        }}>
                                        Community site address
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '195px',
                                            minWidth: '195px',
                                            maxWidth: '195px'
                                        }}>
                                        Community site city
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '195px',
                                            minWidth: '195px',
                                            maxWidth: '195px'
                                        }}>
                                        Community site state
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '160px',
                                            minWidth: '160px',
                                            maxWidth: '160px'
                                        }}>
                                        Community site postal code
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '120px',
                                            minWidth: '120px',
                                            maxWidth: '120px'
                                        }}>
                                        Community site country
                                    </StickyHeader>
                                </>
                            )}
                            {(showForReforestation || showForCommunity || showForCarbon) && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '60px',
                                        minWidth: '60px',
                                        maxWidth: '60px'
                                    }}>
                                    Map
                                </StickyHeader>
                            )}
                            {(showForCarbon || showForReforestation) && (
                                <StickyNumericHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '130px',
                                        minWidth: '130px',
                                        maxWidth: '130px'
                                    }}>
                                    Project years
                                </StickyNumericHeader>
                            )}
                            {(showForCommunity ||
                                showForCommunityCanopy ||
                                showForCommunityTreeDistributor) && (
                                <StickyDateHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '230px',
                                        minWidth: '230px',
                                        maxWidth: '230px'
                                    }}>
                                    Community event dates
                                </StickyDateHeader>
                            )}
                            {showForReforestation && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '290px',
                                        minWidth: '290px',
                                        maxWidth: '290px'
                                    }}>
                                    TTF planting timeframe
                                </StickyHeader>
                            )}
                            {(showForReforestation ||
                                showForCommunity ||
                                showForCommunityCanopy ||
                                showForCommunityTreeDistributor) && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '230px',
                                        minWidth: '230px',
                                        maxWidth: '230px'
                                    }}>
                                    Reason for planting
                                </StickyHeader>
                            )}
                            {(showForReforestation ||
                                showForCommunity ||
                                showForCommunityTreeDistributor) && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    Impact story - in progress
                                </StickyHeader>
                            )}
                            <StickyHeader
                                style={{
                                    ...offsetOneRow,
                                    width: '120px',
                                    minWidth: '120px',
                                    maxWidth: '120px'
                                }}>
                                Impact story - completed
                            </StickyHeader>
                            {showForCarbon && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '195px',
                                        minWidth: '300px',
                                        maxWidth: '300px'
                                    }}>
                                    Sustainable Development Goals
                                </StickyHeader>
                            )}
                            {(showForCommunityCanopy || showForCommunityTreeDistributor) && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '350px',
                                        minWidth: '350px',
                                        maxWidth: '350px'
                                    }}>
                                    Funding partners
                                </StickyHeader>
                            )}
                            {(showForReforestation || showForCommunity || showForCarbon) && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '425px',
                                        minWidth: '425px',
                                        maxWidth: '425px'
                                    }}>
                                    Planting partner
                                </StickyHeader>
                            )}
                            {showForReforestation && (
                                <>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '160px',
                                            minWidth: '160px',
                                            maxWidth: '160px'
                                        }}>
                                        TTF commitments
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '150px',
                                            minWidth: '150px',
                                            maxWidth: '150px'
                                        }}>
                                        TTF commitment fiscal year
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '220px',
                                            minWidth: '220px',
                                            maxWidth: '220px'
                                        }}>
                                        TTF agreement number
                                    </StickyHeader>
                                    <StickyNumericHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '160px',
                                            minWidth: '160px',
                                            maxWidth: '160px'
                                        }}>
                                        TTF total tree cost
                                    </StickyNumericHeader>
                                </>
                            )}
                            {showForCommunity && (
                                <>
                                    <StickyNumericHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '160px',
                                            minWidth: '160px',
                                            maxWidth: '160px'
                                        }}>
                                        Community corp. volunteers
                                    </StickyNumericHeader>
                                    <StickyNumericHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '160px',
                                            minWidth: '160px',
                                            maxWidth: '160px'
                                        }}>
                                        Community total participants
                                    </StickyNumericHeader>
                                </>
                            )}
                            {showForReforestation && (
                                <StickyNumericHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    TTF trees
                                </StickyNumericHeader>
                            )}
                            {showForCommunity && (
                                <StickyNumericHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    Community trees planted
                                </StickyNumericHeader>
                            )}
                            {showForCommunity && (
                                <StickyNumericHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    Community trees dist.
                                </StickyNumericHeader>
                            )}
                            {showForReforestation && (
                                <StickyNumericHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    TTF DBH
                                </StickyNumericHeader>
                            )}
                            {showForCommunity && (
                                <StickyNumericHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    Community planted DBH
                                </StickyNumericHeader>
                            )}
                            {showForCommunity && (
                                <StickyNumericHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    Community dist. DBH
                                </StickyNumericHeader>
                            )}
                            {(showForReforestation || showForCarbon) && (
                                <StickyNumericHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    Acres restored
                                </StickyNumericHeader>
                            )}
                            {(showForCommunity || showForReforestation) && (
                                <StickyHeader
                                    style={{
                                        ...offsetOneRow,
                                        width: '120px',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}>
                                    Has tree species
                                </StickyHeader>
                            )}
                            {showForCarbon && (
                                <>
                                    <StickyNumericHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '120px',
                                            minWidth: '120px',
                                            maxWidth: '120px'
                                        }}>
                                        Total Carbon Credits
                                    </StickyNumericHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '195px',
                                            minWidth: '245px',
                                            maxWidth: '240px'
                                        }}>
                                        CC Project Standard
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '195px',
                                            minWidth: '195px',
                                            maxWidth: '195px'
                                        }}>
                                        Carbon Credit Type
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '195px',
                                            minWidth: '195px',
                                            maxWidth: '195px'
                                        }}>
                                        CC ICROA Endorsed Standard
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '195px',
                                            minWidth: '195px',
                                            maxWidth: '195px'
                                        }}>
                                        CC CORSIA Eligible
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '250px',
                                            minWidth: '250px',
                                            maxWidth: '250px'
                                        }}>
                                        CC Additional Certifications
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '195px',
                                            minWidth: '195px',
                                            maxWidth: '195px'
                                        }}>
                                        CC More Details Link
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '195px',
                                            minWidth: '195px',
                                            maxWidth: '195px'
                                        }}>
                                        CC Registry Link
                                    </StickyHeader>
                                </>
                            )}
                            {(showForReforestation ||
                                showForCommunity ||
                                showForCarbon ||
                                showCommunityCanopyColumn) && (
                                <>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '120px',
                                            minWidth: '120px',
                                            maxWidth: '120px'
                                        }}>
                                        DAM tag
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '70px',
                                            minWidth: '70px',
                                            maxWidth: '70px'
                                        }}>
                                        Lytho images
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '70px',
                                            minWidth: '70px',
                                            maxWidth: '70px'
                                        }}>
                                        Image Count
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '70px',
                                            minWidth: '70px',
                                            maxWidth: '70px'
                                        }}>
                                        Has Hero
                                    </StickyHeader>
                                </>
                            )}

                            {showForReforestation && (
                                <>
                                    <StickyNumericHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '120px',
                                            minWidth: '120px',
                                            maxWidth: '120px'
                                        }}>
                                        Gals runoff per tree
                                    </StickyNumericHeader>
                                    <StickyNumericHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '120px',
                                            minWidth: '120px',
                                            maxWidth: '120px'
                                        }}>
                                        Tons carbon per tree
                                    </StickyNumericHeader>
                                    <StickyNumericHeader
                                        style={{
                                            ...offsetOneRow,
                                            width: '120px',
                                            minWidth: '120px',
                                            maxWidth: '120px'
                                        }}>
                                        Tons air poll. per tree
                                    </StickyNumericHeader>
                                </>
                            )}
                        </tr>
                        <tr>
                            <StickyHeader
                                style={{
                                    ...offsetTwoRows,
                                    zIndex: 1,
                                    ...fixedValColumn,
                                    ...centeredContainerStyle
                                }}>
                                <ValidateFilter
                                    value={filter?.EverythingValidated ?? ValidateFilterValue.Unset}
                                    onChange={(e) =>
                                        updateFilter({...filter, EverythingValidated: e.value})
                                    }
                                />
                            </StickyHeader>
                            <StickyHeader
                                style={{...offsetTwoRows, zIndex: 1, ...fixedProjectIdColumn}}>
                                <TextFilter
                                    value={filter?.Id ?? ''}
                                    onChange={(e) => updateFilter({...filter, Id: e.target.value})}
                                />
                            </StickyHeader>
                            <StickyHeader
                                style={{
                                    ...offsetTwoRows,
                                    zIndex: 1,
                                    ...fixedProjectTypeColumn,
                                    ...centeredContainerStyle
                                }}>
                                <ValidateFilter
                                    value={
                                        filter?.ProjectTypeValidated ?? ValidateFilterValue.Unset
                                    }
                                    onChange={(e) =>
                                        updateFilter({...filter, ProjectTypeValidated: e.value})
                                    }
                                />
                            </StickyHeader>
                            <StickyHeader style={{...offsetTwoRows, zIndex: 1, ...fixedNameColumn}}>
                                <CellWithValidator>
                                    <ValidateFilter
                                        value={filter?.NameValidated ?? ValidateFilterValue.Unset}
                                        onChange={(e) =>
                                            updateFilter({...filter, NameValidated: e.value})
                                        }
                                    />
                                    <TextFilter
                                        value={filter?.Name ?? ''}
                                        onChange={(e) =>
                                            updateFilter({...filter, Name: e.target.value})
                                        }
                                    />
                                </CellWithValidator>
                            </StickyHeader>
                            {(showForReforestation || showForCommunity || showForCarbon) && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.ProjectStatusValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectStatusValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.ProjectStatus ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectStatus: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}
                            {showForCommunityCanopy && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <TextFilter
                                        value={filter?.BHCampaigns ?? ''}
                                        onChange={(e) =>
                                            updateFilter({
                                                ...filter,
                                                BHCampaigns: e.target.value
                                            })
                                        }
                                    />
                                </StickyHeader>
                            )}

                            {(showForReforestation || showForCommunity || showForCarbon) && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.LocationValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    LocationValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.Location ?? ''}
                                            onChange={(e) =>
                                                updateFilter({...filter, Location: e.target.value})
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}
                            {showForReforestation && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.TTFForestCodeValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    TTFForestCodeValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.TTFForest ?? ''}
                                            onChange={(e) =>
                                                updateFilter({...filter, TTFForest: e.target.value})
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}
                            {showForCommunity && (
                                <>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CommunitySiteNameValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteNameValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CommunitySiteName ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteName: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CommunitySiteAddressValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteAddressValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CommunitySiteAddress ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteAddress: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CommunitySiteCityValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteCityValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CommunitySiteCity ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteCity: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CommunitySiteStateCodeValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteStateCodeValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CommunitySiteStateCode ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteStateCode: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CommunitySitePostalCodeValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySitePostalCodeValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CommunitySitePostalCode ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySitePostalCode: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CommunitySiteCountryCodeValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteCountryCodeValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CommunitySiteCountryCode ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunitySiteCountryCode: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                </>
                            )}
                            {(showForReforestation || showForCommunity || showForCarbon) && (
                                <StickyHeader style={{...offsetTwoRows, ...centeredContainerStyle}}>
                                    <ValidateFilter
                                        value={filter?.MapValidated ?? ValidateFilterValue.Unset}
                                        onChange={(e) =>
                                            updateFilter({...filter, MapValidated: e.value})
                                        }
                                    />
                                </StickyHeader>
                            )}
                            {(showForCarbon || showForReforestation) && (
                                <StickyNumericHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.ProjectYearsValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectYearsValidated: e.value
                                                })
                                            }
                                        />
                                        <NumericFilter
                                            value={filter?.ProjectYears ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectYears: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyNumericHeader>
                            )}
                            {(showForCommunity ||
                                showForCommunityCanopy ||
                                showForCommunityTreeDistributor) && (
                                <StickyDateHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.CommunityEventDatesValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    CommunityEventDatesValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.CommunityEventDates ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    CommunityEventDates: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyDateHeader>
                            )}
                            {showForReforestation && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.TTFPlantingTimeframeValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    TTFPlantingTimeframeValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.TTFPlantingTimeframe ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    TTFPlantingTimeframe: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}
                            {(showForReforestation ||
                                showForCommunity ||
                                showForCommunityCanopy ||
                                showForCommunityTreeDistributor) && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.ReasonForPlantingValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ReasonForPlantingValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.ReasonForPlanting ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ReasonForPlanting: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}
                            {(showForReforestation ||
                                showForCommunity ||
                                showForCommunityTreeDistributor) && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.ImpactStoryInProgressValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ImpactStoryInProgressValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.HasImpactStoryInProgress ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    HasImpactStoryInProgress: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}
                            <StickyHeader style={{...offsetTwoRows}}>
                                <CellWithValidator>
                                    <ValidateFilter
                                        value={
                                            filter?.ImpactStoryCompletedValidated ??
                                            ValidateFilterValue.Unset
                                        }
                                        onChange={(e) =>
                                            updateFilter({
                                                ...filter,
                                                ImpactStoryCompletedValidated: e.value
                                            })
                                        }
                                    />
                                    <TextFilter
                                        value={filter?.HasImpactStoryCompleted ?? ''}
                                        onChange={(e) =>
                                            updateFilter({
                                                ...filter,
                                                HasImpactStoryCompleted: e.target.value
                                            })
                                        }
                                    />
                                </CellWithValidator>
                            </StickyHeader>
                            {showForCarbon && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.SustainableDevelopmentGoalsValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    SustainableDevelopmentGoalsValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.SustainableDevelopmentGoals ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    SustainableDevelopmentGoals: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}
                            {(showForCommunityCanopy || showForCommunityTreeDistributor) && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <TextFilter
                                        value={filter?.FundingPartners ?? ''}
                                        onChange={(e) =>
                                            updateFilter({
                                                ...filter,
                                                FundingPartners: e.target.value
                                            })
                                        }
                                    />
                                </StickyHeader>
                            )}
                            {(showForReforestation || showForCommunity || showForCarbon) && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.PlantingPartnerValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    PlantingPartnerValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.PlantingPartner ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    PlantingPartner: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}
                            {showForReforestation && (
                                <>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <TextFilter
                                            value={filter?.TTFCommitments ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    TTFCommitments: e.target.value
                                                })
                                            }
                                        />
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.TTFCommitmentFiscalYearValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        TTFCommitmentFiscalYearValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.TTFCommitmentFiscalYear ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        TTFCommitmentFiscalYear: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyNumericHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.TTFAgreementNumberValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        TTFAgreementNumberValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.TTFAgreementNumber ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        TTFAgreementNumber: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyNumericHeader>
                                    <StickyNumericHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.TTFTotalTreeCostValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        TTFTotalTreeCostValidated: e.value
                                                    })
                                                }
                                            />
                                            <NumericFilter
                                                value={filter?.TTFTotalTreeCost ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        TTFTotalTreeCost: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyNumericHeader>
                                </>
                            )}
                            {showForCommunity && (
                                <>
                                    <StickyNumericHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CommunityNumberOfCorporateVolunteersValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunityNumberOfCorporateVolunteersValidated:
                                                            e.value
                                                    })
                                                }
                                            />
                                            <NumericFilter
                                                value={
                                                    filter?.CommunityNumberOfCorporateVolunteers ??
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunityNumberOfCorporateVolunteers:
                                                            e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyNumericHeader>
                                    <StickyNumericHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CommunityNumberOfTotalParticipantsValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunityNumberOfTotalParticipantsValidated:
                                                            e.value
                                                    })
                                                }
                                            />
                                            <NumericFilter
                                                value={
                                                    filter?.CommunityNumberOfTotalParticipants ?? ''
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CommunityNumberOfTotalParticipants:
                                                            e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyNumericHeader>
                                </>
                            )}
                            {showForReforestation && (
                                <StickyNumericHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.ProjectTTFTreesValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectTTFTreesValidated: e.value
                                                })
                                            }
                                        />
                                        <NumericFilter
                                            value={filter?.ProjectTTFTrees ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectTTFTrees: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyNumericHeader>
                            )}
                            {showForCommunity && (
                                <StickyNumericHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.ProjectCommunityTreesPlantedValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectCommunityTreesPlantedValidated: e.value
                                                })
                                            }
                                        />
                                        <NumericFilter
                                            value={filter?.ProjectCommunityTreesPlanted ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectCommunityTreesPlanted: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyNumericHeader>
                            )}
                            {showForCommunity && (
                                <StickyNumericHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.ProjectCommunityTreesDistributedValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectCommunityTreesDistributedValidated:
                                                        e.value
                                                })
                                            }
                                        />
                                        <NumericFilter
                                            value={filter?.ProjectCommunityTreesDistributed ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectCommunityTreesDistributed: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyNumericHeader>
                            )}
                            {showForReforestation && (
                                <StickyNumericHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.TTFDBHValidated ?? ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({...filter, TTFDBHValidated: e.value})
                                            }
                                        />
                                        <NumericFilter
                                            value={filter?.TTFDBH ?? ''}
                                            onChange={(e) =>
                                                updateFilter({...filter, TTFDBH: e.target.value})
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyNumericHeader>
                            )}
                            {showForCommunity && (
                                <StickyNumericHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.CommunityDBHTreesPlantedValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    CommunityDBHTreesPlantedValidated: e.value
                                                })
                                            }
                                        />
                                        <NumericFilter
                                            value={filter?.CommunityDBHTreesPlanted ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    CommunityDBHTreesPlanted: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyNumericHeader>
                            )}
                            {showForCommunity && (
                                <StickyNumericHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.CommunityDBHTreesDistributedValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    CommunityDBHTreesDistributedValidated: e.value
                                                })
                                            }
                                        />
                                        <NumericFilter
                                            value={filter?.CommunityDBHTreesDistributed ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    CommunityDBHTreesDistributed: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyNumericHeader>
                            )}
                            {(showForCarbon || showForReforestation) && (
                                <StickyNumericHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.ProjectAcresRestoredValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectAcresRestoredValidated: e.value
                                                })
                                            }
                                        />
                                        <NumericFilter
                                            value={filter?.ProjectAcresRestored ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ProjectAcresRestored: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyNumericHeader>
                            )}
                            {(showForCommunity || showForReforestation) && (
                                <StickyHeader style={{...offsetTwoRows}}>
                                    <CellWithValidator>
                                        <ValidateFilter
                                            value={
                                                filter?.TreeSpeciesValidated ??
                                                ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    TreeSpeciesValidated: e.value
                                                })
                                            }
                                        />
                                        <TextFilter
                                            value={filter?.TreeSpecies ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    TreeSpecies: e.target.value
                                                })
                                            }
                                        />
                                    </CellWithValidator>
                                </StickyHeader>
                            )}

                            {showForCarbon && (
                                <>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CarbonTotalCreditsIssuedValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonTotalCreditsIssuedValidated: e.value
                                                    })
                                                }
                                            />
                                            <NumericFilter
                                                value={filter?.CarbonTotalCreditsIssued ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonTotalCreditsIssued: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CarbonProjectStandardValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonProjectStandardValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CarbonProjectStandard ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonProjectStandard: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CarbonCreditCreditTypeValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonCreditCreditTypeValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CarbonCreditCreditType ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonCreditCreditType: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CarbonICroaEndorsedStandardValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonICroaEndorsedStandardValidated:
                                                            e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CarbonICroaEndorsedStandard ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonICroaEndorsedStandard: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CarbonCorsiaEligibleValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonCorsiaEligibleValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CarbonCorsiaEligible ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonCorsiaEligible: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.AdditionalCarbonCreditCertificationsValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        AdditionalCarbonCreditCertificationsValidated:
                                                            e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={
                                                    filter?.CarbonCreditsAdditionalCertifications ??
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonCreditsAdditionalCertifications:
                                                            e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CarbonAdditionalDetailsLinkValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonAdditionalDetailsLinkValidated:
                                                            e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CarbonAdditionalDetailsLink ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonAdditionalDetailsLink: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <CellWithValidator>
                                            <ValidateFilter
                                                value={
                                                    filter?.CarbonProjectRegistryLinkValidated ??
                                                    ValidateFilterValue.Unset
                                                }
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonProjectRegistryLinkValidated: e.value
                                                    })
                                                }
                                            />
                                            <TextFilter
                                                value={filter?.CarbonProjectRegistryLink ?? ''}
                                                onChange={(e) =>
                                                    updateFilter({
                                                        ...filter,
                                                        CarbonProjectRegistryLink: e.target.value
                                                    })
                                                }
                                            />
                                        </CellWithValidator>
                                    </StickyHeader>
                                </>
                            )}
                            {(showForReforestation ||
                                showForCommunity ||
                                showForCarbon ||
                                showCommunityCanopyColumn) && (
                                <>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <TextFilter
                                            value={filter?.DAMTag ?? ''}
                                            onChange={(e) =>
                                                updateFilter({...filter, DAMTag: e.target.value})
                                            }
                                        />
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <ValidateFilter
                                            value={
                                                filter?.ImagesValidated ?? ValidateFilterValue.Unset
                                            }
                                            onChange={(e) =>
                                                updateFilter({...filter, ImagesValidated: e.value})
                                            }
                                        />
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetTwoRows,
                                            zIndex: 1,
                                            ...fixedProjectIdColumn
                                        }}>
                                        <TextFilter
                                            value={filter?.ImageCount ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    ImageCount: e.target.value
                                                })
                                            }
                                        />
                                    </StickyHeader>
                                    <StickyHeader
                                        style={{
                                            ...offsetTwoRows,
                                            zIndex: 1,
                                            ...fixedProjectIdColumn
                                        }}>
                                        <TextFilter
                                            value={filter?.HasHeroImage ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    HasHeroImage: e.target.value
                                                })
                                            }
                                        />
                                    </StickyHeader>
                                </>
                            )}

                            {showForReforestation && (
                                <>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <NumericFilter
                                            value={
                                                filter?.GallonsTotalRunoffAvoidedPerTreeReforestation ??
                                                ''
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    GallonsTotalRunoffAvoidedPerTreeReforestation:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <NumericFilter
                                            value={filter?.TonsCarbonPerTreeReforestation ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    TonsCarbonPerTreeReforestation: e.target.value
                                                })
                                            }
                                        />
                                    </StickyHeader>
                                    <StickyHeader style={{...offsetTwoRows}}>
                                        <NumericFilter
                                            value={
                                                filter?.TonsAirPollutionPerTreeReforestation ?? ''
                                            }
                                            onChange={(e) =>
                                                updateFilter({
                                                    ...filter,
                                                    TonsAirPollutionPerTreeReforestation:
                                                        e.target.value
                                                })
                                            }
                                        />
                                    </StickyHeader>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProjects.map((project, i) => (
                            <ProjectRow
                                key={i}
                                project={project}
                                forSelection={forSelection}
                                projectSelected={projectSelected}
                                showForReforestation={showForReforestation}
                                showForCommunity={showForCommunity}
                                showForCarbon={showForCarbon}
                                showForCommunityCanopy={showForCommunityCanopy}
                                showForCommunityTreeDistributor={showForCommunityTreeDistributor}
                                treeHarmonyBaseUrl={config.treeHarmonyBaseUrl}
                                lythoData={lythoImageData[project.DAMTag] ?? null}
                            />
                        ))}
                    </tbody>
                </table>
            </ProjectsArea>
        </PlantingProjectsLayout>
    );
}

export default PlantingProjectSearch;
