import {Tab, Tabs, TabSpacer} from '../../components/Tab/Tabs';
import {PlantingProjectType} from '../../entities';

export enum PlantingProjectView {
    Default = 0,
    Reforestation = 1,
    Community = 2,
    Carbon = 3,
    CommunityCanopy = 4,
    CommunityTreeDistributor = 5
}

export function getViewForProjectType(projectType: PlantingProjectType) {
    switch (projectType) {
        case PlantingProjectType.Reforestation:
            return PlantingProjectView.Reforestation;
        case PlantingProjectType.CommunityEvent:
            return PlantingProjectView.Community;
        case PlantingProjectType.Carbon:
            return PlantingProjectView.Carbon;
        case PlantingProjectType.CommunityCanopy:
            return PlantingProjectView.CommunityCanopy;
        case PlantingProjectType.CommunityTreeDistributor:
            return PlantingProjectView.CommunityTreeDistributor;
        default:
            return undefined;
    }
}

export function showReforestationColumn(view: PlantingProjectView) {
    switch (view) {
        case PlantingProjectView.Default:
            return true;
        case PlantingProjectView.Reforestation:
            return true;
        default:
            return false;
    }
}

export function showCommunityColumn(view: PlantingProjectView) {
    switch (view) {
        case PlantingProjectView.Default:
            return true;
        case PlantingProjectView.Community:
            return true;
        default:
            return false;
    }
}

export function showCarbonColumn(view: PlantingProjectView) {
    switch (view) {
        case PlantingProjectView.Default:
            return true;
        case PlantingProjectView.Carbon:
            return true;
        default:
            return false;
    }
}

export function showCommunityCanopyColumn(view: PlantingProjectView) {
    switch (view) {
        case PlantingProjectView.Default:
        case PlantingProjectView.CommunityCanopy:
            return true;
        default:
            return false;
    }
}

export function showCommunityTreeDistributorColumn(view: PlantingProjectView) {
    switch (view) {
        case PlantingProjectView.Default:
        case PlantingProjectView.CommunityTreeDistributor:
            return true;
        default:
            return false;
    }
}

export function getProjectTypeForView(view: PlantingProjectView) {
    switch (view) {
        case PlantingProjectView.Reforestation:
            return PlantingProjectType.Reforestation;
        case PlantingProjectView.Community:
            return PlantingProjectType.CommunityEvent;
        case PlantingProjectView.Carbon:
            return PlantingProjectType.Carbon;
        case PlantingProjectView.CommunityCanopy:
            return PlantingProjectType.CommunityCanopy;
        case PlantingProjectView.CommunityTreeDistributor:
            return PlantingProjectType.CommunityTreeDistributor;
    }
}

function PlantingProjectViewSelector({
    view,
    changeView,
    allowViewChange
}: {
    view: PlantingProjectView;
    changeView: (view: PlantingProjectView) => void;
    allowViewChange: boolean;
}) {
    return (
        <Tabs>
            {(allowViewChange || view === PlantingProjectView.Default) && (
                <>
                    <Tab
                        active={view === PlantingProjectView.Default}
                        onClick={() => changeView(PlantingProjectView.Default)}>
                        All
                    </Tab>
                    <TabSpacer />
                </>
            )}
            {(allowViewChange || view === PlantingProjectView.Reforestation) && (
                <>
                    <Tab
                        active={view === PlantingProjectView.Reforestation}
                        onClick={() => changeView(PlantingProjectView.Reforestation)}>
                        Reforestation
                    </Tab>
                    <TabSpacer />
                </>
            )}
            {(allowViewChange || view == PlantingProjectView.Community) && (
                <>
                    <Tab
                        active={view === PlantingProjectView.Community}
                        onClick={() => changeView(PlantingProjectView.Community)}>
                        Community
                    </Tab>
                    <TabSpacer />
                </>
            )}
            {(allowViewChange || view == PlantingProjectView.Carbon) && (
                <>
                    <Tab
                        active={view === PlantingProjectView.Carbon}
                        onClick={() => changeView(PlantingProjectView.Carbon)}>
                        Carbon Credits
                    </Tab>
                    <TabSpacer />
                </>
            )}
            {(allowViewChange || view == PlantingProjectView.CommunityCanopy) && (
                <Tab
                    active={view === PlantingProjectView.CommunityCanopy}
                    onClick={() => changeView(PlantingProjectView.CommunityCanopy)}>
                    Community Canopy
                </Tab>
            )}
            {(allowViewChange || view == PlantingProjectView.CommunityTreeDistributor) && (
                <Tab
                    active={view === PlantingProjectView.CommunityTreeDistributor}
                    onClick={() => changeView(PlantingProjectView.CommunityTreeDistributor)}>
                    Community Tree Distributor
                </Tab>
            )}
        </Tabs>
    );
}

export default PlantingProjectViewSelector;
