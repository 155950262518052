import {
    PlantingProjectType,
    TreeSpecies,
    PlantingProject as ProjectEntity,
    CarbonProjectStandards,
    CarbonCreditCreditType,
    YesNoConditional,
    CarbonCreditsAdditionalCertification,
    SustainableDevelopmentGoal
} from '../../entities';
import {EditedField, RecordEditStatus, Validation, ValidationLevel} from '../../record-editing';
import {decimalPlaces} from '../../validations';
import {absoluteLinkIsValid} from '../../utilities/stringUtilities';

export type EditedProjectBase = {
    EditStatus: RecordEditStatus;
    Id: number;
    ProjectType: EditedField<PlantingProjectType>;
    WasEverReadyToPublish: boolean;
    ControlledByAutomation: boolean;
    Participations: EditedParticipation[];
    ExtraValidations: Validation[];
};

export type EditedReforestationProject = {
    ProjectTypeValidated: EditedField<boolean>;
    ProjectStartYear: EditedField<number>;
    ProjectEndYear: EditedField<number>;
    ProjectYearsValidated: EditedField<boolean>;
    Name: EditedField<string>;
    NameValidated: EditedField<boolean>;
    Location: EditedField<string>;
    LocationValidated: EditedField<boolean>;
    TTFForestCode: EditedField<string>;
    TTFForestCodeValidated: EditedField<boolean>;
    ReasonForPlanting: EditedField<string>;
    ReasonForPlantingValidated: EditedField<boolean>;
    TreeSpeciesPlanted: EditedField<string>;
    TreeSpecies: EditedPlantingProjectTreeSpecies[];
    TreeSpeciesValidated: EditedField<boolean>;
    PlantingPartnerName: EditedField<string>;
    PlantingPartnerNameValidated: EditedField<boolean>;
    ImpactStoryInProgress: EditedField<string>;
    ImpactStoryInProgressValidated: EditedField<boolean>;
    ImpactStoryCompleted: EditedField<string>;
    ImpactStoryCompletedValidated: EditedField<boolean>;
    TTFTrees: EditedField<number>;
    TTFTreesValidated: EditedField<boolean>;
    AcresRestored: EditedField<number>;
    AcresRestoredValidated: EditedField<boolean>;
    TTFTotalTreeCost: EditedField<number>;
    TTFTotalTreeCostValidated: EditedField<boolean>;
    TTFAgreementNumber: EditedField<string>;
    TTFAgreementNumberValidated: EditedField<boolean>;
    TTFCommitments: EditedCommitment[];
    TTFCommitmentFiscalYear: EditedField<string>;
    TTFCommitmentFiscalYearValidated: EditedField<boolean>;
    TTFPlantingTimeframe: EditedField<string>;
    TTFPlantingTimeframeValidated: EditedField<boolean>;
    DAMTag: string;
    ImagesValidated: EditedField<boolean>;
    IsHoldingProject: boolean;
    TTFDBH: EditedField<number>;
    TTFDBHValidated: EditedField<boolean>;
    MapValidated: EditedField<boolean>;
    Completed: EditedField<boolean>;
    CompletedValidated: EditedField<boolean>;
    Participations: EditedParticipation[];
    HasMap: boolean;
} & EditedProjectBase;

export type EditedCommunityProject = {
    ProjectTypeValidated: EditedField<boolean>;
    Name: EditedField<string>;
    NameValidated: EditedField<boolean>;
    Location: EditedField<string>;
    LocationValidated: EditedField<boolean>;
    ReasonForPlanting: EditedField<string>;
    ReasonForPlantingValidated: EditedField<boolean>;
    TreeSpeciesPlanted: EditedField<string>;
    TreeSpecies: EditedPlantingProjectTreeSpecies[];
    TreeSpeciesValidated: EditedField<boolean>;
    PlantingPartnerName: EditedField<string>;
    PlantingPartnerNameValidated: EditedField<boolean>;
    ImpactStoryUnedited: EditedField<string>;
    ImpactStoryInProgress: EditedField<string>;
    ImpactStoryInProgressValidated: EditedField<boolean>;
    ImpactStoryCompleted: EditedField<string>;
    ImpactStoryCompletedValidated: EditedField<boolean>;
    CommunityTreesPlanted: EditedField<number>;
    CommunityTreesPlantedValidated: EditedField<boolean>;
    CommunityTreesDistributed: EditedField<number>;
    CommunityTreesDistributedValidated: EditedField<boolean>;
    CommunityNumberOfCorporateVolunteers: EditedField<number>;
    CommunityNumberOfCorporateVolunteersValidated: EditedField<boolean>;
    CommunityNumberOfTotalParticipants: EditedField<number>;
    CommunityNumberOfTotalParticipantsValidated: EditedField<boolean>;
    CommunityFirstDayOfEvent: EditedField<string>;
    CommunityFinalDayOfEvent: EditedField<string>;
    CommunityEventDatesValidated: EditedField<boolean>;
    CommunitySiteName: EditedField<string>;
    CommunitySiteNameValidated: EditedField<boolean>;
    CommunitySiteAddress: EditedField<string>;
    CommunitySiteAddressValidated: EditedField<boolean>;
    CommunitySiteCity: EditedField<string>;
    CommunitySiteCityValidated: EditedField<boolean>;
    CommunitySiteStateCode: EditedField<string>;
    CommunitySiteStateCodeValidated: EditedField<boolean>;
    CommunitySitePostalCode: EditedField<string>;
    CommunitySitePostalCodeValidated: EditedField<boolean>;
    CommunitySiteCountryCode: EditedField<string>;
    CommunitySiteCountryCodeValidated: EditedField<boolean>;
    DAMTag: string;
    ImagesValidated: EditedField<boolean>;
    CommunityDBHTreesPlanted: EditedField<number>;
    CommunityDBHTreesPlantedValidated: EditedField<boolean>;
    CommunityDBHTreesDistributed: EditedField<number>;
    CommunityDBHTreesDistributedValidated: EditedField<boolean>;
    MapValidated: EditedField<boolean>;
    Completed: EditedField<boolean>;
    CompletedValidated: EditedField<boolean>;
    D365EventId: EditedField<number>;
    D365PlantingProjectId: string;
    D365_eventcoordinationid: EditedField<string>;
    Participations: EditedParticipation[];
    HasMap: boolean;
} & EditedProjectBase;

export type EditedCarbonProject = {
    ProjectTypeValidated: EditedField<boolean>;
    ProjectStartYear: EditedField<number>;
    ProjectEndYear: EditedField<number>;
    ProjectYearsValidated: EditedField<boolean>;
    Name: EditedField<string>;
    NameValidated: EditedField<boolean>;
    Location: EditedField<string>;
    LocationValidated: EditedField<boolean>;
    PlantingPartnerName: EditedField<string>;
    PlantingPartnerNameValidated: EditedField<boolean>;
    ImpactStoryCompleted: EditedField<string>;
    ImpactStoryCompletedValidated: EditedField<boolean>;
    AcresRestored: EditedField<number>;
    AcresRestoredValidated: EditedField<boolean>;
    DAMTag: string;
    ImagesValidated: EditedField<boolean>;
    MapValidated: EditedField<boolean>;
    WasEverReadyToPublish: boolean;
    Completed: EditedField<boolean>;
    CompletedValidated: EditedField<boolean>;
    CarbonTotalCreditsIssued: EditedField<number>;
    CarbonProjectStandard: EditedField<CarbonProjectStandards>;
    CarbonCreditCreditType: EditedField<CarbonCreditCreditType>;
    CarbonICroaEndorsedStandard: EditedField<YesNoConditional>;
    CarbonCorsiaEligible: EditedField<YesNoConditional>;
    CarbonAdditionalDetailsLink: EditedField<string>;
    CarbonProjectRegistryLink: EditedField<string>;
    CarbonCreditsAdditionalCertifications: EditedCarbonCreditsAdditionalCertification[];
    SustainableDevelopmentGoals: EditedSustainableDevelopmentGoal[];
    ControlledByAutomation: boolean;
    Participations: EditedParticipation[];
    CarbonTotalCreditsIssuedValidated: EditedField<boolean>;
    CarbonProjectStandardValidated: EditedField<boolean>;
    CarbonCreditCreditTypeValidated: EditedField<boolean>;
    CarbonICroaEndorsedStandardValidated: EditedField<boolean>;
    CarbonCorsiaEligibleValidated: EditedField<boolean>;
    CarbonAdditionalDetailsLinkValidated: EditedField<boolean>;
    CarbonProjectRegistryLinkValidated: EditedField<boolean>;
    SustainableDevelopmentGoalsValidated: EditedField<boolean>;
    AdditionalCarbonCreditCertificationsValidated: EditedField<boolean>;
    HasMap: boolean;
} & EditedProjectBase;

export type EditedCommunityCanopyProject = {
    ProjectTypeValidated: EditedField<boolean>;
    Name: EditedField<string>;
    ReasonForPlanting: EditedField<string>;
    TreeSpeciesPlanted: EditedField<string>;
    TreeSpecies: EditedPlantingProjectTreeSpecies[];
    ImpactStoryCompleted: EditedField<string>;
    CommunityTreesDistributed: EditedField<number>;
    CommunityFirstDayOfEvent: EditedField<string>;
    CommunityFinalDayOfEvent: EditedField<string>;
    Completed: EditedField<boolean>;
    Participations: EditedParticipation[];
    BHCampaigns: EditedPlantingProjectBHCampaign[];
} & EditedProjectBase;

export type EditedCommunityTreeDistributorProject = {
    ProjectTypeValidated: EditedField<boolean>;
    Name: EditedField<string>;
    ReasonForPlanting: EditedField<string>;
    TreeSpeciesPlanted: EditedField<string>;
    TreeSpecies: EditedPlantingProjectTreeSpecies[];
    ImpactStoryCompleted: EditedField<string>;
    CommunityTreesDistributed: EditedField<number>;
    CommunityFirstDayOfEvent: EditedField<string>;
    CommunityFinalDayOfEvent: EditedField<string>;
    Completed: EditedField<boolean>;
    Participations: EditedParticipation[];
} & EditedProjectBase;

export type EditedProject = object &
    EditedReforestationProject &
    EditedCommunityProject &
    EditedCarbonProject &
    EditedCommunityCanopyProject &
    EditedCommunityTreeDistributorProject;

export type EditedCarbonCreditsAdditionalCertification = {
    EditStatus: RecordEditStatus;
    CarbonCreditsAdditionalCertificationType: CarbonCreditsAdditionalCertification;
};

export type EditedSustainableDevelopmentGoal = {
    EditStatus: RecordEditStatus;
    SustainableDevelopmentGoalType: SustainableDevelopmentGoal;
};

export type EditedPlantingProjectTreeSpecies = {
    EditStatus: RecordEditStatus;
    Id: number;
    Species: TreeSpecies;
    OtherName: EditedField<string>;
};

export type EditedParticipationBase = {
    Id: number;
    PartnerId: number;
    Partner: EditedPartner;
    FundedYear: number;
    DollarsDonated: number;
};

export type EditedReforestationParticipation = {
    TTFTrees: number;
} & EditedParticipationBase;

export type EditedCommunityParticipation = {
    CommunityTreesPlanted: number;
    CommunityTreesDistributed: number;
    CommunityBudgetPassThrough: number;
} & EditedParticipationBase;

export type EditedCarbonParticipation = {
    CarbonCreditsTransacted: number;
    SerialNumber: string;
    ParticipantRegistryLink: string;
    VintageYear: string;
} & EditedParticipationBase;

export type EditedCommunityCanopyParticipation = {
    CommunityTreesDistributed: number;
} & EditedParticipationBase;

export type EditedCommunityTreeDistributorParticipation = {
    CommunityTreesDistributed: number;
} & EditedParticipationBase;

export type EditedParticipation = object &
    EditedReforestationParticipation &
    EditedCommunityParticipation &
    EditedCarbonParticipation &
    EditedCommunityCanopyParticipation &
    EditedCommunityTreeDistributorParticipation;

export type EditedPartner = {
    Name: string;
};

export type EditedCommitment = {
    EditStatus: RecordEditStatus;
    Id: number;
    CommitmentId: number;
};

export type EditedPlantingProjectBHCampaign = {
    EditStatus: RecordEditStatus;
    Id: number;
    CampaignId: string;
};

export function initializeEditedProject(project: ProjectEntity): EditedProject {
    return {
        EditStatus: RecordEditStatus.Existing,
        Id: project.Id,
        ProjectType: new EditedField(project.ProjectType),
        ProjectTypeValidated: new EditedField(project.ProjectTypeValidated),
        ProjectStartYear: new EditedField(project.ProjectStartYear),
        ProjectEndYear: new EditedField(project.ProjectEndYear),
        ProjectYearsValidated: new EditedField(project.ProjectYearsValidated),
        Name: new EditedField(project.Name),
        NameValidated: new EditedField(project.NameValidated),
        Location: new EditedField(project.Location),
        LocationValidated: new EditedField(project.LocationValidated),
        TTFForestCode: new EditedField(project.TTFForestCode),
        TTFForestCodeValidated: new EditedField(project.TTFForestCodeValidated),
        ReasonForPlanting: new EditedField(project.ReasonForPlanting),
        ReasonForPlantingValidated: new EditedField(project.ReasonForPlantingValidated),
        TreeSpeciesPlanted: new EditedField(project.TreeSpeciesPlanted),
        TreeSpecies:
            project.TreeSpecies?.map((ts) => ({
                ...ts,
                EditStatus: RecordEditStatus.Existing,
                OtherName: new EditedField(ts.OtherName)
            })) ?? [],
        TreeSpeciesValidated: new EditedField(project.TreeSpeciesValidated),
        PlantingPartnerName: new EditedField(project.PlantingPartnerName),
        PlantingPartnerNameValidated: new EditedField(project.PlantingPartnerNameValidated),
        ImpactStoryUnedited: new EditedField(project.ImpactStoryUnedited),
        ImpactStoryInProgress: new EditedField(project.ImpactStoryInProgress),
        ImpactStoryInProgressValidated: new EditedField(project.ImpactStoryInProgressValidated),
        ImpactStoryCompleted: new EditedField(project.ImpactStoryCompleted),
        ImpactStoryCompletedValidated: new EditedField(project.ImpactStoryCompletedValidated),
        TTFTrees: new EditedField(project.TTFTrees),
        TTFTreesValidated: new EditedField(project.TTFTreesValidated),
        AcresRestored: new EditedField(project.AcresRestored),
        AcresRestoredValidated: new EditedField(project.AcresRestoredValidated),
        TTFTotalTreeCost: new EditedField(project.TTFTotalTreeCost),
        TTFTotalTreeCostValidated: new EditedField(project.TTFTotalTreeCostValidated),
        TTFAgreementNumber: new EditedField(project.TTFAgreementNumber),
        TTFAgreementNumberValidated: new EditedField(project.TTFAgreementNumberValidated),
        TTFCommitments:
            project.Commitments?.map((commitment) => ({
                ...commitment,
                EditStatus: RecordEditStatus.Existing
            })) ?? [],
        BHCampaigns:
            project.BHCampaigns?.map((campaign) => ({
                ...campaign,
                EditStatus: RecordEditStatus.Existing
            })) ?? [],
        TTFCommitmentFiscalYear: new EditedField(project.TTFCommitmentFiscalYear),
        TTFCommitmentFiscalYearValidated: new EditedField(project.TTFCommitmentFiscalYearValidated),
        CommunityTreesPlanted: new EditedField(project.CommunityTreesPlanted),
        CommunityTreesPlantedValidated: new EditedField(project.CommunityTreesPlantedValidated),
        CommunityTreesDistributed: new EditedField(project.CommunityTreesDistributed),
        CommunityTreesDistributedValidated: new EditedField(
            project.CommunityTreesDistributedValidated
        ),
        CommunityNumberOfCorporateVolunteers: new EditedField(
            project.CommunityNumberOfCorporateVolunteers
        ),
        CommunityNumberOfCorporateVolunteersValidated: new EditedField(
            project.CommunityNumberOfCorporateVolunteersValidated
        ),
        CommunityNumberOfTotalParticipants: new EditedField(
            project.CommunityNumberOfTotalParticipants
        ),
        CommunityNumberOfTotalParticipantsValidated: new EditedField(
            project.CommunityNumberOfTotalParticipantsValidated
        ),
        CommunityFirstDayOfEvent: new EditedField(project.CommunityFirstDayOfEvent),
        CommunityFinalDayOfEvent: new EditedField(project.CommunityFinalDayOfEvent),
        CommunityEventDatesValidated: new EditedField(project.CommunityEventDatesValidated),
        TTFPlantingTimeframe: new EditedField(project.TTFPlantingTimeframe),
        TTFPlantingTimeframeValidated: new EditedField(project.TTFPlantingTimeframeValidated),
        CommunitySiteName: new EditedField(project.CommunitySiteName),
        CommunitySiteNameValidated: new EditedField(project.CommunitySiteNameValidated),
        CommunitySiteAddress: new EditedField(project.CommunitySiteAddress),
        CommunitySiteAddressValidated: new EditedField(project.CommunitySiteAddressValidated),
        CommunitySiteCity: new EditedField(project.CommunitySiteCity),
        CommunitySiteCityValidated: new EditedField(project.CommunitySiteCityValidated),
        CommunitySiteStateCode: new EditedField(project.CommunitySiteStateCode),
        CommunitySiteStateCodeValidated: new EditedField(project.CommunitySiteStateCodeValidated),
        CommunitySitePostalCode: new EditedField(project.CommunitySitePostalCode),
        CommunitySitePostalCodeValidated: new EditedField(project.CommunitySitePostalCodeValidated),
        CommunitySiteCountryCode: new EditedField(project.CommunitySiteCountryCode),
        CommunitySiteCountryCodeValidated: new EditedField(
            project.CommunitySiteCountryCodeValidated
        ),
        DAMTag: project.DAMTag,
        ImagesValidated: new EditedField(project.ImagesValidated),
        IsHoldingProject: project.IsHoldingProject,
        TTFDBH: new EditedField(project.TTFDBH),
        TTFDBHValidated: new EditedField(project.TTFDBHValidated),
        CommunityDBHTreesPlanted: new EditedField(project.CommunityDBHTreesPlanted),
        CommunityDBHTreesPlantedValidated: new EditedField(
            project.CommunityDBHTreesPlantedValidated
        ),
        CommunityDBHTreesDistributed: new EditedField(project.CommunityDBHTreesDistributed),
        CommunityDBHTreesDistributedValidated: new EditedField(
            project.CommunityDBHTreesDistributedValidated
        ),
        MapValidated: new EditedField(project.MapValidated),
        WasEverReadyToPublish: project.WasEverReadyToPublish,
        Completed: new EditedField(project.Completed),
        CompletedValidated: new EditedField(project.CompletedValidated),
        D365EventId: new EditedField(null),
        ControlledByAutomation: project.ControlledByAutomation,
        D365PlantingProjectId: project.D365PlantingProjectId,
        D365_eventcoordinationid: new EditedField(project.D365_eventcoordinationid),
        CarbonTotalCreditsIssued: new EditedField(project.CarbonTotalCreditsIssued),
        CarbonProjectStandard: new EditedField(project.CarbonProjectStandard),
        CarbonCreditCreditType: new EditedField(project.CarbonCreditCreditType),
        CarbonICroaEndorsedStandard: new EditedField(project.CarbonICroaEndorsedStandard),
        CarbonCorsiaEligible: new EditedField(project.CarbonCorsiaEligible),
        CarbonAdditionalDetailsLink: new EditedField(project.CarbonAdditionalDetailsLink),
        CarbonProjectRegistryLink: new EditedField(project.CarbonProjectRegistryLink),
        Participations: project.Participations ?? [],
        CarbonCreditsAdditionalCertifications:
            project.CarbonCreditsAdditionalCertifications?.map((x) => ({
                EditStatus: RecordEditStatus.Existing,
                CarbonCreditsAdditionalCertificationType: x.CarbonCreditsAdditionalCertification
            })) ?? [],
        SustainableDevelopmentGoals:
            project.SustainableDevelopmentGoals?.map((x) => ({
                EditStatus: RecordEditStatus.Existing,
                SustainableDevelopmentGoalType: x.SustainableDevelopmentGoal
            })) ?? [],
        CarbonTotalCreditsIssuedValidated: new EditedField(
            project.CarbonTotalCreditsIssuedValidated
        ),
        CarbonProjectStandardValidated: new EditedField(project.CarbonProjectStandardValidated),
        CarbonCreditCreditTypeValidated: new EditedField(project.CarbonCreditCreditTypeValidated),
        CarbonICroaEndorsedStandardValidated: new EditedField(
            project.CarbonICroaEndorsedStandardValidated
        ),
        CarbonCorsiaEligibleValidated: new EditedField(project.CarbonCorsiaEligibleValidated),
        CarbonAdditionalDetailsLinkValidated: new EditedField(
            project.CarbonAdditionalDetailsLinkValidated
        ),
        CarbonProjectRegistryLinkValidated: new EditedField(
            project.CarbonProjectRegistryLinkValidated
        ),
        SustainableDevelopmentGoalsValidated: new EditedField(
            project.SustainableDevelopmentGoalsValidated
        ),
        AdditionalCarbonCreditCertificationsValidated: new EditedField(
            project.AdditionalCarbonCreditCertificationsValidated
        ),
        HasMap: project.HasMap,
        ExtraValidations: []
    };
}

function getNewProjectDefaults() {
    return {
        EditStatus: RecordEditStatus.New,
        Id: null,
        ProjectType: new EditedField(PlantingProjectType.Reforestation),
        ProjectTypeValidated: new EditedField(true),
        ProjectStartYear: new EditedField(null),
        ProjectEndYear: new EditedField(null),
        ProjectYearsValidated: new EditedField(true),
        Name: new EditedField(null),
        NameValidated: new EditedField(true),
        Location: new EditedField(null),
        LocationValidated: new EditedField(true),
        TTFForestCode: new EditedField(null),
        TTFForestCodeValidated: new EditedField(true),
        ReasonForPlanting: new EditedField(null),
        ReasonForPlantingValidated: new EditedField(true),
        TreeSpeciesPlanted: new EditedField(null),
        TreeSpecies: [],
        TreeSpeciesValidated: new EditedField(true),
        PlantingPartnerName: new EditedField(null),
        PlantingPartnerNameValidated: new EditedField(true),
        ImpactStoryUnedited: new EditedField(null),
        ImpactStoryInProgress: new EditedField(null),
        ImpactStoryInProgressValidated: new EditedField(true),
        ImpactStoryCompleted: new EditedField(null),
        ImpactStoryCompletedValidated: new EditedField(true),
        TTFTrees: new EditedField(null),
        TTFTreesValidated: new EditedField(true),
        AcresRestored: new EditedField(null),
        AcresRestoredValidated: new EditedField(true),
        TTFTotalTreeCost: new EditedField(null),
        TTFTotalTreeCostValidated: new EditedField(true),
        TTFAgreementNumber: new EditedField(null),
        TTFAgreementNumberValidated: new EditedField(true),
        TTFCommitments: [],
        TTFCommitmentFiscalYear: new EditedField(null),
        TTFCommitmentFiscalYearValidated: new EditedField(true),
        CommunityTreesPlanted: new EditedField(null),
        CommunityTreesPlantedValidated: new EditedField(true),
        CommunityTreesDistributed: new EditedField(null),
        CommunityTreesDistributedValidated: new EditedField(true),
        CommunityNumberOfCorporateVolunteers: new EditedField(null),
        CommunityNumberOfCorporateVolunteersValidated: new EditedField(true),
        CommunityNumberOfTotalParticipants: new EditedField(null),
        CommunityNumberOfTotalParticipantsValidated: new EditedField(true),
        CommunityFirstDayOfEvent: new EditedField(null),
        CommunityFinalDayOfEvent: new EditedField(null),
        CommunityEventDatesValidated: new EditedField(true),
        TTFPlantingTimeframe: new EditedField(null),
        TTFPlantingTimeframeValidated: new EditedField(true),
        CommunitySiteName: new EditedField(null),
        CommunitySiteNameValidated: new EditedField(true),
        CommunitySiteAddress: new EditedField(null),
        CommunitySiteAddressValidated: new EditedField(true),
        CommunitySiteCity: new EditedField(null),
        CommunitySiteCityValidated: new EditedField(true),
        CommunitySiteStateCode: new EditedField(null),
        CommunitySiteStateCodeValidated: new EditedField(true),
        CommunitySitePostalCode: new EditedField(null),
        CommunitySitePostalCodeValidated: new EditedField(true),
        CommunitySiteCountryCode: new EditedField(null),
        CommunitySiteCountryCodeValidated: new EditedField(true),
        DAMTag: null,
        ImagesValidated: new EditedField(true),
        IsHoldingProject: false,
        TTFDBH: new EditedField(null),
        TTFDBHValidated: new EditedField(true),
        CommunityDBHTreesPlanted: new EditedField(null),
        CommunityDBHTreesPlantedValidated: new EditedField(true),
        CommunityDBHTreesDistributed: new EditedField(null),
        CommunityDBHTreesDistributedValidated: new EditedField(true),
        MapValidated: new EditedField(true),
        WasEverReadyToPublish: false,
        Completed: new EditedField(false),
        CompletedValidated: new EditedField(true),
        D365EventId: new EditedField(null),
        CarbonAdditionalDetailsLink: new EditedField(null),
        ControlledByAutomation: false,
        D365PlantingProjectId: null,
        D365_eventcoordinationid: new EditedField(null),

        CarbonCorsiaEligible: new EditedField(null),
        CarbonCreditCreditType: new EditedField(null),
        CarbonICroaEndorsedStandard: new EditedField(null),
        CarbonProjectRegistryLink: new EditedField(null),
        CarbonProjectStandard: new EditedField(null),
        CarbonTotalCreditsIssued: new EditedField(null),
        CarbonCreditsAdditionalCertifications: [],
        SustainableDevelopmentGoals: [],
        Participations: [],
        CarbonTotalCreditsIssuedValidated: new EditedField(true),
        CarbonProjectStandardValidated: new EditedField(true),
        CarbonCreditCreditTypeValidated: new EditedField(true),
        CarbonICroaEndorsedStandardValidated: new EditedField(true),
        CarbonCorsiaEligibleValidated: new EditedField(true),
        CarbonAdditionalDetailsLinkValidated: new EditedField(true),
        CarbonProjectRegistryLinkValidated: new EditedField(true),
        SustainableDevelopmentGoalsValidated: new EditedField(true),
        AdditionalCarbonCreditCertificationsValidated: new EditedField(true),
        HasMap: false,
        BHCampaigns: [],
        ExtraValidations: []
    };
}

function initializeNewReforestationProject() {
    const project = getNewProjectDefaults();

    project.ProjectType = project.ProjectType.update(PlantingProjectType.Reforestation);
    project.CompletedValidated = project.CompletedValidated.update(false);
    project.NameValidated = project.NameValidated.update(false);
    project.LocationValidated = project.LocationValidated.update(false);
    project.TTFForestCodeValidated = project.TTFForestCodeValidated.update(false);
    project.MapValidated = project.MapValidated.update(false);
    project.ProjectYearsValidated = project.ProjectYearsValidated.update(false);
    project.TTFPlantingTimeframeValidated = project.TTFPlantingTimeframeValidated.update(false);
    project.ReasonForPlantingValidated = project.ReasonForPlantingValidated.update(false);
    project.ImpactStoryInProgressValidated = project.ImpactStoryInProgressValidated.update(false);
    project.ImpactStoryCompletedValidated = project.ImpactStoryCompletedValidated.update(false);
    project.PlantingPartnerNameValidated = project.PlantingPartnerNameValidated.update(false);
    project.TTFCommitmentFiscalYearValidated =
        project.TTFCommitmentFiscalYearValidated.update(false);
    project.TTFAgreementNumberValidated = project.TTFAgreementNumberValidated.update(false);
    project.TTFTotalTreeCostValidated = project.TTFTotalTreeCostValidated.update(false);
    project.TTFTreesValidated = project.TTFTreesValidated.update(false);
    project.TTFDBH = project.TTFDBH.update(0.25);
    project.TTFDBHValidated = project.TTFDBHValidated.update(false);
    project.AcresRestoredValidated = project.AcresRestoredValidated.update(false);
    project.TreeSpeciesValidated = project.TreeSpeciesValidated.update(false);
    project.ImagesValidated = project.ImagesValidated.update(false);

    return project;
}

function initializeNewCommunityEventProject() {
    const project = getNewProjectDefaults();

    project.ProjectType = project.ProjectType.update(PlantingProjectType.CommunityEvent);
    project.CompletedValidated = project.CompletedValidated.update(false);
    project.NameValidated = project.NameValidated.update(false);
    project.LocationValidated = project.LocationValidated.update(false);
    project.CommunitySiteNameValidated = project.CommunitySiteNameValidated.update(false);
    project.CommunitySiteAddressValidated = project.CommunitySiteAddressValidated.update(false);
    project.CommunitySiteCityValidated = project.CommunitySiteCityValidated.update(false);
    project.CommunitySiteStateCodeValidated = project.CommunitySiteStateCodeValidated.update(false);
    project.CommunitySitePostalCodeValidated =
        project.CommunitySitePostalCodeValidated.update(false);
    project.CommunitySiteCountryCodeValidated =
        project.CommunitySiteCountryCodeValidated.update(false);
    project.MapValidated = project.MapValidated.update(false);
    project.CommunityEventDatesValidated = project.CommunityEventDatesValidated.update(false);
    project.ReasonForPlantingValidated = project.ReasonForPlantingValidated.update(false);
    project.ImpactStoryInProgressValidated = project.ImpactStoryInProgressValidated.update(false);
    project.ImpactStoryCompletedValidated = project.ImpactStoryCompletedValidated.update(false);
    project.PlantingPartnerNameValidated = project.PlantingPartnerNameValidated.update(false);
    project.CommunityNumberOfCorporateVolunteersValidated =
        project.CommunityNumberOfCorporateVolunteersValidated.update(false);
    project.CommunityNumberOfTotalParticipantsValidated =
        project.CommunityNumberOfTotalParticipantsValidated.update(false);
    project.CommunityTreesPlantedValidated.update(false);
    project.CommunityTreesDistributedValidated =
        project.CommunityTreesDistributedValidated.update(false);
    project.CommunityDBHTreesPlantedValidated =
        project.CommunityDBHTreesPlantedValidated.update(false);
    project.CommunityDBHTreesDistributedValidated =
        project.CommunityDBHTreesDistributedValidated.update(false);
    project.CommunityTreesPlantedValidated = project.CommunityTreesPlantedValidated.update(false);
    project.TreeSpeciesValidated = project.TreeSpeciesValidated.update(false);
    project.ImagesValidated = project.ImagesValidated.update(false);

    return project;
}

function initializeNewCarbonCreditProject() {
    const project = getNewProjectDefaults();

    project.ProjectType = project.ProjectType.update(PlantingProjectType.Carbon);
    project.CompletedValidated = project.CompletedValidated.update(true);
    project.Completed = project.Completed.update(true);
    project.NameValidated = project.NameValidated.update(false);
    project.LocationValidated = project.LocationValidated.update(false);
    project.MapValidated = project.MapValidated.update(false);
    project.ProjectYearsValidated = project.ProjectYearsValidated.update(false);
    project.SustainableDevelopmentGoalsValidated =
        project.SustainableDevelopmentGoalsValidated.update(false);
    project.ImpactStoryCompletedValidated = project.ImpactStoryCompletedValidated.update(false);
    project.PlantingPartnerNameValidated = project.PlantingPartnerNameValidated.update(false);
    project.AcresRestoredValidated = project.AcresRestoredValidated.update(false);
    project.CarbonTotalCreditsIssuedValidated =
        project.CarbonTotalCreditsIssuedValidated.update(false);
    project.CarbonProjectStandardValidated = project.CarbonProjectStandardValidated.update(false);
    project.CarbonCreditCreditTypeValidated = project.CarbonCreditCreditTypeValidated.update(false);
    project.CarbonICroaEndorsedStandardValidated =
        project.CarbonICroaEndorsedStandardValidated.update(false);
    project.CarbonCorsiaEligibleValidated = project.CarbonCorsiaEligibleValidated.update(false);
    project.CarbonAdditionalDetailsLinkValidated =
        project.CarbonAdditionalDetailsLinkValidated.update(false);
    project.CarbonProjectRegistryLinkValidated =
        project.CarbonProjectRegistryLinkValidated.update(false);
    project.SustainableDevelopmentGoalsValidated =
        project.SustainableDevelopmentGoalsValidated.update(false);
    project.AdditionalCarbonCreditCertificationsValidated =
        project.AdditionalCarbonCreditCertificationsValidated.update(false);
    project.ImagesValidated = project.ImagesValidated.update(false);

    return project;
}

function initializeNewCommunityCanopyProject() {
    const project = getNewProjectDefaults();

    project.ProjectType = project.ProjectType.update(PlantingProjectType.CommunityCanopy);
    project.Completed = project.Completed.update(true);
    project.Name = project.Name.update('Community Canopy');
    project.ReasonForPlanting = project.ReasonForPlanting.update('Community Trees');
    project.ImpactStoryCompleted = project.ImpactStoryCompleted.update(
        'Community Canopy projects engage your employees, customers, or entire community in tree planting. This program provides your audience with trees and education, ensuring they plant the right tree in the right place. Together, your participants will help create happier, healthier communities while growing urban canopy to improve both air and water quality.'
    );

    return project;
}

export function initializeNewProject(projectType: PlantingProjectType): EditedProject {
    switch (projectType) {
        case PlantingProjectType.Reforestation:
            return initializeNewReforestationProject();
        case PlantingProjectType.CommunityEvent:
            return initializeNewCommunityEventProject();
        case PlantingProjectType.Carbon:
            return initializeNewCarbonCreditProject();
        case PlantingProjectType.CommunityCanopy:
            return initializeNewCommunityCanopyProject();
    }
}

export function hasChanges(project: EditedProject) {
    if (!project) return false;

    if (
        project.ProjectType.modified ||
        project.ProjectTypeValidated.modified ||
        project.ProjectStartYear.modified ||
        project.ProjectEndYear.modified ||
        project.ProjectYearsValidated.modified ||
        project.Name.modified ||
        project.NameValidated.modified ||
        project.Location.modified ||
        project.LocationValidated.modified ||
        project.TTFForestCode.modified ||
        project.TTFForestCodeValidated.modified ||
        project.ReasonForPlanting.modified ||
        project.ReasonForPlantingValidated.modified ||
        project.TreeSpeciesPlanted.modified ||
        project.TreeSpeciesValidated.modified ||
        project.PlantingPartnerName.modified ||
        project.PlantingPartnerNameValidated.modified ||
        project.MapValidated.modified ||
        project.ImpactStoryUnedited.modified ||
        project.ImpactStoryInProgress.modified ||
        project.ImpactStoryInProgressValidated.modified ||
        project.ImpactStoryCompleted.modified ||
        project.ImpactStoryCompletedValidated.modified ||
        project.TTFTrees.modified ||
        project.TTFTreesValidated.modified ||
        project.AcresRestored.modified ||
        project.AcresRestoredValidated.modified ||
        project.TTFTotalTreeCost.modified ||
        project.TTFTotalTreeCostValidated.modified ||
        project.TTFAgreementNumber.modified ||
        project.TTFAgreementNumberValidated.modified ||
        project.TTFCommitmentFiscalYear.modified ||
        project.TTFCommitmentFiscalYearValidated.modified ||
        project.CommunityTreesPlanted.modified ||
        project.CommunityTreesPlantedValidated.modified ||
        project.CommunityTreesDistributed.modified ||
        project.CommunityTreesDistributedValidated.modified ||
        project.CommunityNumberOfCorporateVolunteers.modified ||
        project.CommunityNumberOfCorporateVolunteersValidated.modified ||
        project.CommunityNumberOfTotalParticipants.modified ||
        project.CommunityNumberOfTotalParticipantsValidated.modified ||
        project.CommunityFirstDayOfEvent.modified ||
        project.CommunityFinalDayOfEvent.modified ||
        project.CommunityEventDatesValidated.modified ||
        project.TTFPlantingTimeframe.modified ||
        project.TTFPlantingTimeframeValidated.modified ||
        project.CommunitySiteName.modified ||
        project.CommunitySiteNameValidated.modified ||
        project.CommunitySiteAddress.modified ||
        project.CommunitySiteAddressValidated.modified ||
        project.CommunitySiteCity.modified ||
        project.CommunitySiteCityValidated.modified ||
        project.CommunitySiteStateCode.modified ||
        project.CommunitySiteStateCodeValidated.modified ||
        project.CommunitySitePostalCode.modified ||
        project.CommunitySitePostalCodeValidated.modified ||
        project.CommunitySiteCountryCode.modified ||
        project.CommunitySiteCountryCodeValidated.modified ||
        project.TTFDBH.modified ||
        project.TTFDBHValidated.modified ||
        project.CommunityDBHTreesPlanted.modified ||
        project.CommunityDBHTreesPlantedValidated.modified ||
        project.CommunityDBHTreesDistributed.modified ||
        project.CommunityDBHTreesDistributedValidated.modified ||
        project.ImagesValidated.modified ||
        project.Completed.modified ||
        project.CompletedValidated.modified ||
        project.CarbonTotalCreditsIssued.modified ||
        project.CarbonProjectStandard.modified ||
        project.CarbonCreditCreditType.modified ||
        project.CarbonICroaEndorsedStandard.modified ||
        project.CarbonCorsiaEligible.modified ||
        project.CarbonAdditionalDetailsLink.modified ||
        project.CarbonProjectRegistryLink.modified ||
        project.CarbonTotalCreditsIssuedValidated.modified ||
        project.CarbonProjectStandardValidated.modified ||
        project.CarbonCreditCreditTypeValidated.modified ||
        project.CarbonICroaEndorsedStandardValidated.modified ||
        project.CarbonCorsiaEligibleValidated.modified ||
        project.CarbonAdditionalDetailsLinkValidated.modified ||
        project.CarbonProjectRegistryLinkValidated.modified ||
        project.SustainableDevelopmentGoalsValidated.modified ||
        project.AdditionalCarbonCreditCertificationsValidated.modified ||
        project.D365_eventcoordinationid.modified
    )
        return true;

    for (const ts of project.TreeSpecies) {
        switch (ts.EditStatus) {
            case RecordEditStatus.New:
            case RecordEditStatus.Deleted:
                return true;
            case RecordEditStatus.Existing:
                if (ts.OtherName.modified) return true;
                break;
        }
    }

    for (const commitment of project.TTFCommitments) {
        switch (commitment.EditStatus) {
            case RecordEditStatus.New:
            case RecordEditStatus.Deleted:
                return true;
            case RecordEditStatus.Existing:
                break;
        }
    }

    for (const campaign of project.BHCampaigns) {
        switch (campaign.EditStatus) {
            case RecordEditStatus.New:
            case RecordEditStatus.Deleted:
                return true;
            case RecordEditStatus.Existing:
                break;
        }
    }

    for (const sdg of project.SustainableDevelopmentGoals) {
        switch (sdg.EditStatus) {
            case RecordEditStatus.New:
            case RecordEditStatus.Deleted: {
                return true;
            }
            default:
                continue;
        }
    }

    for (const sdg of project.CarbonCreditsAdditionalCertifications) {
        switch (sdg.EditStatus) {
            case RecordEditStatus.New:
            case RecordEditStatus.Deleted: {
                return true;
            }
            default:
                continue;
        }
    }

    return false;
}

export function getNotValidToSave(project: EditedProject) {
    if (!project) return false;

    if (project.Completed.notValidToSave) return true;
    if (project.ProjectType.notValidToSave) return true;
    if (project.Name.notValidToSave) return true;
    if (project.Location.notValidToSave) return true;
    if (project.TTFForestCode.notValidToSave) return true;
    if (project.CommunitySiteName.notValidToSave) return true;
    if (project.CommunitySiteAddress.notValidToSave) return true;
    if (project.CommunitySiteCity.notValidToSave) return true;
    if (project.CommunitySiteStateCode.notValidToSave) return true;
    if (project.CommunitySitePostalCode.notValidToSave) return true;
    if (project.CommunitySiteCountryCode.notValidToSave) return true;
    if (project.ProjectStartYear.notValidToSave) return true;
    if (project.ProjectEndYear.notValidToSave) return true;
    if (project.CommunityFirstDayOfEvent.notValidToSave) return true;
    if (project.CommunityFinalDayOfEvent.notValidToSave) return true;
    if (project.TTFPlantingTimeframe.notValidToSave) return true;
    if (project.ReasonForPlanting.notValidToSave) return true;
    if (project.ImpactStoryInProgress.notValidToSave) return true;
    if (project.ImpactStoryCompleted.notValidToSave) return true;
    if (project.PlantingPartnerName.notValidToSave) return true;
    if (project.CommunityNumberOfCorporateVolunteers.notValidToSave) return true;
    if (project.CommunityNumberOfTotalParticipants.notValidToSave) return true;
    if (project.TTFTrees.notValidToSave) return true;
    if (project.CommunityTreesPlanted.notValidToSave) return true;
    if (project.CommunityTreesDistributed.notValidToSave) return true;
    if (project.AcresRestored.notValidToSave) return true;
    if (project.TreeSpeciesPlanted.notValidToSave) return true;
    if (project.TTFDBH.notValidToSave) return true;
    if (project.CommunityDBHTreesPlanted.notValidToSave) return true;
    if (project.CommunityDBHTreesDistributed.notValidToSave) return true;
    if (project.D365EventId.notValidToSave) return true;
    if (project.TTFCommitmentFiscalYear.notValidToSave) return true;
    if (project.TTFAgreementNumber.notValidToSave) return true;
    if (project.TTFTotalTreeCost.notValidToSave) return true;
    if (project.CarbonTotalCreditsIssued.notValidToSave) return true;
    if (project.CarbonAdditionalDetailsLink.notValidToSave) return true;
    if (project.CarbonProjectRegistryLink.notValidToSave) return true;
    if (project.D365_eventcoordinationid.notValidToSave) return true;
    if (project.ExtraValidations.findIndex((v) => v.level === ValidationLevel.NotValidToSave) >= 0)
        return true;

    return false;
}

function updateCompletedReforestationProject(project: EditedReforestationProject) {
    project.TTFPlantingTimeframeValidated = project.TTFPlantingTimeframeValidated.update(false);
    project.ProjectYearsValidated = project.ProjectYearsValidated.update(false);
    project.AcresRestoredValidated = project.AcresRestoredValidated.update(false);
    project.TTFTreesValidated = project.TTFTreesValidated.update(false);
    project.TreeSpeciesValidated = project.TreeSpeciesValidated.update(false);
    project.ImagesValidated = project.ImagesValidated.update(false);
    project.MapValidated = project.MapValidated.update(false);
}

function updateCompletedCommunityProject(project: EditedCommunityProject) {
    project.CommunityNumberOfCorporateVolunteersValidated =
        project.CommunityNumberOfCorporateVolunteersValidated.update(false);
    project.CommunityNumberOfTotalParticipantsValidated =
        project.CommunityNumberOfTotalParticipantsValidated.update(false);
    project.CommunityTreesPlantedValidated = project.CommunityTreesPlantedValidated.update(false);
    project.CommunityTreesDistributedValidated =
        project.CommunityTreesDistributedValidated.update(false);
    project.CommunityDBHTreesPlantedValidated =
        project.CommunityDBHTreesPlantedValidated.update(false);
    project.CommunityDBHTreesDistributedValidated =
        project.CommunityDBHTreesDistributedValidated.update(false);
    project.ImpactStoryCompletedValidated = project.ImpactStoryCompletedValidated.update(false);
    project.TreeSpeciesValidated = project.TreeSpeciesValidated.update(false);
    project.ImagesValidated = project.ImagesValidated.update(false);
    project.MapValidated = project.MapValidated.update(false);
}

function updateCompletedCarbonProject(project: EditedCarbonProject) {
    project.ProjectYearsValidated = project.ProjectYearsValidated.update(false);
    project.ImagesValidated = project.ImagesValidated.update(false);
    project.MapValidated = project.MapValidated.update(false);
}

export function updateCompletedProject(project: EditedProject) {
    if (project.Completed.original === true || project.Completed.current === false) return;

    switch (project.ProjectType.current) {
        case PlantingProjectType.Reforestation:
            updateCompletedReforestationProject(project);
            break;
        case PlantingProjectType.CommunityEvent:
            updateCompletedCommunityProject(project);
            break;
        case PlantingProjectType.Carbon:
            updateCompletedCarbonProject(project);
            break;
        case PlantingProjectType.CommunityCanopy:
            break;
    }
}

export const approvedReasonsForPlanting = [
    'Community Trees',
    'Disaster Recovery',
    'Disease or Insect Damage',
    'Employee Engagement',
    'Environmental Equity',
    'Food Security',
    'Habitat and Biodiversity',
    'Livelihood Program',
    'Mine Reclamation',
    'Storm Damage',
    'Sustainable Forestry',
    'Watershed Restoration',
    'Wildfire Recovery'
];

function d365EventIdFormat(d365EventId: EditedField<number>) {
    if (
        d365EventId.current != null &&
        (!Number.isInteger(d365EventId.current) ||
            d365EventId.current < 0 ||
            d365EventId.current > 999999)
    )
        d365EventId.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'The format is invalid for an event number'
        });
}

function carbonAdditionalDetailsLinkFormat(carbonAdditionalDetailsLink: EditedField<string>) {
    if (!absoluteLinkIsValid(carbonAdditionalDetailsLink.current)) {
        carbonAdditionalDetailsLink.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'All urls must begin with https:// or http://'
        });
    }
}

function carbonProjectRegistryLinkFormat(carbonProjectRegistryLink: EditedField<string>) {
    if (!absoluteLinkIsValid(carbonProjectRegistryLink.current)) {
        carbonProjectRegistryLink.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'All urls must begin with https:// or http://'
        });
    }
}

function carbonTotalCreditsIssuedNonNegative(carbonTotalCreditsIssued: EditedField<number>) {
    if (carbonTotalCreditsIssued.current !== null && carbonTotalCreditsIssued.current < 0)
        carbonTotalCreditsIssued.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Total Carbon Credits cannot be negative'
        });
}

function communityDBHTreesDistributedNonNegative(
    communityDBHTreesDistributed: EditedField<number>
) {
    if (communityDBHTreesDistributed.current !== null && communityDBHTreesDistributed.current < 0)
        communityDBHTreesDistributed.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Tree diameter - distributed can't be negative"
        });
}

function communityDBHTreesDistributedRequired(communityDBHTreesDistributed: EditedField<number>) {
    if (!communityDBHTreesDistributed.current)
        communityDBHTreesDistributed.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Tree diameter - distributed is required to show this project to the partner'
        });
}

function communityDBHTreesPlantedNonNegative(communityDBHTreesPlanted: EditedField<number>) {
    if (communityDBHTreesPlanted.current !== null && communityDBHTreesPlanted.current < 0)
        communityDBHTreesPlanted.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Tree diameter - planted can't be negative"
        });
}

function communityDBHTreesPlantedRequired(communityDBHTreesPlanted: EditedField<number>) {
    if (!communityDBHTreesPlanted.current)
        communityDBHTreesPlanted.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Tree diameter - planted is required to show this project to the partner'
        });
}

function ttfDBHNonNegative(ttfDBH: EditedField<number>) {
    if (ttfDBH.current !== null && ttfDBH.current < 0)
        ttfDBH.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Tree diameter can't be negative"
        });
}

function ttfDBHRequired(ttfDBH: EditedField<number>) {
    if (!ttfDBH.current)
        ttfDBH.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Tree diameter is required to show this project to the partner'
        });
}

function treeSpeciesOtherNameRequired(
    ts: EditedPlantingProjectTreeSpecies,
    treeSpeciesPlanted: EditedField<string>
) {
    if (ts.Species.Code === 'OTHER' && ts.OtherName.current == null) {
        const error = {
            level: ValidationLevel.NotValidToSave,
            message: 'The name of the other tree species is required'
        };
        ts.OtherName.validations.push(error);
        treeSpeciesPlanted.validations.push(error);
    }
}

function duplicateTreeSpecies(
    treeSpeciesPlanted: EditedField<string>,
    treeSpecies: EditedPlantingProjectTreeSpecies[],
    ts: EditedPlantingProjectTreeSpecies
) {
    if (
        treeSpecies.findIndex(
            (ts2) =>
                ts2.Id !== ts.Id &&
                ts2.Id < ts.Id &&
                (ts2.EditStatus === RecordEditStatus.New ||
                    ts2.EditStatus === RecordEditStatus.Existing) &&
                ts2.Species.Code === ts.Species.Code &&
                ts2.OtherName.current === ts.OtherName.current
        ) !== -1
    )
        treeSpeciesPlanted.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: `${ts.OtherName.current ?? ts.Species.CommonName} is listed more than once`
        });
}

function validateTreeSpecies(
    treeSpecies: EditedPlantingProjectTreeSpecies[],
    treeSpeciesPlanted: EditedField<string>
) {
    for (const ts of treeSpecies) {
        ts.OtherName.validations = [];
        if (ts.EditStatus === RecordEditStatus.New || ts.EditStatus === RecordEditStatus.Existing) {
            treeSpeciesOtherNameRequired(ts, treeSpeciesPlanted);
            duplicateTreeSpecies(treeSpeciesPlanted, treeSpecies, ts);
        }
    }
}

function mapRequired(mapValidated: EditedField<boolean>, hasMap: boolean) {
    if (!hasMap) {
        mapValidated.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message:
                'No map was found for this project. You must add a map, save the project and then refresh the page before you can click the Map Validated checkbox.'
        });
    }
}

function acresRestoredNonNegative(acresRestored: EditedField<number>) {
    if (acresRestored.current < 0)
        acresRestored.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Acres restored can't be negative"
        });
}

function communityTreesDistributedWholeNumber(communityTreesDistributed: EditedField<number>) {
    if (decimalPlaces(communityTreesDistributed.current) > 0)
        communityTreesDistributed.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Trees distributed must be a whole number'
        });
}

function communityTreesDistributedNonNegative(communityTreesDistributed: EditedField<number>) {
    if (communityTreesDistributed.current < 0)
        communityTreesDistributed.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Trees distributed can't be negative"
        });
}

function communityTreesDistributedRequired(communityTreesDistributed: EditedField<number>) {
    if (communityTreesDistributed.current === null)
        communityTreesDistributed.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Trees distributed is required to show to the partner'
        });
}

function communityTreesPlantedWholeNumber(communityTreesPlanted: EditedField<number>) {
    if (decimalPlaces(communityTreesPlanted.current) > 0)
        communityTreesPlanted.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Trees planted must be a whole number'
        });
}

function communityTreesPlantedNonNegative(communityTreesPlanted: EditedField<number>) {
    if (communityTreesPlanted.current < 0)
        communityTreesPlanted.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Trees planted can't be negative"
        });
}

function communityTreesPlantedRequired(communityTreesPlanted: EditedField<number>) {
    if (communityTreesPlanted.current === null)
        communityTreesPlanted.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Trees planted is required to show to the partner'
        });
}

function ttfTreesWholeNumber(ttfTrees: EditedField<number>) {
    if (decimalPlaces(ttfTrees.current) > 0)
        ttfTrees.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Trees planted must be a whole number'
        });
}

function ttfTreesNonNegative(ttfTrees: EditedField<number>) {
    if (ttfTrees.current < 0)
        ttfTrees.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Trees planted can't be negative"
        });
}

function communityNumberOfTotalParticipantsWholeNumber(
    communityNumberOfTotalParticipants: EditedField<number>
) {
    if (decimalPlaces(communityNumberOfTotalParticipants.current) > 0)
        communityNumberOfTotalParticipants.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Total number of participants must be a whole number'
        });
}

function communityNumberOfTotalParticipantsNonNegative(
    communityNumberOfTotalParticipants: EditedField<number>
) {
    if (communityNumberOfTotalParticipants.current < 0)
        communityNumberOfTotalParticipants.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Total number of participants can't be negative"
        });
}

function communityNumberOfCorporateVolunteersWholeNumber(
    communityNumberOfCorporateVolunteers: EditedField<number>
) {
    if (decimalPlaces(communityNumberOfCorporateVolunteers.current) > 0)
        communityNumberOfCorporateVolunteers.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Number of corporate volunteers must be a whole number'
        });
}

function communityNumberOfCorporateVolunteersNonNegative(
    communityNumberOfCorporateVolunteers: EditedField<number>
) {
    if (communityNumberOfCorporateVolunteers.current < 0)
        communityNumberOfCorporateVolunteers.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Number of corporate volunteers can't be negative"
        });
}

function ttfTotalTreeCostNonNegative(ttfTotalTreeCost: EditedField<number>) {
    if (ttfTotalTreeCost.current !== null) {
        if (ttfTotalTreeCost.current < 0)
            ttfTotalTreeCost.validations.push({
                level: ValidationLevel.NotValidToSave,
                message: "The cost can't be negative"
            });
    }
}

function impactStoryInProgressRequired(
    impactStoryInProgress: EditedField<string>,
    completed: EditedField<boolean>
) {
    if (!completed.current) {
        if (!impactStoryInProgress.current)
            impactStoryInProgress.validations.push({
                level: ValidationLevel.NotValidToShowPartner,
                message:
                    'Impact story - in progress is required to show this project to the partner'
            });
    }
}

function impactStoryCompletedRequired(
    impactStoryCompleted: EditedField<string>,
    completed: EditedField<boolean>
) {
    if (!completed.current) return;
    if (!impactStoryCompleted.current)
        impactStoryCompleted.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Impact story - completed is required to show this project to the partner'
        });
}

function impactStoryCompletedRequiredToSave(
    impactStoryCompleted: EditedField<string>,
    completed: EditedField<boolean>
) {
    if (!completed.current) return;
    if (!impactStoryCompleted.current)
        impactStoryCompleted.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Impact story - completed is required'
        });
}

function reasonForPlantingInApprovedList(reasonForPlanting: EditedField<string>) {
    if (
        reasonForPlanting.current &&
        !approvedReasonsForPlanting.includes(reasonForPlanting.current)
    )
        reasonForPlanting.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Reason for planting is not in the approved list of reasons'
        });
}

function reasonForPlantingRequired(reasonForPlanting: EditedField<string>) {
    if (!reasonForPlanting.current)
        reasonForPlanting.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Reason for planting is required to show this project to the partner'
        });
}

function ttfCommitmentFiscalYearFormat(ttfCommitmentFiscalYear: EditedField<string>) {
    if (
        ttfCommitmentFiscalYear.current !== null &&
        !/^FY\d{2}\/\d{2}$/.test(ttfCommitmentFiscalYear.current)
    )
        ttfCommitmentFiscalYear.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'The format must be FY##/##'
        });
}

function communityFirstOrFinalDayRequired(
    communityFirstDayOfEvent: EditedField<string>,
    communityFinalDayOfEvent: EditedField<string>
) {
    if (communityFirstDayOfEvent.current === null && communityFinalDayOfEvent.current === null)
        communityFinalDayOfEvent.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Either the first or final day is required to save changes'
        });
}

function communityFirstDayAfterFinalDay(
    communityFirstDayOfEvent: EditedField<string>,
    communityFinalDayOfEvent: EditedField<string>
) {
    if (communityFinalDayOfEvent.current < communityFirstDayOfEvent.current)
        communityFinalDayOfEvent.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "The final day can't be before the first day"
        });
}

function projectStartOrEndYearRequired(
    projectStartYear: EditedField<number>,
    projectEndYear: EditedField<number>
) {
    if (projectStartYear.current === null && projectEndYear.current === null)
        projectEndYear.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Either the project start or end year is required to save changes'
        });
}

function projectStartYearAfterEndYear(
    projectStartYear: EditedField<number>,
    projectEndYear: EditedField<number>
) {
    if (
        projectEndYear.current !== null &&
        projectStartYear.current !== null &&
        projectEndYear.current < projectStartYear.current
    )
        projectEndYear.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: "Project end year can't be before the start year"
        });
}

function projectEndYearWholeNumber(projectEndYear: EditedField<number>) {
    if (decimalPlaces(projectEndYear.current) > 0)
        projectEndYear.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Project end year must be a whole number'
        });
}

function projectEndYearMinimum(projectEndYear: EditedField<number>) {
    if (projectEndYear.current !== null && projectEndYear.current < 2000)
        projectEndYear.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Project end year must be 2000 or after'
        });
}

function projectStartYearWholeNumber(projectStartYear: EditedField<number>) {
    if (decimalPlaces(projectStartYear.current) > 0)
        projectStartYear.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Project start year must be a whole number'
        });
}

function projectStartYearMinimum(projectStartYear: EditedField<number>) {
    if (projectStartYear.current !== null && projectStartYear.current < 2000)
        projectStartYear.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Project start year must be 2000 or after'
        });
}

function locationRequired(location: EditedField<string>) {
    if (!location.current)
        location.validations.push({
            level: ValidationLevel.NotValidToShowPartner,
            message: 'Location is required to show this project to the partner'
        });
}

function nameRequired(name: EditedField<string>) {
    if (!name.current)
        name.validations.push({
            level: ValidationLevel.NotValidToSave,
            message: 'Name is required to save changes'
        });
}

function beehiveCampaignRequired(campaigns: EditedPlantingProjectBHCampaign[]): Validation[] {
    if (
        !campaigns ||
        campaigns.findIndex(
            (c) =>
                c.EditStatus === RecordEditStatus.New || c.EditStatus === RecordEditStatus.Existing
        ) < 0
    )
        return [
            {
                level: ValidationLevel.NotValidToSave,
                message: 'At least one campaign is required'
            }
        ];

    return [];
}

function setReforestationValidations(project: EditedReforestationProject) {
    nameRequired(project.Name);
    locationRequired(project.Location);
    projectStartYearMinimum(project.ProjectStartYear);
    projectStartYearWholeNumber(project.ProjectStartYear);
    projectEndYearMinimum(project.ProjectEndYear);
    projectEndYearWholeNumber(project.ProjectEndYear);
    projectStartYearAfterEndYear(project.ProjectStartYear, project.ProjectEndYear);
    projectStartOrEndYearRequired(project.ProjectStartYear, project.ProjectEndYear);
    ttfCommitmentFiscalYearFormat(project.TTFCommitmentFiscalYear);
    reasonForPlantingRequired(project.ReasonForPlanting);
    reasonForPlantingInApprovedList(project.ReasonForPlanting);
    impactStoryCompletedRequired(project.ImpactStoryCompleted, project.Completed);
    impactStoryInProgressRequired(project.ImpactStoryInProgress, project.Completed);
    ttfTotalTreeCostNonNegative(project.TTFTotalTreeCost);
    ttfTreesNonNegative(project.TTFTrees);
    ttfTreesWholeNumber(project.TTFTrees);
    acresRestoredNonNegative(project.AcresRestored);
    mapRequired(project.MapValidated, project.HasMap);
    validateTreeSpecies(project.TreeSpecies, project.TreeSpeciesPlanted);
    ttfDBHRequired(project.TTFDBH);
    ttfDBHNonNegative(project.TTFDBH);
}

function setCommunityValidations(project: EditedCommunityProject) {
    nameRequired(project.Name);
    locationRequired(project.Location);
    communityFirstDayAfterFinalDay(
        project.CommunityFirstDayOfEvent,
        project.CommunityFinalDayOfEvent
    );
    communityFirstOrFinalDayRequired(
        project.CommunityFirstDayOfEvent,
        project.CommunityFinalDayOfEvent
    );
    reasonForPlantingRequired(project.ReasonForPlanting);
    reasonForPlantingInApprovedList(project.ReasonForPlanting);
    impactStoryCompletedRequired(project.ImpactStoryCompleted, project.Completed);
    impactStoryInProgressRequired(project.ImpactStoryInProgress, project.Completed);
    communityNumberOfCorporateVolunteersNonNegative(project.CommunityNumberOfCorporateVolunteers);
    communityNumberOfCorporateVolunteersWholeNumber(project.CommunityNumberOfCorporateVolunteers);
    communityNumberOfTotalParticipantsNonNegative(project.CommunityNumberOfTotalParticipants);
    communityNumberOfTotalParticipantsWholeNumber(project.CommunityNumberOfTotalParticipants);
    communityTreesPlantedRequired(project.CommunityTreesPlanted);
    communityTreesPlantedNonNegative(project.CommunityTreesPlanted);
    communityTreesPlantedWholeNumber(project.CommunityTreesPlanted);
    communityTreesDistributedRequired(project.CommunityTreesDistributed);
    communityTreesDistributedNonNegative(project.CommunityTreesDistributed);
    communityTreesDistributedWholeNumber(project.CommunityTreesDistributed);
    mapRequired(project.MapValidated, project.HasMap);
    validateTreeSpecies(project.TreeSpecies, project.TreeSpeciesPlanted);
    communityDBHTreesPlantedRequired(project.CommunityDBHTreesPlanted);
    communityDBHTreesPlantedNonNegative(project.CommunityDBHTreesPlanted);
    communityDBHTreesDistributedRequired(project.CommunityDBHTreesDistributed);
    communityDBHTreesDistributedNonNegative(project.CommunityDBHTreesDistributed);
    if (project.EditStatus === RecordEditStatus.New) d365EventIdFormat(project.D365EventId);
}

function setCarbonValidations(project: EditedCarbonProject) {
    nameRequired(project.Name);
    locationRequired(project.Location);
    projectStartYearMinimum(project.ProjectStartYear);
    projectStartYearWholeNumber(project.ProjectStartYear);
    projectEndYearMinimum(project.ProjectEndYear);
    projectEndYearWholeNumber(project.ProjectEndYear);
    projectStartYearAfterEndYear(project.ProjectStartYear, project.ProjectEndYear);
    impactStoryCompletedRequired(project.ImpactStoryCompleted, project.Completed);
    acresRestoredNonNegative(project.AcresRestored);
    mapRequired(project.MapValidated, project.HasMap);
    carbonTotalCreditsIssuedNonNegative(project.CarbonTotalCreditsIssued);
    carbonProjectRegistryLinkFormat(project.CarbonProjectRegistryLink);
    carbonAdditionalDetailsLinkFormat(project.CarbonAdditionalDetailsLink);
}

function setCommunityCanopyValidations(project: EditedCommunityCanopyProject) {
    nameRequired(project.Name);
    project.ExtraValidations = [
        ...project.ExtraValidations,
        ...beehiveCampaignRequired(project.BHCampaigns)
    ];
    reasonForPlantingRequired(project.ReasonForPlanting);
    reasonForPlantingInApprovedList(project.ReasonForPlanting);
    impactStoryCompletedRequiredToSave(project.ImpactStoryCompleted, project.Completed);
}

function clearValidations(project: EditedProject) {
    project.ExtraValidations = [];

    project.ProjectType.validations = [];
    project.Completed.validations = [];
    project.Name.validations = [];
    project.Location.validations = [];
    project.TTFForestCode.validations = [];
    project.PlantingPartnerName.validations = [];
    project.ProjectStartYear.validations = [];
    project.ProjectEndYear.validations = [];
    project.CommunityFirstDayOfEvent.validations = [];
    project.CommunityFinalDayOfEvent.validations = [];
    project.TTFCommitmentFiscalYear.validations = [];
    project.ImpactStoryCompleted.validations = [];
    project.ImpactStoryInProgress.validations = [];
    project.CommunityNumberOfCorporateVolunteers.validations = [];
    project.CommunityNumberOfTotalParticipants.validations = [];
    project.TTFTrees.validations = [];
    project.CommunityTreesPlanted.validations = [];
    project.CommunityTreesDistributed.validations = [];
    project.AcresRestored.validations = [];
    project.MapValidated.validations = [];
    project.TreeSpeciesPlanted.validations = [];
    project.TTFDBH.validations = [];
    project.CommunityDBHTreesPlanted.validations = [];
    project.CommunityDBHTreesDistributed.validations = [];
    project.D365EventId.validations = [];
    project.ReasonForPlanting.validations = [];
    project.TTFPlantingTimeframe.validations = [];
    project.TTFTotalTreeCost.validations = [];
    project.TTFAgreementNumber.validations = [];
    project.CommunityTreesPlanted.validations = [];
    project.CommunityTreesDistributed.validations = [];
    project.CommunitySiteName.validations = [];
    project.CommunitySiteAddress.validations = [];
    project.CommunitySiteCity.validations = [];
    project.CommunitySiteStateCode.validations = [];
    project.CommunitySitePostalCode.validations = [];
    project.CommunitySiteCountryCode.validations = [];
    project.CarbonTotalCreditsIssued.validations = [];
    project.CarbonProjectStandard.validations = [];
    project.CarbonCreditCreditType.validations = [];
    project.CarbonICroaEndorsedStandard.validations = [];
    project.CarbonCorsiaEligible.validations = [];
    project.CarbonAdditionalDetailsLink.validations = [];
    project.CarbonProjectRegistryLink.validations = [];

    for (const ts of project.TreeSpecies) {
        ts.OtherName.validations = [];
    }
}

function clearReforestationValidatedFlagsIfInvalid(project: EditedReforestationProject) {
    if (project.ProjectType.notValidToShowPartner)
        project.ProjectTypeValidated = project.ProjectTypeValidated.update(false);
    if (project.Name.notValidToShowPartner)
        project.NameValidated = project.NameValidated.update(false);
    if (project.Location.notValidToShowPartner)
        project.LocationValidated = project.LocationValidated.update(false);
    if (project.TTFForestCode.notValidToShowPartner)
        project.TTFForestCodeValidated = project.TTFForestCodeValidated.update(false);
    if (project.ProjectStartYear.notValidToShowPartner)
        project.ProjectYearsValidated = project.ProjectYearsValidated.update(false);
    if (project.ProjectEndYear.notValidToShowPartner)
        project.ProjectYearsValidated = project.ProjectYearsValidated.update(false);
    if (project.TTFPlantingTimeframe.notValidToShowPartner)
        project.TTFPlantingTimeframeValidated = project.TTFPlantingTimeframeValidated.update(false);
    if (project.ReasonForPlanting.notValidToShowPartner)
        project.ReasonForPlantingValidated = project.ReasonForPlantingValidated.update(false);
    if (project.ImpactStoryInProgress.notValidToShowPartner)
        project.ImpactStoryInProgressValidated =
            project.ImpactStoryInProgressValidated.update(false);
    if (project.ImpactStoryCompleted.notValidToShowPartner)
        project.ImpactStoryCompletedValidated = project.ImpactStoryCompletedValidated.update(false);
    if (project.TTFTrees.notValidToShowPartner)
        project.TTFTreesValidated = project.TTFTreesValidated.update(false);
    if (project.AcresRestored.notValidToShowPartner)
        project.AcresRestoredValidated = project.AcresRestoredValidated.update(false);
    if (project.TreeSpeciesPlanted.notValidToShowPartner)
        project.TreeSpeciesValidated = project.TreeSpeciesValidated.update(false);
    if (project.TTFDBH.notValidToShowPartner)
        project.TTFDBHValidated = project.TTFDBHValidated.update(false);
    if (project.MapValidated.notValidToShowPartner)
        project.MapValidated = project.MapValidated.update(false);
}

function clearCommunityValidatedFlagsIfInvalid(project: EditedCommunityProject) {
    if (project.ProjectType.notValidToShowPartner)
        project.ProjectTypeValidated = project.ProjectTypeValidated.update(false);
    if (project.Name.notValidToShowPartner)
        project.NameValidated = project.NameValidated.update(false);
    if (project.Location.notValidToShowPartner)
        project.LocationValidated = project.LocationValidated.update(false);
    if (project.CommunitySiteName.notValidToShowPartner)
        project.CommunitySiteNameValidated = project.CommunitySiteNameValidated.update(false);
    if (project.CommunitySiteAddress.notValidToShowPartner)
        project.CommunitySiteAddressValidated = project.CommunitySiteAddressValidated.update(false);
    if (project.CommunitySiteCity.notValidToShowPartner)
        project.CommunitySiteCityValidated = project.CommunitySiteCityValidated.update(false);
    if (project.CommunitySiteStateCode.notValidToShowPartner)
        project.CommunitySiteStateCodeValidated =
            project.CommunitySiteStateCodeValidated.update(false);
    if (project.CommunitySitePostalCode.notValidToShowPartner)
        project.CommunitySitePostalCodeValidated =
            project.CommunitySitePostalCodeValidated.update(false);
    if (project.CommunitySiteCountryCode.notValidToShowPartner)
        project.CommunitySiteCountryCodeValidated =
            project.CommunitySiteCountryCodeValidated.update(false);
    if (project.CommunityFirstDayOfEvent.notValidToShowPartner)
        project.CommunityEventDatesValidated = project.CommunityEventDatesValidated.update(false);
    if (project.CommunityFinalDayOfEvent.notValidToShowPartner)
        project.CommunityEventDatesValidated = project.CommunityEventDatesValidated.update(false);
    if (project.ReasonForPlanting.notValidToShowPartner)
        project.ReasonForPlantingValidated = project.ReasonForPlantingValidated.update(false);
    if (project.ImpactStoryInProgress.notValidToShowPartner)
        project.ImpactStoryInProgressValidated =
            project.ImpactStoryInProgressValidated.update(false);
    if (project.ImpactStoryCompleted.notValidToShowPartner)
        project.ImpactStoryCompletedValidated = project.ImpactStoryCompletedValidated.update(false);
    if (project.CommunityNumberOfCorporateVolunteers.notValidToShowPartner)
        project.CommunityNumberOfCorporateVolunteersValidated =
            project.CommunityNumberOfCorporateVolunteersValidated.update(false);
    if (project.CommunityNumberOfTotalParticipants.notValidToShowPartner)
        project.CommunityNumberOfTotalParticipantsValidated =
            project.CommunityNumberOfTotalParticipantsValidated.update(false);
    if (project.CommunityTreesPlanted.notValidToShowPartner)
        project.CommunityTreesPlantedValidated =
            project.CommunityTreesPlantedValidated.update(false);
    if (project.CommunityTreesDistributed.notValidToShowPartner)
        project.CommunityTreesDistributedValidated =
            project.CommunityTreesDistributedValidated.update(false);
    if (project.TreeSpeciesPlanted.notValidToShowPartner)
        project.TreeSpeciesValidated = project.TreeSpeciesValidated.update(false);
    if (project.CommunityDBHTreesPlanted.notValidToShowPartner)
        project.CommunityDBHTreesPlantedValidated =
            project.CommunityDBHTreesPlantedValidated.update(false);
    if (project.CommunityDBHTreesDistributed.notValidToShowPartner)
        project.CommunityDBHTreesDistributedValidated =
            project.CommunityDBHTreesDistributedValidated.update(false);
    if (project.MapValidated.notValidToShowPartner)
        project.MapValidated = project.MapValidated.update(false);
}

function clearCarbonValidatedFlagsIfInvalid(project: EditedCarbonProject) {
    if (project.ProjectType.notValidToShowPartner)
        project.ProjectTypeValidated = project.ProjectTypeValidated.update(false);
    if (project.Name.notValidToShowPartner)
        project.NameValidated = project.NameValidated.update(false);
    if (project.Location.notValidToShowPartner)
        project.LocationValidated = project.LocationValidated.update(false);
    if (project.ProjectStartYear.notValidToShowPartner)
        project.ProjectYearsValidated = project.ProjectYearsValidated.update(false);
    if (project.ProjectEndYear.notValidToShowPartner)
        project.ProjectYearsValidated = project.ProjectYearsValidated.update(false);
    if (project.ImpactStoryCompleted.notValidToShowPartner)
        project.ImpactStoryCompletedValidated = project.ImpactStoryCompletedValidated.update(false);
    if (project.AcresRestored.notValidToShowPartner)
        project.AcresRestoredValidated = project.AcresRestoredValidated.update(false);
    if (project.MapValidated.notValidToShowPartner)
        project.MapValidated = project.MapValidated.update(false);
}

function clearValidatedFlagsIfInvalid(project: EditedProject) {
    switch (project.ProjectType.current) {
        case PlantingProjectType.Reforestation:
            clearReforestationValidatedFlagsIfInvalid(project);
            break;
        case PlantingProjectType.CommunityEvent:
            clearCommunityValidatedFlagsIfInvalid(project);
            break;
        case PlantingProjectType.Carbon:
            clearCarbonValidatedFlagsIfInvalid(project);
            break;
        case PlantingProjectType.CommunityCanopy:
            break;
    }
}

export function setValidations(project: EditedProject) {
    if (!project) return;
    clearValidations(project);

    switch (project.ProjectType.current) {
        case PlantingProjectType.Reforestation:
            setReforestationValidations(project);
            break;
        case PlantingProjectType.CommunityEvent:
            setCommunityValidations(project);
            break;
        case PlantingProjectType.Carbon:
            setCarbonValidations(project);
            break;
        case PlantingProjectType.CommunityCanopy:
            setCommunityCanopyValidations(project);
            break;
    }

    clearValidatedFlagsIfInvalid(project);
}
