import {StickyHeader} from '../../components/Table/TableStyles';
import {ParticipationView, viewIncludesCarbon} from './ParticipationViewSelector';
import {fixedProjectIdColumn} from './Styles/ParticipationTableStyles';

const getParticipationSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 7;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 7;
        case ParticipationView.Carbon:
            return 8;
        case ParticipationView.CommunityCanopy:
            return 5;
        case ParticipationView.CommunityTreeDistributor:
            return 4;
        default:
            return -1;
    }
};

const getFixedWhatSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 3;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 3;
        case ParticipationView.Carbon:
            return 3;
        case ParticipationView.CommunityCanopy:
            return 3;
        case ParticipationView.CommunityTreeDistributor:
            return 2;
        default:
            return -1;
    }
};

const getNonFixedWhatSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 1;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 1;
        case ParticipationView.Carbon:
            return 1;
        case ParticipationView.CommunityCanopy:
            return 1;
        case ParticipationView.CommunityTreeDistributor:
            return 1;
        default:
            return -1;
    }
};

const getWhereSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 3;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 8;
        case ParticipationView.Carbon:
            return 2;
        case ParticipationView.CommunityCanopy:
            return 0;
        case ParticipationView.CommunityTreeDistributor:
            return 0;
        default:
            return -1;
    }
};

const getWhenSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 2;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 1;
        case ParticipationView.Carbon:
            return 1;
        case ParticipationView.CommunityCanopy:
            return 1;
        case ParticipationView.CommunityTreeDistributor:
            return 1;
        default:
            return -1;
    }
};

const getWhySpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 3;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 3;
        case ParticipationView.Carbon:
            return 2;
        case ParticipationView.CommunityCanopy:
            return 2;
        case ParticipationView.CommunityTreeDistributor:
            return 3;
        default:
            return -1;
    }
};

const getWhoSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 5;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 3;
        case ParticipationView.Carbon:
            return 1;
        case ParticipationView.CommunityCanopy:
            return 0;
        case ParticipationView.CommunityTreeDistributor:
            return 0;
        default:
            return -1;
    }
};

const getWhatPlantedSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 4;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 5;
        case ParticipationView.Carbon:
            return 1;
        case ParticipationView.CommunityCanopy:
            return 0;
        case ParticipationView.CommunityTreeDistributor:
            return 0;
        default:
            return -1;
    }
};

const getImagesSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 4;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 4;
        case ParticipationView.Carbon:
            return 4;
        case ParticipationView.CommunityCanopy:
            return 0;
        case ParticipationView.CommunityTreeDistributor:
            return 1;
        default:
            return -1;
    }
};

const getBenefitsSpan = (view: ParticipationView) => {
    switch (view) {
        case ParticipationView.Reforestation:
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
            return 3;
        case ParticipationView.Community:
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
            return 0;
        case ParticipationView.Carbon:
            return 0;
        case ParticipationView.CommunityCanopy:
            return 0;
        case ParticipationView.CommunityTreeDistributor:
            return 0;
        default:
            return -1;
    }
};

function ParticipationColumnGroupRow({view}: {view: ParticipationView}) {
    const participationGroupSpan = getParticipationSpan(view);
    const fixedWhatGroupSpan = getFixedWhatSpan(view);
    const nonFixedWhatGroupSpan = getNonFixedWhatSpan(view);
    const whereGroupSpan = getWhereSpan(view);
    const whenGroupSpan = getWhenSpan(view);
    const whoGroupSpan = getWhoSpan(view);
    const whatPlantedGroupSpan = getWhatPlantedSpan(view);
    const whyGroupSpan = getWhySpan(view);
    const imagesGroupSpan = getImagesSpan(view);
    //eslint-disable-next-line
    const benefitsGroupSpan = getBenefitsSpan(view);

    return (
        <tr>
            <StickyHeader colSpan={4} style={{border: 'none', zIndex: 1}}></StickyHeader>
            <StickyHeader
                colSpan={participationGroupSpan}
                style={{
                    border: 'none',
                    zIndex: 1,
                    textAlign: 'center',
                    backgroundColor: 'rgb(255, 243, 230)'
                }}>
                This partner's participation
            </StickyHeader>
            <StickyHeader
                colSpan={fixedWhatGroupSpan}
                style={{
                    border: 'none',
                    zIndex: 1,
                    ...fixedProjectIdColumn,
                    textAlign: 'center',
                    backgroundColor: 'rgb(245, 230, 255)'
                }}>
                What is the project?
            </StickyHeader>
            <StickyHeader
                colSpan={nonFixedWhatGroupSpan}
                style={{border: 'none', backgroundColor: 'rgb(245, 230, 255)'}}></StickyHeader>
            {whereGroupSpan > 0 && (
                <StickyHeader
                    colSpan={whereGroupSpan}
                    style={{
                        border: 'none',
                        textAlign: 'center',
                        backgroundColor: 'rgb(215, 230, 255)'
                    }}>
                    {whereGroupSpan > 1 ? 'Where is the project?' : 'Where?'}
                </StickyHeader>
            )}
            {whenGroupSpan > 0 && (
                <StickyHeader
                    colSpan={whenGroupSpan}
                    style={{
                        border: 'none',
                        textAlign: 'center',
                        backgroundColor: 'rgb(230, 245, 255)',
                        minWidth: '200px'
                    }}>
                    When is the project?
                </StickyHeader>
            )}
            {whyGroupSpan > 0 && (
                <StickyHeader
                    colSpan={whyGroupSpan}
                    style={{
                        border: 'none',
                        textAlign: 'center',
                        backgroundColor: 'rgb(215, 230, 255)'
                    }}>
                    Why is the project being done?
                </StickyHeader>
            )}
            {whoGroupSpan > 0 && (
                <StickyHeader
                    colSpan={whoGroupSpan}
                    style={{
                        border: 'none',
                        textAlign: 'center',
                        backgroundColor: 'rgb(230, 245, 255)'
                    }}>
                    Who is participating?
                </StickyHeader>
            )}
            {whatPlantedGroupSpan > 0 && (
                <StickyHeader
                    colSpan={whatPlantedGroupSpan}
                    style={{
                        border: 'none',
                        textAlign: 'center',
                        backgroundColor: 'rgb(210, 235, 250)',
                        minWidth: '200px'
                    }}>
                    What is being planted?
                </StickyHeader>
            )}
            {viewIncludesCarbon(view) && (
                <StickyHeader
                    colSpan={8}
                    style={{
                        border: 'none',
                        textAlign: 'center',
                        backgroundColor: 'rgb(199, 219, 251)'
                    }}>
                    Additional Project Details
                </StickyHeader>
            )}
            {imagesGroupSpan > 0 && (
                <StickyHeader
                    colSpan={4}
                    style={{
                        border: 'none',
                        textAlign: 'center',
                        backgroundColor: 'rgb(230, 245, 255)'
                    }}>
                    Lytho images
                </StickyHeader>
            )}
        </tr>
    );
}

export default ParticipationColumnGroupRow;
