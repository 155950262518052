import {useState, useEffect} from 'react';
import {LythoImage} from '../../../entities';
import {
    Section,
    LabelContainer,
    SectionHeader,
    ReadOnlyProperty,
    PropertyName
} from '../../../components/Form/FormStyles';
import {Help} from '../../../components/Buttons/Help';
import {ValidateControl} from '../../../components/Controls/ValidateControl';
import {EditedProject} from '../EditedProject';
import {
    LythoImageGrid,
    LythoImageContainer,
    LythoImageDisplay,
    LythoImageMetadata
} from './LythoImageGrid';

export function ImagesSection({
    newProject,
    project,
    setProjectAndValidate
}: {
    newProject: boolean;
    project: EditedProject;
    setProjectAndValidate: (project: EditedProject) => void;
}) {
    const [lythoImageData, setLythoImageData] = useState(null);

    useEffect(() => {
        setLythoImageData(null);
    }, [project.Id]);

    const getLythoImageData = async () => {
        if (!newProject && project) {
            const result = await (
                await fetch(`/api/lytho-images?projects=${project.DAMTag}`)
            ).json();
            setLythoImageData(result);
        }
    };

    const damTag = project?.DAMTag ?? '';

    useEffect(() => {
        getLythoImageData();
    }, [damTag]);

    if (lythoImageData === null || lythoImageData[project.DAMTag] === null) return;

    const lythoImages = lythoImageData ? lythoImageData[project.DAMTag].images : [];
    const heroImage = lythoImageData
        ? lythoImages.find((a) => a.id === lythoImageData[project.DAMTag].hero.id)
        : null;

    function imageSortPriority(a: LythoImage) {
        if (a.id === heroImage?.id) return 999999;
        return a.width / a.height;
    }

    lythoImages.sort((a: LythoImage, b: LythoImage) => imageSortPriority(b) - imageSortPriority(a));

    return (
        <Section>
            <LabelContainer>
                {!project.ControlledByAutomation && (
                    <ValidateControl
                        validated={project.ImagesValidated.current}
                        disabled={false}
                        onChange={(e) =>
                            setProjectAndValidate({
                                ...project,
                                ImagesValidated: project.ImagesValidated.update(e.validated)
                            })
                        }
                    />
                )}
                <SectionHeader>Lytho images</SectionHeader>
                {!project.ControlledByAutomation && (
                    <Help>All images with acceptable quality have been added to Lytho</Help>
                )}
            </LabelContainer>
            <ReadOnlyProperty>
                <PropertyName>DAM tag: </PropertyName>
                {project.DAMTag}
            </ReadOnlyProperty>
            <ReadOnlyProperty>
                <PropertyName>Image count: </PropertyName>
                {lythoImageData && lythoImageData[project.DAMTag]['image-count']}
            </ReadOnlyProperty>
            <ReadOnlyProperty>
                <PropertyName>Has hero image? </PropertyName>
                {lythoImageData && (lythoImageData[project.DAMTag].hero?.id ? 'yes' : 'no')}
            </ReadOnlyProperty>
            {lythoImages.length > 0 && (
                <LythoImageGrid>
                    {lythoImages.map((im: LythoImage) => (
                        <LythoImageContainer key={im.id}>
                            <LythoImageDisplay
                                src={im.link}
                                hero={im.id === lythoImageData[project.DAMTag].hero?.id}
                            />
                            <LythoImageMetadata>
                                <div>
                                    {im.width} x {im.height}
                                </div>
                                {im.id === lythoImageData[project.DAMTag].hero?.id && (
                                    <div>hero</div>
                                )}
                                <div>{im.type}</div>
                            </LythoImageMetadata>
                        </LythoImageContainer>
                    ))}
                </LythoImageGrid>
            )}
        </Section>
    );
}
