import {Filter} from '../../ParticipationFunctions/Filtering';
import {CommunityEventDatesFilter} from '../../ParticipationColumns/CommunityEvents/CommunityEventDates';
import {CommunityTreesDistributedFilter} from '../../ParticipationColumns/Shared/CommunityTreesDistributed';
import {DeleteParticipationFilter} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedFilter} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedFilter} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedFilter} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearFilter} from '../../ParticipationColumns/Shared/FundedYear';
import {ImpactStoryCompletedFilter} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import {ParticipationIdFilter} from '../../ParticipationColumns/Shared/ParticipationId';
import {ProjectIdFilter} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectNameFilter} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectTypeFilter} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ReasonForPlantingFilter} from '../../ParticipationColumns/Shared/ReasonForPlanting';
import {FundingSourceFilter} from '../../ParticipationColumns/Shared/FundingSource';
import { ImpactStoryInProgressFilter } from '../../ParticipationColumns/Shared/ImpactStoryInProgress';
import { DAMTagFilter } from '../../ParticipationColumns/Shared/DAMTag';

export function CommunityTreeDistributorParticipationFilter({
    filter,
    updateFilter
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
}) {
    return (
        <tr>
            <DeleteParticipationFilter />
            <ExcludedFilter filter={filter} updateFilter={updateFilter} />
            <EverythingValidatedFilter filter={filter} updateFilter={updateFilter} />
            <ParticipationIdFilter filter={filter} updateFilter={updateFilter} />
            <FundedYearFilter filter={filter} updateFilter={updateFilter} />
            <FundingSourceFilter filter={filter} updateFilter={updateFilter} />
            <CommunityTreesDistributedFilter filter={filter} updateFilter={updateFilter} />
            <DollarDonatedFilter filter={filter} updateFilter={updateFilter} />
            <ProjectIdFilter filter={filter} updateFilter={updateFilter} />
            <ProjectTypeFilter filter={filter} updateFilter={updateFilter} />
            <ProjectNameFilter filter={filter} updateFilter={updateFilter} />
            <CommunityEventDatesFilter filter={filter} updateFilter={updateFilter} />
            <ReasonForPlantingFilter filter={filter} updateFilter={updateFilter} />
            <ImpactStoryInProgressFilter filter={filter} updateFilter={updateFilter} />
            <ImpactStoryCompletedFilter filter={filter} updateFilter={updateFilter} />
            <DAMTagFilter filter={filter} updateFilter={updateFilter} />
        </tr>
    );
}
