import {
    formatProjectStatus,
    formatProjectType,
    LythoImageProject,
    PlantingProjectType,
    formatYesNoConditional
} from '../../../entities';
import {
    booleanMatch,
    excludedMatch,
    numberMatch,
    numberRangeMatch,
    textMatch,
    validatedMatch
} from '../../../filters';
import {ExcludeFilterValue} from '../../../components/Controls/ExcludeControl';
import {PublishFilterValue} from '../../../components/Controls/PublishControl';
import {ValidateFilterValue} from '../../../components/Controls/ValidateControl';
import {EditedParticipation} from '../../Partner/EditedPartner';
import {ParticipationView} from '../ParticipationViewSelector';
import {formatAdditionalCertificationsArray} from '../../PlantingProjectEditor/Carbon/CarbonCreditAdditionalCertifications';
import {formatCarbonCreditTypes} from '../../PlantingProjectEditor/Carbon/CarbonCreditType';
import {formatCarbonProjectStandards} from '../../PlantingProjectEditor/Carbon/ProjectStandard';
import {formatSustainableDevelopmentGoalArray} from '../../PlantingProjectEditor/Carbon/SustainableDevelopmentGoals';
export type Filter = {
    Id: string;

    FundedYear: string;
    FundedYearValidated: ValidateFilterValue;
    FundingSource: string;
    TTFTrees: string;
    TTFTreesValidated: ValidateFilterValue;
    CommunityTreesPlanted: string;
    CommunityTreesPlantedValidated: ValidateFilterValue;
    CommunityTreesDistributed: string;
    CommunityTreesDistributedValidated: ValidateFilterValue;
    DollarsDonated: string;
    DollarsDonatedValidated: ValidateFilterValue;
    CommunityBudgetPassThrough: string;
    CommunityBudgetPassThroughValidated: ValidateFilterValue;
    ShowOnDate: string;

    PlantingProjectId: string;
    ProjectType: string;
    ProjectTypeValidated: ValidateFilterValue;
    Name: string;
    NameValidated: ValidateFilterValue;
    ProjectStatus: string;
    ProjectStatusValidated: ValidateFilterValue;

    Location: string;
    LocationValidated: ValidateFilterValue;
    TTFForest: string;
    TTFForestCodeValidated: ValidateFilterValue;
    CommunitySiteName: string;
    CommunitySiteNameValidated: ValidateFilterValue;
    CommunitySiteAddress: string;
    CommunitySiteAddressValidated: ValidateFilterValue;
    CommunitySiteCity: string;
    CommunitySiteCityValidated: ValidateFilterValue;
    CommunitySiteStateCode: string;
    CommunitySiteStateCodeValidated: ValidateFilterValue;
    CommunitySitePostalCode: string;
    CommunitySitePostalCodeValidated: ValidateFilterValue;
    CommunitySiteCountryCode: string;
    CommunitySiteCountryCodeValidated: ValidateFilterValue;
    MapValidated: ValidateFilterValue;

    ProjectYears: string;
    ProjectYearsValidated: ValidateFilterValue;
    CommunityEventDates: string;
    CommunityEventDatesValidated: ValidateFilterValue;
    TTFPlantingTimeframe: string;
    TTFPlantingTimeframeValidated: ValidateFilterValue;

    ReasonForPlanting: string;
    ReasonForPlantingValidated: ValidateFilterValue;
    HasImpactStoryInProgress: string;
    ImpactStoryInProgressValidated: ValidateFilterValue;
    HasImpactStoryCompleted: string;
    ImpactStoryCompletedValidated: ValidateFilterValue;

    PlantingPartner: string;
    PlantingPartnerValidated: ValidateFilterValue;
    TTFCommitments: string;
    TTFCommitmentFiscalYear: string;
    TTFCommitmentFiscalYearValidated: ValidateFilterValue;
    TTFAgreementNumber: string;
    TTFAgreementNumberValidated: ValidateFilterValue;
    TTFTotalTreeCost: string;
    TTFTotalTreeCostValidated: ValidateFilterValue;
    CommunityNumberOfCorporateVolunteers: string;
    CommunityNumberOfCorporateVolunteersValidated: ValidateFilterValue;
    CommunityNumberOfTotalParticipants: string;
    CommunityNumberOfTotalParticipantsValidated: ValidateFilterValue;

    ProjectTTFTrees: string;
    ProjectTTFTreesValidated: ValidateFilterValue;
    ProjectCommunityTreesPlanted: string;
    ProjectCommunityTreesPlantedValidated: ValidateFilterValue;
    ProjectCommunityTreesDistributed: string;
    ProjectCommunityTreesDistributedValidated: ValidateFilterValue;
    ProjectAcresRestored: string;
    ProjectAcresRestoredValidated: ValidateFilterValue;

    TreeSpecies: string;
    TreeSpeciesValidated: ValidateFilterValue;
    TTFDBH: string;
    TTFDBHValidated: ValidateFilterValue;
    CommunityDBHTreesPlanted: string;
    CommunityDBHTreesPlantedValidated: ValidateFilterValue;
    CommunityDBHTreesDistributed: string;
    CommunityDBHTreesDistributedValidated: ValidateFilterValue;

    DAMTag: string;
    ImagesValidated: ValidateFilterValue;
    ImageCount: string;
    HasHeroImage: string;

    Excluded: ExcludeFilterValue;
    EverythingValidated: ValidateFilterValue;
    Published: PublishFilterValue;

    SustainableDevelopmentGoals: string;
    SustainableDevelopmentGoalsValidated: ValidateFilterValue;
    CarbonTotalCreditsIssuedValidated: ValidateFilterValue;
    CarbonProjectStandardValidated: ValidateFilterValue;
    CarbonCreditCreditTypeValidated: ValidateFilterValue;
    CarbonICroaEndorsedStandardValidated: ValidateFilterValue;
    CarbonCorsiaEligibleValidated: ValidateFilterValue;
    CarbonAdditionalDetailsLinkValidated: ValidateFilterValue;
    CarbonProjectRegistryLinkValidated: ValidateFilterValue;
    AdditionalCarbonCreditCertificationsValidated: ValidateFilterValue;
    CarbonTotalCreditsIssued: string;
    CarbonProjectStandard: string;
    CarbonCreditCreditType: string;
    CarbonICroaEndorsedStandard: string;
    CarbonCorsiaEligible: string;
    CarbonAdditionalDetailsLink: string;
    CarbonProjectRegistryLink: string;
    AdditionalCarbonCreditCertifications: string;
    CarbonPurchaseAmount: string;
    CarbonPurchaseAmountValidated: ValidateFilterValue;
    CarbonCreditTransactions: string;
    CarbonTransactionsValidated: ValidateFilterValue;
    VintageYear: string;
    VintageYearValidated: ValidateFilterValue;
    SerialNumber: string;
    SerialNumberValidated: ValidateFilterValue;
    ParticipantRegistryLink: string;
    ParticipantRegistryLinkValidated: ValidateFilterValue;
    CarbonDocumentsValidated: ValidateFilterValue;
    CarbonTransactionCount: string;
    DocumentCount: string;
    BHCampaigns: string;
};

function passesCommunityFilter(ppn: EditedParticipation) {
    return ppn.ProjectType === PlantingProjectType.CommunityEvent;
}

function passesCPCommunityFilter(ppn: EditedParticipation) {
    return (
        ppn.ProjectType === PlantingProjectType.CommunityEvent &&
        (!ppn.FundedYearValidated.current ||
            !ppn.CommunityTreesPlantedValidated.current ||
            !ppn.CommunityTreesDistributedValidated.current ||
            !ppn.DollarsDonatedValidated.current ||
            !ppn.PlantingProject.ProjectTypeValidated ||
            !ppn.PlantingProject.CommunitySiteNameValidated ||
            !ppn.PlantingProject.CommunitySiteAddressValidated ||
            !ppn.PlantingProject.CommunitySiteCityValidated ||
            !ppn.PlantingProject.CommunitySiteStateCodeValidated ||
            !ppn.PlantingProject.CommunitySitePostalCodeValidated ||
            !ppn.PlantingProject.CommunitySiteCountryCodeValidated ||
            !ppn.PlantingProject.CommunityEventDatesValidated ||
            !ppn.PlantingProject.CommunityNumberOfCorporateVolunteersValidated ||
            !ppn.PlantingProject.CommunityTreesPlantedValidated ||
            !ppn.PlantingProject.CommunityTreesDistributedValidated)
    );
}

function passesCommunityUnvalidatedFilter(ppn: EditedParticipation) {
    const project = ppn.PlantingProject;
    return (
        ppn.ProjectType === PlantingProjectType.CommunityEvent &&
        (!project.NameValidated ||
            !project.LocationValidated ||
            !project.ReasonForPlantingValidated ||
            !project.PlantingPartnerNameValidated ||
            !project.CommunityDBHTreesPlantedValidated ||
            !project.CommunityDBHTreesDistributedValidated ||
            !project.TreeSpeciesValidated ||
            !project.CompletedValidated ||
            !project.CommunityNumberOfCorporateVolunteersValidated ||
            !project.CommunityNumberOfTotalParticipantsValidated ||
            !ppn.CommunityBudgetPassThroughValidated.current)
    );
}

function passesReforestationFilter(ppn: EditedParticipation) {
    return ppn.ProjectType === PlantingProjectType.Reforestation;
}

function passesCPReforestationFilter(ppn: EditedParticipation) {
    return (
        ppn.ProjectType === PlantingProjectType.Reforestation &&
        (!ppn.FundedYearValidated.current ||
            !ppn.TTFTreesValidated.current ||
            !ppn.CommunityTreesPlantedValidated.current ||
            !ppn.CommunityTreesDistributedValidated.current ||
            !ppn.DollarsDonatedValidated.current ||
            !ppn.PlantingProject.ProjectTypeValidated)
    );
}

function passesReforestationUnvalidatedFilter(ppn: EditedParticipation) {
    const project = ppn.PlantingProject;
    return (
        ppn.ProjectType === PlantingProjectType.Reforestation &&
        (!project.NameValidated ||
            !project.LocationValidated ||
            !project.TTFForestCodeValidated ||
            !project.ProjectYearsValidated ||
            !project.TTFPlantingTimeframeValidated ||
            !project.ReasonForPlantingValidated ||
            !project.PlantingPartnerNameValidated ||
            !project.TTFTreesValidated ||
            !project.TTFDBHValidated ||
            !project.AcresRestoredValidated ||
            !project.TreeSpeciesValidated ||
            !project.CompletedValidated ||
            !project.TTFAgreementNumberValidated ||
            !project.TTFCommitmentFiscalYearValidated ||
            !project.TTFTotalTreeCostValidated)
    );
}

function passesCarbonFilter(ppn: EditedParticipation) {
    return ppn.ProjectType === PlantingProjectType.Carbon;
}

function passesCommunityCanopyFilter(ppn: EditedParticipation) {
    return ppn.ProjectType === PlantingProjectType.CommunityCanopy;
}

function passesCommunityTreeDistributorFilter(ppn: EditedParticipation) {
    return ppn.ProjectType === PlantingProjectType.CommunityTreeDistributor;
}

function passesViewFilter(ppn: EditedParticipation, view: ParticipationView) {
    switch (view) {
        case ParticipationView.Default:
            return true;
        case ParticipationView.Community:
            return passesCommunityFilter(ppn);
        case ParticipationView.CPEvents:
            return passesCPCommunityFilter(ppn);
        case ParticipationView.UnvalidatedEvents:
            return passesCommunityUnvalidatedFilter(ppn);
        case ParticipationView.CPReforestation:
            return passesCPReforestationFilter(ppn);
        case ParticipationView.UnvalidatedReforestation:
            return passesReforestationUnvalidatedFilter(ppn);
        case ParticipationView.Reforestation:
            return passesReforestationFilter(ppn);
        case ParticipationView.Carbon:
            return passesCarbonFilter(ppn);
        case ParticipationView.CommunityCanopy:
            return passesCommunityCanopyFilter(ppn);
        case ParticipationView.CommunityTreeDistributor:
            return passesCommunityTreeDistributorFilter(ppn);
    }
}

export const getCarbonCreditCount = (transactions) => {
    let total = 0;
    transactions?.forEach(
        (transaction) => (total += transaction?.CarbonCreditsTransacted?.current)
    );
    return total;
};

function passesParticipationFilter(
    ppn: EditedParticipation,
    lythoImageData: LythoImageProject[],
    filter: Filter,
    view: ParticipationView
) {
    if (!passesViewFilter(ppn, view)) return false;
    if (!filter) return true;

    const lythoProjectData = lythoImageData ? lythoImageData[ppn.PlantingProject.DAMTag] : null;

    return (
        textMatch(ppn.Id.toString(), filter.Id) &&
        numberMatch(ppn.FundedYear.current, filter.FundedYear) &&
        validatedMatch(ppn.FundedYearValidated.current, filter.FundedYearValidated) &&
        textMatch(ppn.FundingSource.current, filter.FundingSource) &&
        numberMatch(ppn.TTFTrees.current, filter.TTFTrees) &&
        validatedMatch(ppn.TTFTreesValidated.current, filter.TTFTreesValidated) &&
        numberMatch(ppn.CommunityTreesPlanted.current, filter.CommunityTreesPlanted) &&
        validatedMatch(
            ppn.CommunityTreesPlantedValidated.current,
            filter.CommunityTreesPlantedValidated
        ) &&
        numberMatch(ppn.CommunityTreesDistributed.current, filter.CommunityTreesDistributed) &&
        validatedMatch(
            ppn.CommunityTreesDistributedValidated.current,
            filter.CommunityTreesDistributedValidated
        ) &&
        numberMatch(ppn.DollarsDonated.current, filter.DollarsDonated) &&
        validatedMatch(ppn.DollarsDonatedValidated.current, filter.DollarsDonatedValidated) &&
        numberMatch(ppn.CommunityBudgetPassThrough.current, filter.CommunityBudgetPassThrough) &&
        validatedMatch(
            ppn.CommunityBudgetPassThroughValidated.current,
            filter.CommunityBudgetPassThroughValidated
        ) &&
        textMatch(ppn.ShowOnDate.current, filter.ShowOnDate) &&
        textMatch(ppn.PlantingProjectId.current?.toString(), filter.PlantingProjectId) &&
        textMatch(formatProjectType(ppn.PlantingProject.ProjectType), filter.ProjectType) &&
        validatedMatch(ppn.PlantingProject.ProjectTypeValidated, filter.ProjectTypeValidated) &&
        textMatch(ppn.PlantingProject.Name, filter.Name) &&
        validatedMatch(ppn.PlantingProject.NameValidated, filter.NameValidated) &&
        textMatch(
            ppn.PlantingProject.Id ? formatProjectStatus(ppn.PlantingProject.Completed) : '',
            filter.ProjectStatus
        ) &&
        validatedMatch(ppn.PlantingProject.CompletedValidated, filter.ProjectStatusValidated) &&
        textMatch(ppn.PlantingProject.Location, filter.Location) &&
        validatedMatch(ppn.PlantingProject.LocationValidated, filter.LocationValidated) &&
        textMatch(ppn.PlantingProject.TTFForestName, filter.TTFForest) &&
        validatedMatch(ppn.PlantingProject.TTFForestCodeValidated, filter.TTFForestCodeValidated) &&
        textMatch(ppn.PlantingProject.CommunitySiteName, filter.CommunitySiteName) &&
        validatedMatch(
            ppn.PlantingProject.CommunitySiteNameValidated,
            filter.CommunitySiteNameValidated
        ) &&
        textMatch(ppn.PlantingProject.CommunitySiteAddress, filter.CommunitySiteAddress) &&
        validatedMatch(
            ppn.PlantingProject.CommunitySiteAddressValidated,
            filter.CommunitySiteAddressValidated
        ) &&
        textMatch(ppn.PlantingProject.CommunitySiteCity, filter.CommunitySiteCity) &&
        validatedMatch(
            ppn.PlantingProject.CommunitySiteCityValidated,
            filter.CommunitySiteCityValidated
        ) &&
        textMatch(ppn.PlantingProject.CommunitySiteStateCode, filter.CommunitySiteStateCode) &&
        validatedMatch(
            ppn.PlantingProject.CommunitySiteStateCodeValidated,
            filter.CommunitySiteStateCodeValidated
        ) &&
        textMatch(ppn.PlantingProject.CommunitySitePostalCode, filter.CommunitySitePostalCode) &&
        validatedMatch(
            ppn.PlantingProject.CommunitySitePostalCodeValidated,
            filter.CommunitySitePostalCodeValidated
        ) &&
        textMatch(ppn.PlantingProject.CommunitySiteCountryCode, filter.CommunitySiteCountryCode) &&
        validatedMatch(
            ppn.PlantingProject.CommunitySiteCountryCodeValidated,
            filter.CommunitySiteCountryCodeValidated
        ) &&
        validatedMatch(ppn.PlantingProject.MapValidated, filter.MapValidated) &&
        numberRangeMatch(
            ppn.PlantingProject.ProjectStartYear,
            ppn.PlantingProject.ProjectEndYear,
            filter.ProjectYears
        ) &&
        validatedMatch(ppn.PlantingProject.ProjectYearsValidated, filter.ProjectYearsValidated) &&
        textMatch(ppn.PlantingProject.CommunityFirstDayOfEvent, filter.CommunityEventDates) &&
        validatedMatch(
            ppn.PlantingProject.CommunityEventDatesValidated,
            filter.CommunityEventDatesValidated
        ) &&
        textMatch(ppn.PlantingProject.TTFPlantingTimeframe, filter.TTFPlantingTimeframe) &&
        validatedMatch(
            ppn.PlantingProject.TTFPlantingTimeframeValidated,
            filter.TTFPlantingTimeframeValidated
        ) &&
        textMatch(ppn.PlantingProject.ReasonForPlanting, filter.ReasonForPlanting) &&
        validatedMatch(
            ppn.PlantingProject.ReasonForPlantingValidated,
            filter.ReasonForPlantingValidated
        ) &&
        booleanMatch(
            ppn.PlantingProject.HasImpactStoryInProgress,
            filter.HasImpactStoryInProgress
        ) &&
        validatedMatch(
            ppn.PlantingProject.ImpactStoryInProgressValidated,
            filter.ImpactStoryInProgressValidated
        ) &&
        booleanMatch(ppn.PlantingProject.HasImpactStoryCompleted, filter.HasImpactStoryCompleted) &&
        validatedMatch(
            ppn.PlantingProject.ImpactStoryCompletedValidated,
            filter.ImpactStoryCompletedValidated
        ) &&
        textMatch(ppn.PlantingProject.PlantingPartnerName, filter.PlantingPartner) &&
        validatedMatch(
            ppn.PlantingProject.PlantingPartnerNameValidated,
            filter.PlantingPartnerValidated
        ) &&
        textMatch(
            ppn.PlantingProject.Commitments?.map((commitment) => commitment.CommitmentId).join(
                ', '
            ),
            filter.TTFCommitments
        ) &&
        textMatch(ppn.PlantingProject.TTFCommitmentFiscalYear, filter.TTFCommitmentFiscalYear) &&
        validatedMatch(
            ppn.PlantingProject.TTFCommitmentFiscalYearValidated,
            filter.TTFCommitmentFiscalYearValidated
        ) &&
        textMatch(ppn.PlantingProject.TTFAgreementNumber, filter.TTFAgreementNumber) &&
        validatedMatch(
            ppn.PlantingProject.TTFAgreementNumberValidated,
            filter.TTFAgreementNumberValidated
        ) &&
        numberMatch(ppn.PlantingProject.TTFTotalTreeCost, filter.TTFTotalTreeCost) &&
        validatedMatch(
            ppn.PlantingProject.TTFTotalTreeCostValidated,
            filter.TTFTotalTreeCostValidated
        ) &&
        numberMatch(
            ppn.PlantingProject.CommunityNumberOfCorporateVolunteers,
            filter.CommunityNumberOfCorporateVolunteers
        ) &&
        validatedMatch(
            ppn.PlantingProject.CommunityNumberOfCorporateVolunteersValidated,
            filter.CommunityNumberOfCorporateVolunteersValidated
        ) &&
        numberMatch(
            ppn.PlantingProject.CommunityNumberOfTotalParticipants,
            filter.CommunityNumberOfTotalParticipants
        ) &&
        validatedMatch(
            ppn.PlantingProject.CommunityNumberOfTotalParticipantsValidated,
            filter.CommunityNumberOfTotalParticipantsValidated
        ) &&
        numberMatch(ppn.PlantingProject.TTFTrees, filter.ProjectTTFTrees) &&
        validatedMatch(ppn.PlantingProject.TTFTreesValidated, filter.ProjectTTFTreesValidated) &&
        numberMatch(
            ppn.PlantingProject.CommunityTreesPlanted,
            filter.ProjectCommunityTreesPlanted
        ) &&
        validatedMatch(
            ppn.PlantingProject.CommunityTreesPlantedValidated,
            filter.ProjectCommunityTreesPlantedValidated
        ) &&
        numberMatch(
            ppn.PlantingProject.CommunityTreesDistributed,
            filter.ProjectCommunityTreesDistributed
        ) &&
        validatedMatch(
            ppn.PlantingProject.CommunityTreesDistributedValidated,
            filter.ProjectCommunityTreesDistributedValidated
        ) &&
        numberMatch(ppn.PlantingProject.AcresRestored, filter.ProjectAcresRestored) &&
        validatedMatch(
            ppn.PlantingProject.AcresRestoredValidated,
            filter.ProjectAcresRestoredValidated
        ) &&
        booleanMatch(ppn.PlantingProject.HasTreeSpecies, filter.TreeSpecies) &&
        validatedMatch(ppn.PlantingProject.TreeSpeciesValidated, filter.TreeSpeciesValidated) &&
        numberMatch(ppn.PlantingProject.TTFDBH, filter.TTFDBH) &&
        validatedMatch(ppn.PlantingProject.TTFDBHValidated, filter.TTFDBHValidated) &&
        numberMatch(
            ppn.PlantingProject.CommunityDBHTreesPlanted,
            filter.CommunityDBHTreesPlanted
        ) &&
        validatedMatch(
            ppn.PlantingProject.CommunityDBHTreesPlantedValidated,
            filter.CommunityDBHTreesPlantedValidated
        ) &&
        numberMatch(
            ppn.PlantingProject.CommunityDBHTreesDistributed,
            filter.CommunityDBHTreesDistributed
        ) &&
        validatedMatch(
            ppn.PlantingProject.CommunityDBHTreesDistributedValidated,
            filter.CommunityDBHTreesDistributedValidated
        ) &&
        textMatch(ppn.PlantingProject.DAMTag, filter.DAMTag) &&
        validatedMatch(ppn.PlantingProject.ImagesValidated, filter.ImagesValidated) &&
        numberMatch(lythoProjectData ? lythoProjectData['image-count'] : 0, filter.ImageCount) &&
        booleanMatch(lythoProjectData?.hero ?? false, filter.HasHeroImage) &&
        excludedMatch(ppn.Excluded.current, filter.Excluded) &&
        validatedMatch(ppn.EverythingValidated, filter.EverythingValidated) &&
        textMatch(
            ppn.PlantingProject.CarbonAdditionalDetailsLink,
            filter.CarbonAdditionalDetailsLink
        ) &&
        validatedMatch(
            ppn.PlantingProject.CarbonAdditionalDetailsLinkValidated,
            filter.CarbonAdditionalDetailsLinkValidated
        ) &&
        textMatch(
            ppn.PlantingProject.CarbonProjectRegistryLink,
            filter.CarbonProjectRegistryLink
        ) &&
        validatedMatch(
            ppn.PlantingProject.CarbonProjectRegistryLinkValidated,
            filter.CarbonProjectRegistryLinkValidated
        ) &&
        numberMatch(
            ppn.PlantingProject.CarbonTotalCreditsIssued,
            filter.CarbonTotalCreditsIssued
        ) &&
        validatedMatch(
            ppn.PlantingProject.CarbonTotalCreditsIssuedValidated,
            filter.CarbonTotalCreditsIssuedValidated
        ) &&
        textMatch(
            formatSustainableDevelopmentGoalArray(ppn.PlantingProject.SustainableDevelopmentGoals),
            filter.SustainableDevelopmentGoals
        ) &&
        validatedMatch(
            ppn.PlantingProject.SustainableDevelopmentGoalsValidated,
            filter.SustainableDevelopmentGoalsValidated
        ) &&
        textMatch(
            formatAdditionalCertificationsArray(
                ppn.PlantingProject.CarbonCreditsAdditionalCertifications
            ),
            filter.AdditionalCarbonCreditCertifications
        ) &&
        validatedMatch(
            ppn.PlantingProject.AdditionalCarbonCreditCertificationsValidated,
            filter.AdditionalCarbonCreditCertificationsValidated
        ) &&
        textMatch(
            formatYesNoConditional(ppn.PlantingProject.CarbonICroaEndorsedStandard),
            filter.CarbonICroaEndorsedStandard
        ) &&
        validatedMatch(
            ppn.PlantingProject.CarbonICroaEndorsedStandardValidated,
            filter.CarbonICroaEndorsedStandardValidated
        ) &&
        textMatch(
            formatYesNoConditional(ppn.PlantingProject.CarbonCorsiaEligible),
            filter.CarbonCorsiaEligible
        ) &&
        validatedMatch(
            ppn.PlantingProject.CarbonCorsiaEligibleValidated,
            filter.CarbonCorsiaEligibleValidated
        ) &&
        textMatch(
            formatCarbonProjectStandards(ppn.PlantingProject.CarbonProjectStandard),
            filter.CarbonProjectStandard
        ) &&
        validatedMatch(
            ppn.PlantingProject.CarbonProjectStandardValidated,
            filter.CarbonProjectRegistryLinkValidated
        ) &&
        textMatch(
            formatCarbonCreditTypes(ppn.PlantingProject.CarbonCreditCreditType),
            filter.CarbonCreditCreditType
        ) &&
        validatedMatch(
            ppn.PlantingProject.CarbonCreditCreditTypeValidated,
            filter.CarbonCreditCreditTypeValidated
        ) &&
        numberMatch(ppn.DocumentCount, filter.DocumentCount) &&
        numberMatch(ppn.CarbonPurchaseAmount.current, filter.CarbonPurchaseAmount) &&
        numberMatch(ppn.CarbonCreditsTransacted.current, filter.CarbonCreditTransactions) &&
        textMatch(ppn.SerialNumber.current, filter.SerialNumber) &&
        textMatch(ppn.ParticipantRegistryLink.current, filter.ParticipantRegistryLink) &&
        numberMatch(ppn.VintageYear.current, filter.VintageYear) &&
        validatedMatch(true, filter.CarbonDocumentsValidated) &&
        validatedMatch(true, filter.CarbonTransactionsValidated) &&
        validatedMatch(true, filter.CarbonPurchaseAmountValidated) &&
        validatedMatch(true, filter.SerialNumberValidated) &&
        validatedMatch(true, filter.VintageYearValidated) &&
        validatedMatch(true, filter.ParticipantRegistryLinkValidated) &&
        textMatch(
            ppn.PlantingProject.BHCampaigns?.map((campaign) => campaign.CampaignId).join(', '),
            filter.BHCampaigns
        )
    );
}

export function filterParticipations(
    participations: EditedParticipation[],
    lythoImageData: LythoImageProject[],
    filter: Filter,
    view: ParticipationView
) {
    return participations.filter((ppn) =>
        passesParticipationFilter(ppn, lythoImageData, filter, view)
    );
}
