import { Tab, Tabs, TabSpacer } from '../../components/Tab/Tabs';

export enum ActiveTab {
    Default = 0,
    Reforestation = 1,
    Community = 2,
    Carbon = 3,
    CommunityCanopy = 4,
    CommunityTreeDistributor = 5
}

export enum ParticipationView {
    Default = 0,
    CPEvents = 1,
    CPReforestation = 2,
    UnvalidatedEvents = 3,
    UnvalidatedReforestation = 4,
    Carbon = 5,
    Reforestation = 6,
    Community = 7,
    CommunityCanopy = 8,
    CommunityTreeDistributor = 9
}

export function viewIncludesReforestation(view: ParticipationView) {
    switch (view) {
        case ParticipationView.CPReforestation:
            return true;
        case ParticipationView.UnvalidatedReforestation:
            return true;
        case ParticipationView.Reforestation:
            return true;
        default:
            return false;
    }
}

export function viewIncludesCommunityEvents(view: ParticipationView) {
    switch (view) {
        case ParticipationView.CPEvents:
            return true;
        case ParticipationView.UnvalidatedEvents:
            return true;
        case ParticipationView.Community:
            return true;
        default:
            return false;
    }
}

export function viewIncludesCarbon(view: ParticipationView) {
    switch (view) {
        case ParticipationView.Carbon:
            return true;
        default:
            return false;
    }
}

export function viewIncludesCommunityCanopy(view: ParticipationView) {
    switch (view) {
        case ParticipationView.CommunityCanopy:
            return true;
        default:
            return false;
    }
}

export function viewIncludesCommunityTreeDistributor(view: ParticipationView) {
    switch (view) {
        case ParticipationView.CommunityTreeDistributor:
            return true;
        default:
            return false;
    }
}

export function getActiveTabFromView(view: ParticipationView) {
    switch (view) {
        case ParticipationView.Default:
            return ActiveTab.Default;
        case ParticipationView.CPEvents:
        case ParticipationView.UnvalidatedEvents:
        case ParticipationView.Community:
            return ActiveTab.Community;
        case ParticipationView.CPReforestation:
        case ParticipationView.UnvalidatedReforestation:
        case ParticipationView.Reforestation:
            return ActiveTab.Reforestation;
        case ParticipationView.Carbon:
            return ActiveTab.Carbon;
        case ParticipationView.CommunityCanopy:
            return ActiveTab.CommunityCanopy;
        case ParticipationView.CommunityTreeDistributor:
            return ActiveTab.CommunityTreeDistributor;
    }
}

function ParticipationViewSelector({
	view,
	setView,
	activeTab
}: {
	view: ParticipationView;
	setView: (view: ParticipationView) => void;
	activeTab: ActiveTab;
}) {
	return (
		<Tabs>
			{activeTab === ActiveTab.Community && (
				<>
					<Tab
						active={view === ParticipationView.Community}
						onClick={() => setView(ParticipationView.Community)}
					>
						Default
					</Tab>
					<TabSpacer />
					<Tab
						active={view === ParticipationView.CPEvents}
						onClick={() => setView(ParticipationView.CPEvents)}
					>
						CP - Events
					</Tab>
					<TabSpacer />
					<Tab
						active={view === ParticipationView.UnvalidatedEvents}
						onClick={() => setView(ParticipationView.UnvalidatedEvents)}
					>
						Events Team
					</Tab>
				</>
			)}
			{activeTab === ActiveTab.Reforestation && (
				<>
					<Tab
						active={view === ParticipationView.Reforestation}
						onClick={() => setView(ParticipationView.Reforestation)}
					>
						Default
					</Tab>
					<TabSpacer />
					<Tab
						active={view === ParticipationView.CPReforestation}
						onClick={() => setView(ParticipationView.CPReforestation)}
					>
						CP - Reforestation
					</Tab>
					<TabSpacer />
					<Tab
						active={view === ParticipationView.UnvalidatedReforestation}
						onClick={() => setView(ParticipationView.UnvalidatedReforestation)}
					>
						Reforestation Team
					</Tab>
				</>
			)}
		</Tabs>
	);
}

export default ParticipationViewSelector;
