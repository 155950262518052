import { Filter } from './ParticipationFunctions/Filtering';
import {
    ParticipationView,
    viewIncludesCommunityEvents,
    viewIncludesReforestation,
    viewIncludesCarbon,
    viewIncludesCommunityCanopy,
    viewIncludesCommunityTreeDistributor
} from './ParticipationViewSelector';
import {CarbonParticipationFilter} from './ProjectTypeTables/CarbonTable/CarbonParticipationTableFilter';
import {CommunityCanopyParticipationFilter} from './ProjectTypeTables/CommunityCanopyTable/CommunityCanopyParticipationFilter';
import {CommunityEventFilter} from './ProjectTypeTables/CommunityEventTable/CommunityEventFilter';
import {CommunityTreeDistributorParticipationFilter} from './ProjectTypeTables/CommunityTreeDistributorTable/CommunityTreeDistributorParticipationFilter';
import {ReforestationFilter} from './ProjectTypeTables/ReforestationTable/ReforestationFilter';

function ParticipationFilterRow({
    filter,
    updateFilter,
    view
}: {
    filter: Filter;
    updateFilter: (filter: Filter) => void;
    view: ParticipationView;
}) {
    if (viewIncludesReforestation(view))
        return <ReforestationFilter filter={filter} updateFilter={updateFilter} />;

    if (viewIncludesCommunityEvents(view))
        return <CommunityEventFilter filter={filter} updateFilter={updateFilter} />;

    if (viewIncludesCommunityCanopy(view)) {
        return <CommunityCanopyParticipationFilter filter={filter} updateFilter={updateFilter} />;
    }

    if (viewIncludesCommunityTreeDistributor(view)) {
        return (
            <CommunityTreeDistributorParticipationFilter
                filter={filter}
                updateFilter={updateFilter}
            />
        );
    }

    if (viewIncludesCarbon(view)) {
        return <CarbonParticipationFilter filter={filter} updateFilter={updateFilter} />;
    }
}

export default ParticipationFilterRow;
