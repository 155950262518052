import {EditedParticipation} from '../../../Partner/EditedPartner';
import {CommunityEventDatesContent} from '../../ParticipationColumns/CommunityEvents/CommunityEventDates';
import {CommunityTreesDistributedContent} from '../../ParticipationColumns/Shared/CommunityTreesDistributed';
import { DAMTagContent } from '../../ParticipationColumns/Shared/DAMTag';
import {DeleteParticipationContent} from '../../ParticipationColumns/Shared/DeleteParticipation';
import {DollarDonatedContent} from '../../ParticipationColumns/Shared/DollarDonated';
import {EverythingValidatedContent} from '../../ParticipationColumns/Shared/EverythingValidated';
import {ExcludedContent} from '../../ParticipationColumns/Shared/Excluded';
import {FundedYearContent} from '../../ParticipationColumns/Shared/FundedYear';
import {FundingSourceContent} from '../../ParticipationColumns/Shared/FundingSource';
import {ImpactStoryCompletedContent} from '../../ParticipationColumns/Shared/ImpactStoryCompleted';
import { ImpactStoryInProgressContent } from '../../ParticipationColumns/Shared/ImpactStoryInProgress';
import {ParticipationIdContent} from '../../ParticipationColumns/Shared/ParticipationId';
import {ProjectIdContent} from '../../ParticipationColumns/Shared/ProjectId';
import {ProjectNameContent} from '../../ParticipationColumns/Shared/ProjectName';
import {ProjectTypeContent} from '../../ParticipationColumns/Shared/ProjectTypeColumn';
import {ReasonForPlantingContent} from '../../ParticipationColumns/Shared/ReasonForPlanting';

import {
    ParticipationRemover,
    ParticipationUnremover,
    ParticipationUpdater,
    ProjectSelectionSetter
} from '../../ParticipationFunctions/ParticipationModifications';

export function CommunityTreeDistributorParticipationContent({
    role,
    participation,
    remover,
    unremover,
    updater,
    projectSelectionSetter
}: {
    role: string[];
    participation: EditedParticipation;
    remover: ParticipationRemover;
    unremover: ParticipationUnremover;
    updater: ParticipationUpdater;
    projectSelectionSetter: ProjectSelectionSetter;
}) {
    return (
        <tr>
            <DeleteParticipationContent
                ppn={participation}
                remover={remover}
                unremover={unremover}
                role={role}
            />
            <ExcludedContent ppn={participation} updater={updater} />
            <EverythingValidatedContent ppn={participation} />
            <ParticipationIdContent ppn={participation} />
            <FundedYearContent ppn={participation} updater={updater} />
            <FundingSourceContent ppn={participation} updater={updater} />
            <CommunityTreesDistributedContent ppn={participation} updater={updater} />
            <DollarDonatedContent ppn={participation} updater={updater} />
            <ProjectIdContent ppn={participation} projectSelectionSetter={projectSelectionSetter} />
            <ProjectTypeContent ppn={participation} />
            <ProjectNameContent ppn={participation} />
            <CommunityEventDatesContent ppn={participation} />
            <ReasonForPlantingContent ppn={participation} />
            <ImpactStoryInProgressContent ppn={participation} />
            <ImpactStoryCompletedContent ppn={participation} />
            <DAMTagContent ppn={participation} />
        </tr>
    );
}
