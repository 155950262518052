import { useEffect, useState } from 'react';
import {useOutletContext, useParams, useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {
    SaveChangesContainer,
    SaveChangesButton,
    SaveChangesMessage
} from '../components/Buttons/SaveChanges';
import {usePrompt} from '../react-router-additions';
import {
    EditedPartner,
    getNotValidToSave,
    InitializeEditedPartner,
    setCalculatedFields,
    setOverallValidatedFlag,
    setPublishedFlag,
    setValidations
} from '../containers/Partner/EditedPartner';
import {participationHasChanges} from '../containers/Participations/ParticipationFunctions/ParticipationHasChanges';
import {Tab, Tabs, TabSpacer} from '../components/Tab/Tabs';
import GeneralPartnerInfo from '../containers/Partner/GeneralPartnerInfo';
import ParticipationsTab from '../containers/Participations/ParticipationInfo';
import {UserRoles} from '../providers/UserProvider';
import {
    ActiveTab,
    ParticipationView,
    getActiveTabFromView
} from '../containers/Participations/ParticipationViewSelector';
import {Filter} from '../containers/Participations/ParticipationFunctions/Filtering';
import Tippy from '@tippyjs/react';
import {DownloadIcon} from '../components/Buttons/DownloadButton';

const DownloadLink = styled.a`
    color: black;
`;

const PartnerLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'header'
        'main';
    place-content: stretch;
    width: 100%;
    height: 100%;
    position: relative;
`;

const Header = styled.div`
    grid-area: header;
`;

const PartnerName = styled.h2`
    font-size: 1.5rem;
    height: calc(1.5rem * 1.5);
    font-weight: 600;
    margin: 0.3rem 0 0 1rem;
`;

const General = styled.div`
    grid-area: main;
    padding: 1rem;
    overflow: auto;
`;

const PositionedSaveChangesContainer = styled(SaveChangesContainer)`
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
`;

function Partner() {
    const {partnerId} = useParams();

    const [partner, setPartner] = useState<EditedPartner>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<{show: boolean; success: boolean; message: string}>({
        show: false,
        success: true,
        message: 'The changes were saved'
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [originalPartner, setOriginalPartner] = useState<string>(null);
    const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Default);

    const urlView = ParticipationView[searchParams.get('view')] ?? ParticipationView.Default;
    const [view, setView] = useState<ParticipationView>(urlView);
    const urlFilter = JSON.parse(searchParams.get('participationFilter')) as Filter;
    const [filter, setFilter] = useState(urlFilter);

    const {onRefreshPartners} = useOutletContext<{
        onRefreshPartners: () => void;
    }>();

    useEffect(() => {
        const activeTabFromView = getActiveTabFromView(view);
        setActiveTab(activeTabFromView);
    }, []);

    useEffect(() => {
        setOriginalPartner(partnerId);
    }, []);

    useEffect(() => {
        getPartner();
        setSearchParams({});
    }, [partnerId]);

    useEffect(() => {
        if (originalPartner !== null && originalPartner !== partnerId) {
            setActiveTab(ActiveTab.Default);
            setView(ParticipationView.Default);
            removeFilters();
        }
    }, [partnerId]);

    const changed = participationHasChanges(partner);

    useEffect(() => {
        window.onbeforeunload = changed ? () => true : null;
    }, [changed]);

    useEffect(() => {
        const searchParams = {};
        if (view !== ParticipationView.Default) searchParams['view'] = ParticipationView[view];
        if (filter) searchParams['participationFilter'] = JSON.stringify(filter);

        setSearchParams(searchParams, {replace: true});
    }, [activeTab, view, filter]);

    usePrompt('Leave site? Changes you made may not be saved.', changed);

    const userRoles = UserRoles();

    const setPartnerAndValidate = (partner) => {
        setValidations(partner);
        setCalculatedFields(partner);
        setOverallValidatedFlag(partner);
        setPublishedFlag(partner);
        setPartner(partner);
    };

    const getPartner = async () => {
        const result = await (await fetch(`/api/partners/${partnerId}`)).json();
        setPartnerAndValidate(InitializeEditedPartner(result));
        setLoading(false);
    };

    const notValidToSave = getNotValidToSave(partner);

    async function saveChanges() {
        const saveResult = await fetch(`/api/partners/${partnerId}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(partner)
        });

        if (saveResult.ok) {
            setMessage({show: true, success: true, message: 'The changes were saved'});
            setTimeout(() => setMessage({show: false, success: true, message: ''}), 2000);
            await getPartner();
            onRefreshPartners();
        } else if (saveResult.status === 409) {
            setMessage({
                show: true,
                success: false,
                message: "These changes conflict with another user's changes"
            });
        } else {
            setMessage({show: true, success: false, message: 'Something went wrong'});
        }
    }

    const updateFilter = (filter: Filter) => {
        setFilter(filter);
    };

    const removeFilters = () => {
        updateFilter(null);
    };

    const changeActiveTab = (selectedTab: ActiveTab) => {
        setActiveTab(selectedTab);

        switch (selectedTab) {
            case ActiveTab.Default:
                return;
            case ActiveTab.Carbon:
                setView(ParticipationView.Carbon);
                break;
            case ActiveTab.Community:
                setView(ParticipationView.Community);
                break;
            case ActiveTab.Reforestation:
                setView(ParticipationView.Reforestation);
                break;
            case ActiveTab.CommunityCanopy:
                setView(ParticipationView.CommunityCanopy);
                break;
            case ActiveTab.CommunityTreeDistributor:
                setView(ParticipationView.CommunityTreeDistributor);
                break;
        }

        updateFilter(null);
    };

    const setGeneralActiveTab = () => {
        setActiveTab(ActiveTab.Default);
        setView(ParticipationView.Default);
    };

    if (loading) return <div></div>;
    return (
        <PartnerLayout>
            {userRoles.includes('editor') && (
                <PositionedSaveChangesContainer>
                    <SaveChangesButton disabled={!changed || notValidToSave} onClick={saveChanges}>
                        Save Changes
                    </SaveChangesButton>
                    <SaveChangesMessage {...message} />
                </PositionedSaveChangesContainer>
            )}
            <Header>
                <div style={{display: 'flex'}}>
                    <PartnerName>{partner.Name.current}</PartnerName>
                    <div style={{marginLeft: '15px', marginTop: '15px'}}>
                        <Tippy content="Download">
                            <DownloadLink download href={`/api/partners/${partner.Id}/download`}>
                                <DownloadIcon />
                            </DownloadLink>
                        </Tippy>
                    </div>
                </div>
                <Tabs>
                    <Tab
                        active={activeTab === ActiveTab.Default}
                        onClick={() => setGeneralActiveTab()}>
                        General
                    </Tab>
                    <TabSpacer />
                    <Tab
                        active={activeTab === ActiveTab.Reforestation}
                        onClick={() => changeActiveTab(ActiveTab.Reforestation)}>
                        Reforestation
                    </Tab>
                    <TabSpacer />
                    <Tab
                        active={activeTab === ActiveTab.Community}
                        onClick={() => changeActiveTab(ActiveTab.Community)}>
                        Community
                    </Tab>
                    <TabSpacer />
                    <Tab
                        active={activeTab === ActiveTab.Carbon}
                        onClick={() => changeActiveTab(ActiveTab.Carbon)}>
                        Carbon Credits
                    </Tab>
                    <TabSpacer />
                    <Tab
                        active={activeTab === ActiveTab.CommunityCanopy}
                        onClick={() => changeActiveTab(ActiveTab.CommunityCanopy)}>
                        Community Canopy
                    </Tab>
                    <TabSpacer />
                    <Tab
                        active={activeTab === ActiveTab.CommunityTreeDistributor}
                        onClick={() => changeActiveTab(ActiveTab.CommunityTreeDistributor)}>
                        Community Tree Distributor
                    </Tab>
                </Tabs>
            </Header>
            {activeTab === ActiveTab.Default && (
                <General>
                    <GeneralPartnerInfo
                        partner={partner}
                        setPartnerAndValidate={setPartnerAndValidate}
                    />
                </General>
            )}
            {activeTab !== ActiveTab.Default && (
                <ParticipationsTab
                    partner={partner}
                    setPartnerAndValidate={setPartnerAndValidate}
                    activeTab={activeTab}
                    view={view}
                    setView={setView}
                    filter={filter}
                    removeFilters={removeFilters}
                    updateFilters={updateFilter}
                    setMessage={setMessage}
                />
            )}
        </PartnerLayout>
    );
}

export default Partner;
