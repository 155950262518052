export type ArcGISLogin = {
    ArcGISUsername: string;
    ArcGISPassword: string;
};

export type Partner = {
    Id: number;
    Name: string;
    LogoDAMAssetId: string;
    PartnerStatementTemplate: string;
    PartnerStatement: string;
    PartnerLive: boolean;
    EverythingValidated: boolean;
    CreatedByAutomation: boolean;
    Participations: Participation[];
    D365Accounts: D365Account[];
    NavCustomers: NavCustomer[];
    Archived: boolean;
} & ArcGISLogin;

export enum PlantingProjectType {
    Reforestation = 1,
    CommunityEvent = 2,
    Carbon = 3,
    CommunityCanopy = 4,
    CommunityTreeDistributor = 5
}

export function formatProjectType(projectType: PlantingProjectType) {
    switch (projectType) {
        case 1:
            return 'Reforestation';
        case 2:
            return 'Community';
        case 3:
            return 'Carbon Credit';
        case 4:
            return 'Community Canopy';
        case 5:
            return 'Community Tree Distributor';
        default:
            return '';
    }
}

export function formatProjectStatus(completed: boolean) {
    return completed ? 'Completed' : 'In-progress';
}

export type PlantingProjectBase = {
    Id: number;
    ProjectType: PlantingProjectType;
    EverythingValidated: boolean;
    WasEverReadyToPublish: boolean;
    ControlledByAutomation: boolean;
    Participations: Participation[];
    FundingPartners: FundingPartner[];
};

export type ReforestationProject = {
    ProjectTypeValidated: boolean;
    Name: string;
    NameValidated: boolean;
    Location: string;
    LocationValidated: boolean;
    TTFForestCode: string;
    TTFForestName: string;
    TTFForestCodeValidated: boolean;
    ReasonForPlanting: string;
    ReasonForPlantingValidated: boolean;
    TreeSpeciesPlanted: string;
    TreeSpecies: PlantingProjectTreeSpecies[];
    TreeSpeciesValidated: boolean;
    PlantingPartnerName: string;
    PlantingPartnerNameValidated: boolean;
    ImpactStoryInProgress: string;
    ImpactStoryInProgressValidated: boolean;
    ImpactStoryCompleted: string;
    ImpactStoryCompletedValidated: boolean;
    ProjectStartYear: number;
    ProjectEndYear: number;
    ProjectYearsValidated: boolean;
    TTFTrees: number;
    TTFTreesValidated: boolean;
    AcresRestored: number;
    AcresRestoredValidated: boolean;
    TTFPlantingTimeframe: string;
    TTFPlantingTimeframeValidated: boolean;
    TTFDBH: number;
    TTFDBHValidated: boolean;
    TTFTotalTreeCost: number;
    TTFTotalTreeCostValidated: boolean;
    TTFAgreementNumber: string;
    TTFAgreementNumberValidated: boolean;
    TTFCommitmentFiscalYear: string;
    TTFCommitmentFiscalYearValidated: boolean;
    DAMTag: string;
    ImagesValidated: boolean;
    IsHoldingProject: boolean;
    HasTreeSpecies: boolean;
    HasImpactStoryInProgress: boolean;
    HasImpactStoryCompleted: boolean;
    Completed: boolean;
    CompletedValidated: boolean;
    MapValidated: boolean;
    GallonsTotalRunoffAvoidedPerTreeReforestation: number;
    TonsCarbonPerTreeReforestation: number;
    TonsAirPollutionPerTreeReforestation: number;
    Commitments: PlantingProjectCommitment[];
    HasMap: boolean;
} & PlantingProjectBase;

export type CommunityProject = {
    ProjectTypeValidated: boolean;
    Name: string;
    NameValidated: boolean;
    Location: string;
    LocationValidated: boolean;
    ReasonForPlanting: string;
    ReasonForPlantingValidated: boolean;
    TreeSpeciesPlanted: string;
    TreeSpecies: PlantingProjectTreeSpecies[];
    TreeSpeciesValidated: boolean;
    PlantingPartnerName: string;
    PlantingPartnerNameValidated: boolean;
    ImpactStoryUnedited: string;
    ImpactStoryInProgress: string;
    ImpactStoryInProgressValidated: boolean;
    ImpactStoryCompleted: string;
    ImpactStoryCompletedValidated: boolean;
    CommunityTreesPlanted: number;
    CommunityTreesPlantedValidated: boolean;
    CommunityTreesDistributed: number;
    CommunityTreesDistributedValidated: boolean;
    CommunityNumberOfCorporateVolunteers: number;
    CommunityNumberOfCorporateVolunteersValidated: boolean;
    CommunityNumberOfTotalParticipants: number;
    CommunityNumberOfTotalParticipantsValidated: boolean;
    CommunityFirstDayOfEvent: string;
    CommunityFinalDayOfEvent: string;
    CommunityEventDatesValidated: boolean;
    CommunitySiteName: string;
    CommunitySiteNameValidated: boolean;
    CommunitySiteAddress: string;
    CommunitySiteAddressValidated: boolean;
    CommunitySiteCity: string;
    CommunitySiteCityValidated: boolean;
    CommunitySiteStateCode: string;
    CommunitySiteStateCodeValidated: boolean;
    CommunitySitePostalCode: string;
    CommunitySitePostalCodeValidated: boolean;
    CommunitySiteCountryCode: string;
    CommunitySiteCountryCodeValidated: boolean;
    CommunityDBHTreesPlanted: number;
    CommunityDBHTreesPlantedValidated: boolean;
    CommunityDBHTreesDistributed: number;
    CommunityDBHTreesDistributedValidated: boolean;
    DAMTag: string;
    ImagesValidated: boolean;
    HasTreeSpecies: boolean;
    HasImpactStoryInProgress: boolean;
    HasImpactStoryCompleted: boolean;
    Completed: boolean;
    CompletedValidated: boolean;
    MapValidated: boolean;
    GallonsTotalRunoffAvoidedPerTreeEventPlanted: number;
    GallonsTotalRunoffAvoidedPerTreeEventDistributed: number;
    TonsCarbonPerTreeEventPlanted: number;
    TonsCarbonPerTreeEventDistributed: number;
    TonsAirPollutionPerTreeEventPlanted: number;
    TonsAirPollutionPerTreeEventDistributed: number;
    D365_eventcoordinationid: string;
    D365PlantingProjectId: string;
} & PlantingProjectBase;

export type CarbonProject = {
    ProjectTypeValidated: boolean;
    Name: string;
    NameValidated: boolean;
    Location: string;
    LocationValidated: boolean;
    PlantingPartnerName: string;
    PlantingPartnerNameValidated: boolean;
    ImpactStoryCompleted: string;
    ImpactStoryCompletedValidated: boolean;
    ProjectStartYear: number;
    ProjectEndYear: number;
    ProjectYearsValidated: boolean;
    AcresRestored: number;
    AcresRestoredValidated: boolean;
    DAMTag: string;
    ImagesValidated: boolean;
    HasImpactStoryCompleted: boolean;
    Completed: boolean;
    CompletedValidated: boolean;
    MapValidated: boolean;
    CarbonTotalCreditsIssued: number;
    CarbonProjectStandard: CarbonProjectStandards;
    CarbonCreditCreditType: CarbonCreditCreditType;
    CarbonICroaEndorsedStandard: YesNoConditional;
    CarbonCorsiaEligible: YesNoConditional;
    CarbonAdditionalDetailsLink: string;
    CarbonProjectRegistryLink: string;
    CarbonCreditsAdditionalCertifications: CarbonCreditsAdditionalCertifications[];
    SustainableDevelopmentGoals: SustainableDevelopmentGoals[];
    CarbonTotalCreditsIssuedValidated: boolean;
    CarbonProjectStandardValidated: boolean;
    CarbonCreditCreditTypeValidated: boolean;
    CarbonICroaEndorsedStandardValidated: boolean;
    CarbonCorsiaEligibleValidated: boolean;
    CarbonAdditionalDetailsLinkValidated: boolean;
    CarbonProjectRegistryLinkValidated: boolean;
    SustainableDevelopmentGoalsValidated: boolean;
    AdditionalCarbonCreditCertificationsValidated: boolean;
    HasMap: boolean;
} & PlantingProjectBase;

export type CommunityCanopyProject = {
    Id: number;
    ProjectType: PlantingProjectType;
    Name: string;
    ReasonForPlanting: string;
    TreeSpeciesPlanted: string;
    TreeSpecies: PlantingProjectTreeSpecies[];
    ImpactStoryCompleted: string;
    CommunityTreesDistributed: number;
    CommunityFirstDayOfEvent: string;
    CommunityFinalDayOfEvent: string;
    HasTreeSpecies: boolean;
    HasImpactStoryCompleted: boolean;
    Completed: boolean;
    BHCampaigns: PlantingProjectBHCampaign[];
} & PlantingProjectBase;

export type PlantingProject = object &
    ReforestationProject &
    CommunityProject &
    CarbonProject &
    CommunityCanopyProject;

export type FundingPartner = {
    Id: number;
    Name: string;
};

export type ParticipationBase = {
    Id: number;
    PartnerId: number;
    Partner: Partner;
    ProjectType: PlantingProjectType;
    PlantingProjectId: number;
    PlantingProject: PlantingProject;
    FundedYear: number;
    FundingSource: string;
    Excluded: boolean;
    EverythingValidated: boolean;
    WasEverReadyToPublish: boolean;
    ReadyToPublish: boolean;
    ControlledByAutomation: boolean;
};

export type ReforestationParticipation = {
    FundedYearValidated: boolean;
    DollarsDonated: number;
    DollarsDonatedValidated: boolean;
    TTFTrees: number;
    TTFTreesValidated: boolean;
    AcresRestored: number;
    ShowOnDate: string;
    THLiabilityId: number;
    TTFForestName: string;
} & ParticipationBase;

export type CommunityParticipation = {
    FundedYearValidated: boolean;
    DollarsDonated: number;
    DollarsDonatedValidated: boolean;
    CommunityTreesPlanted: number;
    CommunityTreesPlantedValidated: boolean;
    CommunityTreesDistributed: number;
    CommunityTreesDistributedValidated: boolean;
    CommunityBudgetPassThrough: number;
    CommunityBudgetPassThroughValidated: boolean;
    ShowOnDate: string;
    D365RevenuePostingInvoiceId: string;
    isOrderProduct: boolean
} & ParticipationBase;

export type CarbonParticipation = {
    FundedYear: number;
    FundedYearValidated: boolean;
    CarbonPurchaseAmount: number;
    CarbonTransactionId: number;
    CarbonCreditsTransacted: number;
    VintageYear: string;
    SerialNumber: string;
    ParticipantRegistryLink: string;
    DocumentCount: number;
} & ParticipationBase;

export type CommunityCanopyParticipation = {
    FundedYearValidated: boolean;
    DollarsDonated: number;
    DollarsDonatedValidated: boolean;
    CommunityTreesDistributed: number;
    ShowOnDate: string;
} & ParticipationBase;

export type Participation = object &
    ReforestationParticipation &
    CommunityParticipation &
    CarbonParticipation &
    CommunityCanopyParticipation;

export type D365Account = {
    AccountId: string;
};

export type NavCustomer = {
    CustomerId: string;
};

export type TreeSpecies = {
    Code: string;
    ScientificName: string;
    CommonName: string;
    IsValid: boolean;
    NeedsReplacement: boolean;
    ReplacementCode: string;
    ReplacementCommonName: string;
};

export type PlantingProjectTreeSpecies = {
    Id: number;
    PlantingProjectId: number;
    SpeciesCode: string;
    OtherName: string;

    Species: TreeSpecies;
};

export type PlantingProjectCommitment = {
    Id: number;
    PlantingProjectId: number;
    CommitmentId: number;
};

export type PlantingProjectBHCampaign = {
    Id: number;
    PlantingProjectId: number;
    CampaignId: string;
};

export enum BHCampaignType {
    EnergySavingTrees = 1,
    CommunityCanopy = 2
}

export type BHCampaign = {
    Id: number;
    BeehiveCampaignId: string;
    CampaignName: string;
    CampaignStartDate: string;
    CampaignEndDate: string;
    PartnerId: string;
    TotalTreesDistributed: number;
    CampaignType: BHCampaignType;
    LastModifiedDate: string;
};

export type Forest = {
    Id: number;
    Code: string;
    Name: string;
};

export type LythoImage = {
    id: string;
    width: number;
    height: number;
    link: string;
    type: string;
};

export type LythoImageProject = {
    hero: LythoImage;
    // image-count: number; This isn't possible because of the dash, so use ['image-count'] to access this property instead
    images: LythoImage[];
};

export enum YesNoConditional {
    Yes = 1,
    No = 2,
    Conditional = 3
}

export function formatYesNoConditional(ynk: YesNoConditional) {
    switch (ynk) {
        case YesNoConditional.Yes:
            return 'Yes';
        case YesNoConditional.No:
            return 'No';
        case YesNoConditional.Conditional:
            return 'Conditional';
    }
}

export enum CarbonProjectStandards {
    VerraVCS = 1,
    VerraVCSCCB = 2,
    VerraVCSCCBSDVISta = 3,
    PlanVivo = 4,
    ACR = 5,
    GoldStandard = 6,
    CAR = 7,
    UNFCCCMechanisms = 8,
    ERFOfTheAustralianGovernment = 9,
    UKWoodlandCarbonCode = 10,
    ARTTrees = 11,
    CityForestCredits = 12
}

export enum CarbonCreditCreditType {
    REDDReduction = 1,
    IFMReduction = 2,
    IFMRemoval = 3,
    ARRemoval = 4,
    MangroveReduction = 5,
    MangroveRemoval = 6
}

export enum CarbonCreditsAdditionalCertification {
    FairTrade = 1,
    CCBStandards = 2,
    Organic = 3,
    FSC = 4,
    SFI = 5,
    PEFC = 6,
    RainforestAlliance = 7
}

export type SustainableDevelopmentGoals = {
    SustainableDevelopmentGoal: SustainableDevelopmentGoal;
};

export type CarbonCreditsAdditionalCertifications = {
    CarbonCreditsAdditionalCertification: CarbonCreditsAdditionalCertification;
};

export enum SustainableDevelopmentGoal {
    NoPoverty = 1,
    ZeroHunger = 2,
    GoodHealthAndWellBeing = 3,
    QualityEducation = 4,
    GenderEquality = 5,
    CleanWaterAndSanitation = 6,
    AffordableAndCleanEnergy = 7,
    DecentWorkAndEconomicGrowth = 8,
    IndustryInnovationAndInfrastructure = 9,
    ReducedInequalities = 10,
    SustainableCitiesAndCommunities = 11,
    ResponsibleConsumptionAndProduction = 12,
    ClimateAction = 13,
    LifeBelowWater = 14,
    LifeOnLand = 15,
    PeaceJusticeAndStrongInstitutions = 16
}

export type AutomationRun = {
    Id: number;
    Automation: string;
    StartDateTime: string;
    EndDateTime: string;
    Logs: AutomationRunLog[];
};

export type AutomationRunLog = {
    Id: number;
    RunId: number;
    LogDateTime: string;
    Message: string;
    D365AccountId: string;
    PartnerId: number;
    D365EventCoordinationId: string;
    isOrderProduct: boolean;
    PlantingProjectId: number;
    D365RevenuePostingInvoiceId: string;
    D365PlantingProjectId: string;
};
